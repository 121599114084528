import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductRate } from '@gtool.shared/models/models';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';

@Component({
    selector: 'app-revenue-form',
    templateUrl: './revenue-form.component.html',
    styleUrls: ['./revenue-form.component.css'],
})
export class RevenueFormComponent implements OnInit {
    private _closeResult: string;
    public revenueForm: FormGroup;
    public productRates: ProductRate[];
    public showForm: boolean = false;

    @Input() public organisationId: number;

    constructor(
        private modalService: NgbModal,
        private toastr: MyToasterService,
        private organisationService: OrganisationServiceService,
    ) {}

    ngOnInit() {
    }

    open(content) {
        this.initForm();
        this.modalService.open(content).result.then(
            result => {
                this._closeResult = `Closed with: ${result}`;
            },
            reason => {
                this._closeResult = `Dismissed ${this.getDismissReason(
                    reason
                )}`;
            }
        );
    }

    initForm () {
      this.revenueForm =  new FormGroup({});
      this.organisationService.getOrganisationDetails(this.organisationId).subscribe( organisation => {
        this.productRates = organisation.productRates;
        this.productRates.forEach( productRate => {
            this.revenueForm.addControl('productRevenueType_' + productRate.productType.id,
            new FormControl(productRate.rate, [
              Validators.required,
              Validators.min(0),
              Validators.max(100)]));
        });
        this.showForm = true;
      });
    }

    onSubmit() {
      const productRates: ProductRate[] = new Array<ProductRate>();
      Object.keys(this.revenueForm.controls).forEach((key: string) => {
        const productRate = new ProductRate();
        productRate.productTypeId = parseInt( key.split('_')[1], 0);
        productRate.rate = this.revenueForm.controls[key].value;
        productRates.push(productRate);
      });
      this.organisationService.updateOrganisationRates(this.organisationId, productRates).subscribe(r => {
        this.organisationService.rateChange.next(true);
        this.modalService.dismissAll();
        this.toastr.success('msg.organisation.rates.updated');
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  }
}
