import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Debounce } from '@gtool.shared/decorators/debounce';
import { IsNotEmpty } from '@gtool.shared/decorators/not-empty';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { Direction } from '@gtool.shared/models/Direction';
import { DirectionType } from '@gtool.shared/models/DirectionType';
import { Filter } from '@gtool.shared/models/Filter';
import { FilterOperatorType } from '@gtool.shared/models/FilterOperatorType';
import { Paging } from '@gtool.shared/models/Paging';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { Machine } from '@gtool.shared/models/Machine';
import { MachineType } from '@gtool.shared/models/models';

@Component({
    selector: 'app-machines',
    templateUrl: './machines.component.html',
    styleUrls: ['./machines.component.css'],
})
export class MachinesComponent implements OnInit {
	  public machinesResultSet: QueryResultSet<Machine>;
    public machinesResult: Machine[];

  	public machineTypes: MachineType[] = new Array(new MachineType(0, 'All'));
    public orderStatuses: string[] = new Array();

    public currentPage: number = 1;

    //total available pages, based on totalResults and page size
    public totalResultsWithSpecs: number;

    //array to be iterated on the UI
    public pageSizes: Array<number> = [10, 20, 50];
    public currentPageSize: number = 10;

    private currentMachinesQuery: QuerySpecification = new QuerySpecification();
    public currentOrderStatus: string = 'msg.default.select';
    public currentMachineTypeName: string = 'All';
    public currentMachineTypeId: number = 0;

    public showSerialNoSearch: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private machineService: MachineService
    ) {}

    ngOnInit() {
   		this.machinesResultSet = this.route.snapshot.data.list; // receive the data from the resolver
        this.machinesResult = this.machinesResultSet.objectsResult;
        this.totalResultsWithSpecs = this.machinesResultSet.numberOfObjects;
        this.generateOrderStatuses();
        this.machineService.getMachineTypes().subscribe(
            machineTypez => machineTypez.forEach(
                machineType =>
                this.machineTypes.push(new MachineType(machineType.id, machineType.name)))
                );
        this.currentMachinesQuery = this.generateDefaultQuery();
    }

    exportCsv(): void {
        this.machineService.exportCsv().subscribe(response => {
            window.open(window.URL.createObjectURL(response.body));
        });
    }

    refresh(): void {
       this.getMachineOrders();
    }

    upgrade( machineId: number): void {
        this.machineService.upgradeMachine(machineId).toPromise().then( r => {
        // something
        }).finally( () => {
            this.refresh();
        });
    }

    reset( machineId: number): void {
        this.machineService.resetMachine(machineId).toPromise().then( r => {
        // something
        }).finally( () => {
            this.refresh();
        });
    }

     /**
     * This method will generate the default Query.
     * PageSize:10,Current Page:1
     */
    private generateDefaultQuery(): QuerySpecification {
        let query: QuerySpecification = new QuerySpecification();

        //Paging
        let p: Paging = new Paging();
        p.page = 1;
        p.limit = 10;

        //Sorting
        let directions: Array<Direction> = new Array();
        let direction = new Direction();
        direction.column = 'id';
        direction.direction = DirectionType.DESC;
        directions.push(direction);
        p.directions = directions;

        //Filtering
        let f: Filter = new Filter();
        f.operator = FilterOperatorType.AND;

        let criterionQuery: CriterionQuery = new CriterionQuery();

        let criteria: Array<CriterionQuery> = [];
        criteria.push(criterionQuery);

        f.criteria = criteria;

        query.p = p;
        query.f = f;

        return query;
    }

    /**
     * Generate the available options of a orderStatuses based on status
     */
    private generateOrderStatuses(): void {
    	this.orderStatuses.push('msg.default.select');
        this.orderStatuses.push('machine.status.in.production');
        this.orderStatuses.push('machine.status.for.sale');
    }

    @Debounce(500)
    @IsNotEmpty()
    public searchBySerialNo(serialNo: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentMachinesQuery.f.criteria) {
            if(criterion.columnName ==='serialNo'){
                if(serialNo !== ''){
                    criterion.value = serialNo;
                    found = true;
                    break;
                } else {
                    index = this.currentMachinesQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                }
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'serialNo';
            criterionQuery.value = serialNo;
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.LIKE;
            this.currentMachinesQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentMachinesQuery.f.criteria.splice(index,1);
        }
        this.getMachineOrders();
    }

/**
     * Handle change orderStatus event
     * @param orderStatus
     */
 public changeMachineType(machineType: MachineType): void {
    if (this.currentMachineTypeId !== machineType.id) {
        this.currentMachineTypeId = machineType.id;
        this.currentMachineTypeName = machineType.name;
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentMachinesQuery.f.criteria) {
            if(criterion.columnName ==='machineType.id'){
                if(machineType.id === 0){
                    index = this.currentMachinesQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                } else {
                    criterion.value = machineType.id.toString();
                    found = true;
                }
                break;
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'machineType.id';
            criterionQuery.value = machineType.id.toString();
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.EQUAL;
            this.currentMachinesQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentMachinesQuery.f.criteria.splice(index,1);
        }
        this.getMachineOrders();
    }
}

	/**
     * Handle change orderStatus event
     * @param orderStatus
     */
    public changeOrderStatus(orderStatus: string): void {
        if (this.currentOrderStatus !== orderStatus) {
            this.currentOrderStatus = orderStatus;
            let found: boolean= false;
            let index: number = -1;
            for (var criterion of this.currentMachinesQuery.f.criteria) {
                if(criterion.columnName ==='orderStatus'){
                    if(orderStatus === 'msg.default.select'){
                        index = this.currentMachinesQuery.f.criteria.indexOf(criterion,0);
                        found = true;
                    } else {
                        criterion.value = orderStatus;
                        found = true;
                    }
                    break;
                }
            }
            if(!found){
                let criterionQuery: CriterionQuery = new CriterionQuery();
                criterionQuery.columnName = 'orderStatus';
                criterionQuery.value = orderStatus;
                criterionQuery.valueType = CriteriaValueType.STRING;
                criterionQuery.operator = CriteriaOperatorType.EQUAL;
                this.currentMachinesQuery.f.criteria.push(criterionQuery);
            } else if(index > -1){
                this.currentMachinesQuery.f.criteria.splice(index,1);
            }
            this.getMachineOrders();
        }
    }

    /**
     * Handle change PageSize event
     * @param pageSize
     */
    public changePageSize(pageSize: number): void {
        if (this.currentPageSize !== pageSize) {
            this.currentPageSize = pageSize;
            this.currentMachinesQuery.p.limit = pageSize;
            this.getMachineOrders();
        }
    }

    /**
     * Handle change Page event
     * @param page
     */
    public pageChanged(page: number): void {
        this.currentMachinesQuery.p.page = page;
        this.getMachineOrders();
    }

    /**
     * This method will send the updated QuerySpecification
     */
    private getMachineOrders(): void {
        this.machineService
            .getMachinesWithSpecs(this.currentMachinesQuery)
            .subscribe((machines) => {
                this.machinesResultSet = machines;
                this.machinesResult = machines.objectsResult;
                this.totalResultsWithSpecs = machines.numberOfObjects;
            });
    }

    /**
     * When a search input is closed we must refresh, to the default state and re-enable the status button
     */
     public closeSearchInput(columnName: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentMachinesQuery.f.criteria) {
            if(criterion.columnName === columnName){
                index = this.currentMachinesQuery.f.criteria.indexOf(criterion,0);
                found = true;
            }
        }
        if(found && index > -1){
            this.currentMachinesQuery.f.criteria.splice(index,1);
        }
        this.getMachineOrders();
        switch (columnName){
            case 'serialNo' :
                //search by serialNo
                this.showSerialNoSearch = false;
                break;
        }
    }

    /**
     * This method will show the specified search input, hide the others and disable the status filtering button
     * @param field 0:Serial No
     */
    public openSearchInput(field:number):void{
        switch (field){
            case 0 :
                //search by serial No
                this.showSerialNoSearch = true;
                break;
        }

    }
}
