<footer class="footer">
    <div class="container-fluid">
      <!--
      <nav>
        <ul>
          <li>
            <a href="#">
              A link
            </a>
          </li>
          <li>
            <a href="#>
              A link
            </a>
          </li>
          <li>
            <a href="#">
              A link
            </a>
          </li>
        </ul>
      </nav>
    -->
      <div class="copyright">
        &copy;
        {{ copywrite | date: 'yyyy' }}, {{ 'msg.app.name' | translate }}
      </div>
    </div>
  </footer>
