<div class="wrapper">
    <div class="sidebar" data-color="blue">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <div class="panel-header panel-header-sm">&nbsp;</div>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
