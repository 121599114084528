import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-monitor',
  templateUrl: './machine-monitor.component.html',
  styleUrls: ['./machine-monitor.component.css']
})
export class MachineMonitorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
