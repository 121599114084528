import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent implements OnInit {

  public isDistributor = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.isDistributor = this.authService.isDistributor(this.authService.getCurrentOrganisation());
  }

  goTo( route: string[] ) {
    this.router.navigate(route, {relativeTo: this.activatedRoute});
  }

}
