import { CreateInfo } from './CreateInfo';
import { OrganisationDetails, Group, Customer } from './models';
import { OperatorDetails } from './OperatorDetails';
import { PhoneModel } from './PhoneModel';
import { StartFinishInfo } from './StartFinishInfo';

export class RepairOrder {
    id?: number;
    resourceType?: string;
    updateInfo?: string;
    repairPoint?: OrganisationDetails;
    repairPointId?: number;
    customer?: Customer;
    customerId?: number;
    operator?: OperatorDetails;
    phoneModel?: PhoneModel;
    phoneModelId?: number;
    variant?: string;
    owner?: Group;
    status?: string;
    creditsUsed?: number;
    imei?: string;
    createInfo?: CreateInfo;
    startFinishInfo?: StartFinishInfo;
    dateDue?: string;
    checkList?: string[];
    quote?: number;
    diagnosticNotes?: string;
    internalNotes?: string;
    warranty?: boolean;
    pin?: string;
    repairTypes?: number[];
    checlist?: string[];
    refNo?: string;
}


export class RepairEndType {
  id?: number;
  status?: string;
  type?: string;
}
