import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbTypeaheadConfig, NgbTypeahead, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    filter,
} from 'rxjs/operators';
import {
    PhoneType,
    PhoneModel,
    Customer,
    RepairOrderRequest,
    PhoneRepairType,
    RepairOrder,
} from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Observable, Subject, merge } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';
import { RepairService } from '@gtool.shared/services/repair.service';
import { RepairRequestType } from '@gtool.shared/models/RepairRequestType';
import { Router, ActivatedRoute } from '@angular/router';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';

@Component({
    selector: 'app-add-order',
    templateUrl: './add-order.component.html',
    styleUrls: ['./add-order.component.css'],
    providers: [NgbTypeaheadConfig],
})
export class AddOrderComponent implements OnInit {
    constructor(
        private df: NgbDateParserFormatter,
        private toast: MyToasterService,
        private configService: ConfigurationService,
        private repairService: RepairService,
        private authService: AuthenticationService,
        private modal: NgbModal,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    public addOrderForm: FormGroup;
    public phoneTypes: PhoneType[];
    public phoneModels: PhoneModel[];
    public phoneModelVariants: string[];
    public phoneRepairTypes: PhoneRepairType[];
    public customers: Customer[];
    public model: any;
    public customersNames: string[] = [];
    public  repairTypeIds: number[] = [];
    public checkList: string[] = [];
    public checkListItems: any[] = [
        { id: 1, name: 'msg.check.list.bat' },
        { id: 2, name: 'msg.check.list.sim' },
        { id: 3, name: 'msg.check.list.sdc' },
        { id: 4, name: 'msg.check.list.cha' },
    ];
    public disableAddBtn: boolean;
    public selectedPhoneModel : PhoneModel;
    public selectedVariant: string;
    public showRepairs : boolean;

    @ViewChild('content') content: any;

    ngOnInit() {
        this.initializeForm();

        this.phoneTypes = this.route.snapshot.data.phoneTypes[0].sort( (a,b)=> a.name.localeCompare(b.name) );
        this.customers = this.route.snapshot.data.customers;

        this.manipulateData();
    }

    private manipulateData() {
        this.customers.forEach((x: any) => {
            this.customersNames.push(x.firstName + ' ' + x.lastName);
        });
    }

    @ViewChild('instance') instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.click$.pipe(
            filter(() => !this.instance)
        );
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term =>
                (term === ''
                    ? this.customersNames
                    : this.customersNames.filter(
                          v => v.toLowerCase().indexOf(term.toLowerCase()) > -1
                      )
                ).slice(0, 10)
            )
        );
    };

    public checkCustomerMatch(): boolean {
        if (
            this.addOrderForm.get('customer').touched &&
            !this.customersNames.includes(
                this.addOrderForm.get('customer').value
            )
        ) {
            return false;
        }
        return true;
    }

    public getModels() {
        this.clearSelections();
        this.phoneRepairTypes = null;
        this.addOrderForm.get('phoneModel').setValue('')
        const phoneType = this.addOrderForm.get('phoneType').value;
        if (phoneType !== '') {
            this.configService.getPhoneModels().subscribe(data => {
                this.phoneModels = data.sort( (a,b)=> a.name.localeCompare(b.name) ).filter(
                    res => res.phoneTypeId == phoneType
                );
            });
        } else {
            this.phoneModels = null;
            this.selectedPhoneModel = null;
            this.phoneModelVariants = null;
        }
    }

    private clearSelections():void {
        //clear selected repairs
        this.repairTypeIds = [];
        this.addOrderForm.get('variant').setValue('')
        Object.keys(this.addOrderForm.controls).forEach(key => {
          if( key.startsWith('repairType_')){
            this.addOrderForm.get(key).setValue('');
          }
        });
    }

    public getRepairTypes() {
        this.clearSelections();
        const phoneModel = this.addOrderForm.get('phoneModel').value;
        if (phoneModel !== '') {
          this.selectedPhoneModel = this.phoneModels.find( (m) => m.id == phoneModel );
            this.configService
                .getPhoneRepairTypes(phoneModel)
                .subscribe(data => {
                  var filteredData = data.filter( d => this.authService.getCurrentOrganisation().allowDemo || !d.demo );
                  filteredData.forEach(d => {
                        this.addOrderForm.addControl(
                            'repairType_' + d.id,
                            new FormControl('', Validators.required)
                        );
                    });
                    this.phoneRepairTypes = filteredData;
                    this.phoneModelVariants = this.selectedPhoneModel.variances;
                    this.showRepairs = !this.phoneModelVariants;
                });
        } else {
            this.phoneModelVariants = null;
            this.phoneRepairTypes = null;
            this.showRepairs = false;
        }
    }

    public updateRepairs(repairTypeId: number) {
        if (this.addOrderForm.get('repairType_' + repairTypeId).value == true) {
            this.repairTypeIds.push(repairTypeId);
        } else {
            this.repairTypeIds.splice(
                this.repairTypeIds.indexOf(repairTypeId),
                1
            );
        }
    }

    public updateCheckList(itemId: string) {
        const itemName = this.checkListItems.find( item => item.id == itemId).name;
        if (this.addOrderForm.get('checkList_' + itemId).value == true) {
            this.checkList.push(itemName);
        } else {
            this.checkList.splice(
                this.checkList.indexOf(itemName),
                1
            );
        }
    }

    public updateVariant() {
      this.selectedVariant = this.addOrderForm.get('variant').value;
      this.showRepairs = true;
    }

    public validate() {
        let hasModel = this.addOrderForm.get('phoneModel').value !== '';
        let hasRepairs = this.repairTypeIds.length > 0;
        let hasVariant = this.phoneModelVariants == null || this.addOrderForm.get('variant').value !== '';

        if ( hasModel && hasVariant && hasRepairs && this.checkCustomerMatch()) {
            return true;
        }
        return false;
    }

    private findCustomer(): number {
        let fullname = '';
        let customerId = 0;
        this.customers.forEach((x: any) => {
            fullname = x.firstName + ' ' + x.lastName;
            if (fullname === this.addOrderForm.get('customer').value) {
                customerId = x.id;
            }
        });
        return customerId;
    }

    public addOrder() {
      this.disableAddBtn = true;
        const repairOrderRequest: RepairOrderRequest = new RepairOrderRequest();
        repairOrderRequest.action = 'CREATE';
        repairOrderRequest.dto = new RepairOrder();
        repairOrderRequest.dto.customerId = this.findCustomer();

        //repairOrderRequest.dto.imei = this.addOrderForm.get('imei').value;
        repairOrderRequest.dto.repairPointId = this.authService.getCurrentOrganisation().id;
        repairOrderRequest.dto.phoneModelId = this.addOrderForm.get('phoneModel').value;
        repairOrderRequest.dto.checkList = this.checkList;
        repairOrderRequest.dto.repairTypes = this.repairTypeIds;
        repairOrderRequest.dto.dateDue = this.df.format( this.addOrderForm.controls['dateDue'].value );
        repairOrderRequest.dto.refNo = this.addOrderForm.get('refNo').value;
        repairOrderRequest.dto.warranty = this.addOrderForm.get('warranty').value;
        repairOrderRequest.dto.internalNotes = this.addOrderForm.get('internalNotes').value;
        repairOrderRequest.dto.diagnosticNotes = this.addOrderForm.get('diagnosticNotes').value;
        repairOrderRequest.dto.variant = this.addOrderForm.get('variant').value;

        this.repairService
            .addRepairOrder(repairOrderRequest)
            .subscribe(repairOrder => {
              this.disableAddBtn = false;
                this.modal.dismissAll();
                this.toast.success('msg.repair.order.created');
                this.router.navigate(['..', repairOrder.id, 'details'], { relativeTo: this.route });
            });
    }

    private initializeForm() {
        this.addOrderForm = new FormGroup({
            customer: new FormControl('', Validators.required),
            phoneType: new FormControl('', Validators.required),
            phoneModel: new FormControl('', Validators.required),
            variant: new FormControl(''),
            //imei: new FormControl('', Validators.required),
            operator: new FormControl('', Validators.required),
            owner: new FormControl('', Validators.required),
            refNo: new FormControl(''),
            dateDue: new FormControl('', Validators.required),
            warranty: new FormControl(false, Validators.required),
            internalNotes: new FormControl('', ),
            diagnosticNotes: new FormControl('', ),
            quote: new FormControl(''),
        });
        // dynamic fields
        this.checkListItems.forEach( item => {
            this.addOrderForm.addControl( 'checkList_' + item.id, new FormControl('') );
        });
    }
}
