import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-types',
  templateUrl: './machine-types.component.html',
  styleUrls: ['./machine-types.component.css']
})
export class MachineTypesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
