<div class="main-content">
    <div class="row">
        <div class="col-md-12">
        <div class="card">
            <div class="card-body">

                <form [formGroup]="machineFirmwareForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <label for="model">Firmware Version:</label>
                                <input
                                    type="text"
                                    formControlName="firmwareVersion"
                                    placeholder="Firmware Version"
                                    name="firmwareVersion"
                                    class="form-control"
                                    id="firmwareVersion"
                                />
                            </div>
                            <div class="col">
                                <label for="model">Product Version:</label>
                                <input
                                    type="text"
                                    formControlName="productVersion"
                                    placeholder="Product Version"
                                    name="productVersion"
                                    class="form-control"
                                    id="productVersion"
                                />
                            </div>
                            <div class="col">
                                <label for="machinetype">Machine Type:</label>
                                <select
                                    formControlName="machineTypeId"
                                    class="form-control"
                                    id="machineTypeId"
                                >
                                    <option value="" selected> Select machine Type. </option>
                                    <option
                                        *ngFor="let machineType of machineTypes"
                                        value="{{ machineType.id }}"
                                    >
                                        {{ machineType.name }}
                                    </option>
                                </select>
                            </div>                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="firmwareFile"
                                    >{{ 'msg.firmware.file' | translate }}:</label
                                >
                                <input
                                    type="file"
                                    id="firmwareFile"
                                    class="form-control-file"
                                    (change)="onFileChange('firmwareFile', $event)"
                                    #firmwareFile
                                />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="lcdFile"
                                    >{{ 'msg.lcd.file' | translate }}:</label
                                >
                                <input
                                    type="file"
                                    id="lcdFile"
                                    class="form-control-file"
                                    (change)="onFileChange('lcdFile', $event)"
                                    #lcdFile
                                />
                            </div>   
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="infoFile"
                                    >{{ 'msg.info.file' | translate }}:</label
                                >
                                <input
                                    type="file"
                                    id="infoFile"
                                    class="form-control-file"
                                    (change)="onFileChange('infoFile', $event)"
                                    #infoFile
                                />
                            </div>   
                        </div>                                                                            
                    </div>

                    <div class="row mt-2">
                        <div class="col-12">
                            <button
                                class="btn btn-primary float-right ml-1"
                                type="submit"
                                [disabled]="!machineFirmwareForm.valid"
                            >
                                Add Firmware
                            </button>
                            <a
                                [routerLink]="['../../']"
                                class="btn btn-secondary float-right ml-1"
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>