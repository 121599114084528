import { Group } from './models';
import { OrganisationDetails } from './OrganisationDetails';

export class CustomerDetails {
    owner?: Group;
    repairPoint?: OrganisationDetails;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    type?: string;
}
