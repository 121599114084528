import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { RepairOrder } from '@gtool.shared/models/models';
import { RepairService } from '@gtool.shared/services/repair.service';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';

@Injectable()
export class RepairOrdersListResolver implements Resolve<any> {
    constructor(private repairService: RepairService) {}

    resolve(): Observable<QueryResultSet<RepairOrder>> {
        return this.repairService.getRepairOrdersWithSpecsAndPagination();
    }
}
