import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { Machine } from '@gtool.shared/models/Machine';

@Injectable()
export class MachinesListResolver implements Resolve<any> {
    constructor(private machineService: MachineService) {}

    resolve(): Observable<QueryResultSet<Machine>> {
        return this.machineService.getMachinesWithSpecs();
    }
}