import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RepairService } from '@gtool.shared/services/repair.service';
import { RepairTaskInstance } from '@gtool.shared/models/RepairTaskInstance';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-repair-task-instances',
    templateUrl: './repair-task-instances.component.html',
    styleUrls: ['./repair-task-instances.component.css'],
})
export class RepairTaskInstancesComponent implements OnInit, OnDestroy {
    public repairOrderId: number;
    public repairId: number;
    public taskId: number;

    public currentRepairTaskInstance: RepairTaskInstance;
    public repairTaskInstances: RepairTaskInstance[];
    private subscription: Subscription;

    constructor(
        private repairService: RepairService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.params.subscribe((p) => {
            this.repairId = p['rid'];
            this.repairOrderId = p['id'];
            this.taskId = p['tid'];

            this.fetchRemoteInfo();
        });

        // if a repair changes from another component - refetch
        this.subscription = this.repairService.repairTaskChange.subscribe((r) => {
          if( r === "fetch-remote" ){
            this.fetchRemoteInfo();
          }
        });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
    }

    private fetchRemoteInfo(): void {
        this.repairService
            .getRepairTaskInstances(
                this.repairOrderId,
                this.repairId,
                this.taskId
            )
            .subscribe((r) => {
                if (r.length > 0) {
                    this.currentRepairTaskInstance = r.shift();
                    if (
                        this.currentRepairTaskInstance.status ===
                        'repair.task.instance.finished'
                    ) {
                        r.unshift(this.currentRepairTaskInstance);
                        this.currentRepairTaskInstance = null;
                    }
                    this.repairTaskInstances = r;
                }
            });
    }
}
