import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { TranslateProcessPipe } from './translate-process.pipe';
import { TranslateProcessLinkComponent } from './translate-process-link/translate-process-link.component';
import { TranslateProcessModalComponent } from './translate-process-modal/translate-process-modal.component';
import { TranslateToggleComponent } from './translate-toggle/translate-toggle.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [TranslatePipe, TranslateProcessPipe, TranslateProcessLinkComponent, TranslateProcessModalComponent, TranslateToggleComponent],
    imports: [CommonModule, JwBootstrapSwitchNg2Module, ReactiveFormsModule ],
    exports: [TranslatePipe, TranslateProcessPipe, TranslateProcessLinkComponent, TranslateProcessModalComponent, TranslateToggleComponent],
})
export class I18nModule {}
