import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product, ProductType, ProductRequest, CartRequest } from '@gtool.shared/models/models';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { GenerateSerialsRequest } from '@gtool.shared/models/GenerateSerialsRequest';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {}

  static readonly URL_GET_PRODUCTS = '/api/eshop/products';
  static readonly URL_GET_PRODUCT = '/api/eshop/products/:id';
  static readonly URL_GET_PRODUCT_CONTENT = '/api/eshop/products/:id/content';
  static readonly URL_GET_PRODUCT_IMAGE = '/api/eshop/products/:id/image';
  static readonly URL_GET_MY_PRODUCTS = '/api/eshop/products/my';
  static readonly URL_GET_PRODUCT_TYPES = '/api/eshop/products/types';
  static readonly URL_ADD_TO_CART = '/api/eshop/cart/add';
  static readonly URL_POST_PRODUCT_SERIAL = '/api/eshop/products/:id/serials';
  static readonly URL_GET_PRODUCT_SERIAL = '/api/eshop/products/:id/serials/:batch';
  static readonly URL_GET_PRODUCT_SERIAL_BATCHES = '/api/eshop/products/:id/serials/batch';

  static readonly URL_GET_UNPRINTED_SERIALS = '/api/eshop/products/serials/unprinted';


  public getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(
        UtilService.getUrl(ProductService.URL_GET_PRODUCT, [{ k: ':id', v: id }])
    );
  }

  public getProductContent(id: string): Observable<string> {
    return this.http.get(
        UtilService.getUrl(ProductService.URL_GET_PRODUCT_CONTENT, [{ k: ':id', v: id }]),
        {responseType: 'text'}
    );
  }

  public getMyProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(
        UtilService.getUrl(ProductService.URL_GET_MY_PRODUCTS)
    );
}

  public getProducts(): Observable<Product[]> {
      return this.http.get<Product[]>(
          UtilService.getUrl(ProductService.URL_GET_PRODUCTS)
      );
  }

  public getProductsTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(
        UtilService.getUrl(ProductService.URL_GET_PRODUCT_TYPES)
    );
  }

  public createNewProduct(data: FormData): Observable<Product> {

    return this.http
      .post<Product>( UtilService.getUrl(ProductService.URL_GET_PRODUCTS), data,
      /*
        { headers: {
          'Content-Type': 'multipart/form-data' }
        }
        */
      );
  }

  public addToCart(data: CartRequest): Observable<any> {
    return this.http
      .post<Product>( UtilService.getUrl(ProductService.URL_ADD_TO_CART), data);
  }

  public getMaxSerialBatch(id: number): Observable<number> {
    return this.http.get<number>(
        UtilService.getUrl(ProductService.URL_GET_PRODUCT_SERIAL_BATCHES, [{ k: ':id', v: id }])
    );
  }

  public generateSerials(productId: number, data: GenerateSerialsRequest): Observable<number> {

    return this.http
      .post<number>( UtilService.getUrl(ProductService.URL_POST_PRODUCT_SERIAL, [{ k: ':id', v: productId }]), data,
      );
  }

}
