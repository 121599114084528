import { ValidatorFn, FormControl } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationDetails } from '@gtool.shared/models/models';

export function CreditValidator(
    authService: AuthenticationService
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const offer = control.value;
        const org: OrganisationDetails = authService.getCurrentOrganisation();
        if (offer === '') {
            return null;
        } else if (offer.trim() === '' || isNaN(offer)) {
            return { 'credit-format': { value: control.value } };
        } else if (!authService.isRoot(org) && offer > org.credit) {
            return { 'credit-limits': { value: control.value } };
        } else {
            return null;
        }
    };
}

export function OrganisationChildValidator(
    childrenOrgs: { id: number; name: string }[]
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const offer = control.value.id;
        if (offer === undefined) {
            if (control.value === '') {
                // Perhaps input fields contains an empty string (eg. when modal opens)
                return null;
            } else {
                // If not contains an empty string the user entered an invalid input, therefore control.value.id = undefined
                return { 'organisation-child': { value: control.value } };
            }
        } else if (!childrenOrgs.find((o) => o.id === offer)) {
            return { 'organisation-child': { value: control.value.name } };
        }
    };
}

export function PasswordConfirmValidator(passwd: FormControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const passwdConfirmValue = control.value;
        const passwdValue = passwd.value;
        if (passwdValue !== passwdConfirmValue) {
            return { 'password-confirm': { value: false } };
        } else {
            return null;
        }
    };
}

export function OverdraftValidator(
    min: number,
    max: number,
    allowedControl: FormControl
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const overdraft = control.value;
        if (allowedControl.value) {
            if (isNaN(overdraft)) {
                return { 'overdraft-format': { value: control.value } };
            } else if (overdraft < min || overdraft > max) {
                return { 'overdraft-limits': { value: control.value } };
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
}
