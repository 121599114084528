import { Component, OnInit } from '@angular/core';

import { OrganisationDetails } from '@gtool.shared/models/models';

import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})
export class CreditsComponent implements OnInit {

  public organisationDetails: OrganisationDetails;
  public isRoot: boolean;

  constructor(
    private authService: AuthenticationService,
    private orgService: OrganisationServiceService
    ) {}

    public abs(value:number):number {
      return Math.abs(value);
    }

    async ngOnInit() {
      this.organisationDetails = this.authService.getCurrentOrganisation();
      this.isRoot = this.authService.isRoot(this.organisationDetails);

      this.orgService.creditChange.subscribe(creditChange => {
        this.authService.updateCurrentOrganisation().then( r => {
          this.organisationDetails = r;
        });
      });
    }
}
