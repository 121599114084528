<ng-template #translateProcessModal let-modal>

  <form [formGroup]="i18nForm">
    <div class="modal-header">
      <h5 class="modal-title">Translate</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">

        <div class="row">
          <div class="col">
              <label for="credits">{{'msg.default' | translate}}:</label>&nbsp;
              <a *ngIf="allowEditDefault && !editDefault" (click)="enableEditDefault($event)" href="#" title="{{'msg.edit' | translate}}"><i
                  class="fa fa-edit" aria-hidden="true"></i>&nbsp;</a>

              <textarea
              rows="3"
              formControlName="translation_default"
              class="form-control"
          ></textarea>
          </div>
        </div>

          <div *ngFor="let language of languages" class="row mt-3">
              <div class="col">
                  <label for="credits">{{language.nativeName}}:</label>&nbsp;
                  <a (click)="autoTranslate($event, language.iso)" href="#" title="{{'msg.translate.auto' | translate}}"><i class="fa fa-language" aria-hidden="true"></i>&nbsp;</a>
                  <textarea
                  rows="3"
                  formControlName="{{'translation_' + language.iso}}"
                  class="form-control"
                  required
              ></textarea>
              </div>
          </div>
          <div class="row mt-3" *ngIf="isRepairStep">
            <div class="col">
                <label for="applyToAll">Apply to all repairs:
                <input
                  id="applyToAll"
                  class="form-control"
                  formControlName="applyToAll"
                  type="checkbox"
               /></label>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{ 'msg.close' | translate }}</button>
      <button type="submit" class="btn btn-primary" [disabled]="false" (click)="onSubmit()">Save</button>
    </div>
  </form>












</ng-template>
