import { Translation } from "./RepairTask";
import { RepairTool } from "./RepairTool";

export class RepairTaskStep {
    order?: number;
    image?: number;
    vUrl?: string;
    type?: string;
    prompt?: string;
    prompt_i18n?: Translation[];
    info?: string;
    info_i18n?: Translation[];
    sku?: string;
    scanPart?: string;
    side?: string[];
    stage?: string;
    cases?: RepairTaskStepcondition[];
    important?: string;
    important_i18n?: Translation[];
}

export class RepairTaskStepcondition {
  order?: number;
  prompt?: string;
  style?: string;
  targetId?: number;
  type?: string;
}

export class ProcessI18nRequest {
  repairTypeId?: number;
  taskId?: number;
  stepId?: number;
  target?: string;
  type?: string;
  applyToAll?: boolean;
  translations?: Translation[];
}
