export class EngraveInfo {
    center_X_negative?: number;
    center_X?: number;
    center_Y_negative?: number;
    center_Y?: number;
    width?: number;
    height?: number;
    reverse?: number;
    markingtimes?: number;
    pointsTime?: number;
    fixedDPI?: number;
    dpiX?: number;
    dpiY?: number;
    brightnessProcessing?: number;
    brightnessIsNegative?: number;
    brightness?: number;
    contrastIsNegative?: number;
    contrast?: number;
}

export class EngraveDto {
  power?: number;
  freq?: number;
  speed?: number;
  info?: EngraveInfo;
}
