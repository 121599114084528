<div class="dropdown-divider"></div>
<div class="row dropdown-item">
    <div class="col">
        <label for="translateToggle" class="">
            {{ 'msg.translate.inline' | translate }}:&nbsp;
        </label>

        <bSwitch
            id="translateToggle"
            style="word-wrap: nowrap !important"
            [switch-size]="'mini'"
            [switch-on-text]="'msg.yes' | translate"
            [switch-off-text]="'msg.yes' | translate"
            (changeState)="onChange($event)"
        ></bSwitch>
    </div>
</div>
