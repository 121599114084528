import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn()) {
            return true;
        } else {
            // try silent -- synchronous
            const response = await this.authService.silentLogin();
            if ( response ) {
                this.router.navigate([state.url]);
                return true;
            } else {
                this.handleLoggedout(state);
                return false;
            }
        }
    }

    private handleLoggedout( state: RouterStateSnapshot ): void {
        // not logged in so redirect to login page and save the return url
        localStorage.setItem('login_request_url', state.url);
        this.router.navigate(['/login']);
    }
}
