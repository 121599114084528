import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Machine } from '@gtool.shared/models/Machine';
import { UtilService } from './util.service';
import {
    MachineType,
    MachineCommandRequest,
} from '@gtool.shared/models/models';
import { MachineRequest } from '@gtool.shared/models/MachineRequest';
import { MachineFirmware } from '@gtool.shared/models/MachineFirmware';
import { MachineFirmwareRequest } from '@gtool.shared/models/MachineFirmwareRequest';
import { Paging } from '@gtool.shared/models/Paging';
import { Direction } from '@gtool.shared/models/Direction';
import { DirectionType } from '@gtool.shared/models/DirectionType';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { Filter } from '@gtool.shared/models/Filter';
import { FilterOperatorType } from '@gtool.shared/models/FilterOperatorType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { EngraveDto } from '@gtool.shared/models/EngraveInfo';

@Injectable({
    providedIn: 'root',
})
export class MachineService {
    static readonly URL_GET_MACHINE_TYPES = '/api/machines/types';
    static readonly URL_CREATE_MACHINE = '/api/machines';
    static readonly URL_GET_ALL_MACHINES = '/api/machines';
    static readonly URL_GET_MACHINES = '/api/machines/query';
    static readonly URL_GET_MACHINES_CSV = '/api/machines/csv';
    static readonly URL_GET_MACHINE = '/api/machines/:id';
    static readonly URL_POST_MACHINE_UPGRADE = '/api/machines/:id/upgrade';
    static readonly URL_POST_MACHINE_RESET = '/api/machines/:id/reset';
    static readonly URL_GET_MY_MACHINES = '/api/machines/my';
    static readonly URL_GET_MACHINE_CMD = '/api/machines/:id/command';
    static readonly URL_GET_ALL_MACHINE_FIRMWARES_CMD =
        '/api/machines/types/firmware';
        static readonly URL_GET_MACHINE_FIRMWARES_CMD =
        '/api/firmware/query';
    static readonly URL_GET_MACHINE_FIRMWARE_CMD =
        '/api/machines/types/:tid/firmware/:fid/:type';

    static readonly URL_POST_MACHINE_SCAN_UPLOAD =
        '/api/machines/:mid/scan-image/upload';
    static readonly URL_GET_MACHINE_SCAN_IMAGE = '/api/machines/scan-image';
    static readonly URL_POST_MACHINE_TRANSFER = '/api/machines/:id/transfer';
    static readonly URL_POST_MACHINE_SCAN_ENGRAVE =
        '/api/machines/:mid/scan-image/engrave';

    constructor(private http: HttpClient) {}

    public exportCsv(): Observable<HttpResponse<Blob>> {
        return this.http.get(
            UtilService.getUrl(MachineService.URL_GET_MACHINES_CSV),
            { observe: 'response', responseType: 'blob' }
        );
    }

    getMachineTypes(): Observable<MachineType[]> {
        return this.http.get<MachineType[]>(
            UtilService.getUrl(MachineService.URL_GET_MACHINE_TYPES)
        );
    }

    public getMachine(id: string): Observable<Machine> {
        return this.http.get<Machine>(
            UtilService.getUrl(MachineService.URL_GET_MACHINE, [
                { k: ':id', v: id },
            ])
        );
    }

    public getMachines(): Observable<Array<Machine>> {
        return this.http.get<Array<Machine>>(
            UtilService.getUrl(MachineService.URL_GET_ALL_MACHINES)
        );
    }

	public getMachinesWithSpecs(query?: QuerySpecification): Observable<QueryResultSet<Machine>> {
        let qr: QuerySpecification = new QuerySpecification();

        if (query !== null && query !== undefined) {
            qr = query;
        }else{
            let p: Paging = new Paging();
            p.page = 1;
            p.limit = 10;
            qr.p = p;

        }
        return this.http.post<QueryResultSet<Machine>>(
            UtilService.getUrl(MachineService.URL_GET_MACHINES), qr);
    }

    public getMyMachines(): Observable<Array<Machine>> {
        return this.http.get<Array<Machine>>(
            UtilService.getUrl(MachineService.URL_GET_MY_MACHINES)
        );
    }

    public getMachineFirmwares(): Observable<Array<MachineFirmware>> {
        return this.http.get<Array<Machine>>(
            UtilService.getUrl(MachineService.URL_GET_ALL_MACHINE_FIRMWARES_CMD)
        );
    }

	// Custom pagination order for sorting
    public getMachinesFirmwareWithSpecs(query?: QuerySpecification): Observable<QueryResultSet<MachineFirmware>> {
      let qr: QuerySpecification = new QuerySpecification();

      if (query !== null && query !== undefined) {
          qr = query;
      }else{
          let p: Paging = new Paging();
          p.page = 1;
          p.limit = 10;
          p.order = "AmachineType.name,DproductVersion,DfirmwareVersion";
          qr.p = p;

      }
      return this.http.post<QueryResultSet<MachineFirmware>>(
          UtilService.getUrl(MachineService.URL_GET_MACHINE_FIRMWARES_CMD), qr);
  }

    public createNewMachineFirmware(
        data: FormData
    ): Observable<MachineFirmware> {
        return this.http.post<Machine>(
            UtilService.getUrl(MachineService.URL_GET_ALL_MACHINE_FIRMWARES_CMD),
            data
        );
    }

    public createNewMachine(data: MachineRequest): Observable<Machine> {
        console.debug(data);
        return this.http.post<Machine>(
            UtilService.getUrl(MachineService.URL_CREATE_MACHINE),
            data
        );
    }

    public sendCommand(data: MachineCommandRequest): Observable<any> {
        return this.http.post<Machine>(
            UtilService.getUrl(MachineService.URL_GET_MACHINE_CMD, [
                { k: ':id', v: data.machineId },
            ]),
            data
        );
    }

    public upgradeMachine(machineId: number): Observable<any> {
        return this.http.post<Machine>(
            UtilService.getUrl(MachineService.URL_POST_MACHINE_UPGRADE, [
                { k: ':id', v: machineId },
            ]),
            {}
        );
    }

    public resetMachine(machineId: number): Observable<any> {
        return this.http.post<Machine>(
            UtilService.getUrl(MachineService.URL_POST_MACHINE_RESET, [
                { k: ':id', v: machineId },
            ]),
            {}
        );
    }

    public updateScanImage(machineId: number, data: FormData): Observable<any> {
        return this.http.post<any>(
            UtilService.getUrl(MachineService.URL_POST_MACHINE_SCAN_UPLOAD, [
                { k: ':mid', v: machineId },
            ]),
            data
        );
    }

    public engraveScanImage(machineId: number, data: EngraveDto): Observable<any> {
      return this.http.post<any>(
          UtilService.getUrl(MachineService.URL_POST_MACHINE_SCAN_ENGRAVE, [
              { k: ':mid', v: machineId },
          ]),
          data
      );
  }
    public transferMachine(machineId:number, repairPointId:number): Observable<Machine> {
      return this.http.post<Machine>(
          UtilService.getUrl(MachineService.URL_POST_MACHINE_TRANSFER, [{ k: ':id', v: machineId }]),
          repairPointId
      );
  }
}
