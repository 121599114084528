<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText | translate }}</button>
    <button type="button" class="btn btn-success" (click)="accept()">{{ btnOkText | translate }}</button>
  </div>
