import { Component, OnInit } from '@angular/core';
import { I18nService } from '@gtool.i18n/i18n.service';

@Component({
  selector: 'i18n-toggle',
  templateUrl: './translate-toggle.component.html',
  styleUrls: ['./translate-toggle.component.css']
})
export class TranslateToggleComponent implements OnInit {

  constructor(private i18nService: I18nService) { }

  ngOnInit(): void {
  }

  public onChange($event): void {
    this.i18nService.setShowI18n($event.currentValue);
    this.i18nService
    .translationPopup()
    .next({ 'event': 'toggle',  'show': $event.currentValue });
  }

}
