import { ProductType } from './ProductType';

export class Product {
    id?: number;
    productTypeId?: number;
    productType?: ProductType;
    stock?: number;
    sku?: string;
    name?: string;
    content?: string;
    traceable?: boolean;
    hasImage?: boolean;
    serializable?: boolean;
}

