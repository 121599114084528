<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h6 class="title">Machine: {{machine?.serialNo}}</h6>
        </div>
        <div class="card-body">

          last check:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{lastCheck}} <br/>
          last command: {{lastComm}} <br/>

          <span *ngIf="connected"
              class="badge badge-success"
              >Connected</span
          >
          <span *ngIf="!connected"
              class="badge badge-danger"
              >Disonnected</span
          >

          &nbsp;

            <span
            *ngIf="connected && cmd?.status?.operation"
                class="badge"
                [ngClass]="{'READY':'badge-success','ERROR':'badge-danger','UPGRADING':'badge-warning', 'BOOTING': 'badge-warning', 'NONE': 'badge-normal'} [cmd?.status?.operation]"


                > {{ 'machine.operational.status.' + cmd?.status?.operation.toLowerCase() | translate }}</span
            >

            &nbsp;
            <span
                *ngIf="connected && cmd?.status?.lid"
                class="badge"
                [ngClass]="{'CLOSED':'badge-success','OPEN':'badge-warning'} [cmd?.status?.lid]"


                > {{ 'machine.lid.status.' + cmd?.status?.lid.toLowerCase() | translate }}</span
            >
      </div>
    </div>
  </div>
</div>
