import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { OrganisationsComponent } from '@gtool.organisation/organisations/organisations.component';
import { OrganisationViewComponent } from '@gtool.organisation/organisation-view/organisation-view.component';
import { OrganisationsResolver } from '@gtool.resolvers/organisations.resolver';
import { OrganisationsListResolver } from '@gtool.resolvers/organisations-list.resolver';
import { OrganisationFormComponent } from './organisation-form/organisation-form.component';

const routes: Routes = [
    {
        path: '', pathMatch: 'full',
        component: OrganisationsComponent,
        canActivate: [AuthGuard],
        resolve: { list: OrganisationsListResolver },
        data: {title: 'msg.organisations'}
    },
    {
        path: ':id/details',
        component: OrganisationViewComponent,
        canActivate: [AuthGuard],
        resolve: { details: OrganisationsResolver },
        data: {title: 'msg.organisation'}
    },
    {
        path: ':type/:id',
        component: OrganisationFormComponent,
        resolve: { list: OrganisationsListResolver },
        canActivate: [AuthGuard],
        data: {title: 'msg.organisation.form'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OrganisationRoutingModule {}
