import { Component, OnInit } from '@angular/core';

import { OrganisationDetails } from '@gtool.shared/models/models';
import { CreditHistory } from '@gtool.shared/models/CreditHistory';
import { CreditRequest } from '@gtool.shared/models/CreditRequest';
import { CreditRequestType } from '@gtool.shared/models/CreditRequestType';
import { Configuration } from '@gtool.config/configuration';

import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.css']
})
export class CreditHistoryComponent implements OnInit {

  private _organisationDetails: OrganisationDetails;
  private _creditHistory: CreditHistory;
  private _creditRequest: CreditRequest = new CreditRequest();

  constructor(
    private authService: AuthenticationService,
    private orgService: OrganisationServiceService,
    public configuration: Configuration,
    private router: Router,
    private route: ActivatedRoute,
    ) {}

  ngOnInit() {
    this.organisationDetails = this.authService.getCurrentOrganisation();
    this.creditRequest.action = CreditRequestType.QUERY;
    this.orgService.getCreditHistory(this.organisationDetails.id, this.creditRequest).subscribe(resp => {
      this.creditHistory = resp;
    });


    // subscribe on event creditChange (transfer / purchase) to re-fetch credit list
    this.orgService.creditChange.subscribe(creditChange => {
      this.orgService.getCreditHistory(this.organisationDetails.id, this.creditRequest).subscribe(resp => {
        this.creditHistory = resp;
      });
    });
  }

  protected redirectToMachineJob(item: CreditHistory):void {
    this.orgService.getMachineJob(this.organisationDetails.id, item.id, item.machineJobUuid).subscribe(resp => {
      this.router.navigate(['../repairs', resp.repairOrderId, 'repair', resp.repairId, 'task', resp.repairTaskId], {relativeTo: this.route});
    });
  }

  get organisationDetails(): OrganisationDetails {
    return this._organisationDetails;
  }

  set organisationDetails(organisationDetails: OrganisationDetails) {
    this._organisationDetails = organisationDetails;
  }

  get creditHistory(): CreditHistory {
    return this._creditHistory;
  }

  set creditHistory(creditHistory: CreditHistory) {
    this._creditHistory = creditHistory;
  }

  get creditRequest(): CreditRequest {
    return this._creditRequest;
  }

  set creditRequest(creditRequest: CreditRequest) {
    this._creditRequest = creditRequest;
  }

}
