<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <ng-container *ngIf="mode === 'edit'">
                        <h6 class="title">{{ organisation?.name }}</h6>
                        <p class="category">
                            {{ 'msg.gcredit.id' | translate }}
                            {{ organisation?.gCreditId }}
                        </p>
                    </ng-container>

                    <ng-container *ngIf="mode === 'new'">
                        <h6 class="title">
                            {{ 'msg.add' | translate }}
                            <span *ngIf="type === 'distributor'">
                                {{ 'resource.type.distributor' | translate }}
                            </span>
                            <span *ngIf="type === 'repair-point'">
                                {{ 'resource.type.repair.point' | translate }}
                            </span>
                        </h6>
                    </ng-container>
                </div>
                <div class="card-body">
                    <form [formGroup]="detailsForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-3">
                                <label for="machinetype">Parent Name:</label>
                                <select
                                    formControlName="parentId"
                                    class="form-control"
                                    id="parentId"
                                >
                                    <option value="" selected disabled>
                                        Select Parent
                                    </option>
                                    <option
                                        *ngFor="let distributor of distributors"
                                        value="{{ distributor.id }}"
                                    >
                                        {{ distributor.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="gCreditId" class="">
                                        {{ 'msg.gcredit.id' | translate }}
                                    </label>

                                    <input
                                        type="text"
                                        formControlName="gCreditId"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.gcredit.id' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="email" class="">
                                        {{ 'msg.cmp.email' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="email"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.email' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="aggrementDate" class="">
                                        {{ 'msg.cmp.aggr.date' | translate }}
                                    </label>
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            formControlName="aggrementDate"
                                            class="form-control"
                                            placeholder="dd/mm/yyyy"
                                            ngbDatepicker
                                            #d="ngbDatepicker"
                                            required
                                        />
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-calendar m-0"
                                                (click)="d.toggle()"
                                                type="button"
                                            >
                                                <i
                                                    class="now-ui-icons ui-1_calendar-60"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="name" class="">
                                        {{ 'msg.cmp.name' | translate }}
                                    </label>

                                    <input
                                        type="text"
                                        formControlName="name"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.name' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="regNumber" class="">
                                        {{ 'msg.cmp.reg.number' | translate }}
                                    </label>

                                    <input
                                        type="text"
                                        formControlName="regNumber"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.reg.number' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="vatTax" class="">
                                        {{ 'msg.cmp.vat.number' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="vatTax"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.vat.number' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="telephone" class="">
                                        {{ 'msg.cmp.telephone' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="telephone"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.telephone' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="address" class="">
                                        {{ 'msg.cmp.address' | translate }}
                                    </label>
                                    <textarea
                                        rows="3"
                                        formControlName="address"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.address' | translate
                                        }}"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label for="city" class="">
                                        {{ 'msg.cmp.city' | translate }}
                                    </label>
                                    <input
                                        type="test"
                                        formControlName="city"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.city' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <div class="form-group">
                                    <label for="postCode" class="">
                                        {{ 'msg.cmp.postcode' | translate }}
                                    </label>
                                    <input
                                        type="test"
                                        formControlName="postCode"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.postcode' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <label for="countryId">Country:</label>
                                <select
                                    formControlName="countryId"
                                    class="form-control"
                                    id="countryId"
                                >
                                    <option value="" selected>
                                        Select Country
                                    </option>
                                    <option
                                        *ngFor="let country of countries"
                                        value="{{ country.id }}"
                                    >
                                        {{ country.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="commPersonFirstName" class="">
                                        {{ 'msg.cmp.comm.person' | translate }}
                                        {{ 'msg.first.name' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="commPersonFirstName"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.first.name' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="commPersonLastName" class="">
                                        {{ 'msg.cmp.comm.person' | translate }}
                                        {{ 'msg.last.name' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="commPersonLastName"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.last.name' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="techPersonFirstName" class="">
                                        {{ 'msg.cmp.tech.person' | translate }}
                                        {{ 'msg.first.name' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="techPersonFirstName"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.first.name' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="techPersonLastName" class="">
                                        {{ 'msg.cmp.tech.person' | translate }}
                                        {{ 'msg.last.name' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="techPersonLastName"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.last.name' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="bank" class="">
                                        {{ 'msg.cmp.bank' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="bank"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.bank' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="bankAccNumber" class="">
                                        {{
                                            'msg.cmp.bank.accNumber' | translate
                                        }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="bankAccNumber"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.bank.accNumber' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="bankSwift" class="">
                                        {{ 'msg.cmp.bank.swift' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="bankSwift"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.bank.swift' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="bankBranch" class="">
                                        {{ 'msg.cmp.bank.branch' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="bankBranch"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.cmp.bank.branch' | translate
                                        }}"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="this.type === 'repair-point'">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="overdraftAllow" class="">
                                        {{ 'msg.overdraft.allow' | translate }}
                                    </label>
                                    <bSwitch
                                        id="overdraftAllow"
                                        [formControl]="
                                            detailsForm.controls[
                                                'overdraftAllow'
                                            ]
                                        "
                                        style="
                                            word-wrap: normal !important;
                                            display: block;
                                        "
                                        [switch-size]="'normal'"
                                        [switch-on-text]="translate('msg.yes')"
                                        [switch-off-text]="translate('msg.yes')"
                                        (changeState)="overdraftChanged()"
                                    ></bSwitch>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="overdraftAmount" class="">
                                        {{ 'msg.overdraft.amount' | translate }}
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="overdraftAmount"
                                        class="form-control"
                                        placeholder="{{
                                            'msg.overdraft.amount' | translate
                                        }}"
                                    />
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="allowDemo" class="">
                                        {{
                                            'msg.allow.demo.repairs' | translate
                                        }}
                                    </label>
                                    <bSwitch
                                        id="allowDemo"
                                        [formControl]="
                                            detailsForm.controls['allowDemo']
                                        "
                                        style="
                                            word-wrap: normal !important;
                                            display: block;
                                        "
                                        [switch-size]="'normal'"
                                        [switch-on-text]="translate('msg.yes')"
                                        [switch-off-text]="translate('msg.yes')"
                                    ></bSwitch>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <label for="pricingPolicyGroupId"
                                    >Pricing Policy:</label
                                >
                                <select
                                    formControlName="pricingPolicyGroupId"
                                    class="form-control"
                                    id="pricingPolicyGroupId"
                                >
                                    <option value="" selected>Default</option>
                                    <option
                                        *ngFor="
                                            let pricingPolicyGroup of pricingPolicyGroups
                                        "
                                        value="{{ pricingPolicyGroup.id }}"
                                    >
                                        {{ pricingPolicyGroup.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <button
                            class="btn btn-primary pull-right"
                            type="submit"
                            [disabled]="!detailsForm.valid"
                        >
                            {{ 'msg.save' | translate }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
