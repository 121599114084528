import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });

    public showError: boolean;

    constructor(
        private router: Router,
        private authService: AuthenticationService
    ) {
        this.showError = false;
    }

    async ngOnInit() {
        if (this.authService.isLoggedIn()) {
            this.determineRedirect();
        } else { // try silent login
            const response = await this.authService.silentLogin();
            if ( response ) {
                this.determineRedirect();
            }
        }
    }

    public onSubmit() {
        this.authService
            .authorize(
                this.loginForm.controls['username'].value,
                this.loginForm.controls['password'].value,
                'password'
            )
            .subscribe(
                r => {
                    this.determineRedirect();
                },
                e => {
                    this.handleEror(e);
                }
            );
    }

    private determineRedirect(): void {
        let returnUrl: string = localStorage.getItem('login_request_url');
        localStorage.removeItem('login_request_url');
        if (!returnUrl) {
            returnUrl = '/cp';
        }
        this.router.navigate([returnUrl]);
    }

    private handleEror(e) {
        const error: string = e.error;
        if ('unauthorized' === error || 'invalid_grant' === error) {
            this.showError = true;
        }
    }
}
