import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
    NgbModule,
    NgbDateAdapter,
    NgbDatepicker,
    NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@gtool/app-routing.module';
import { AppComponent } from '@gtool/app.component';
import { LoginComponent } from '@gtool/login/login.component';
import { TokenInterceptor } from '@gtool.shared/interceptors/token.interceptor';
import { OAuthRequestInterceptor } from '@gtool.shared/interceptors/oauth.request.interceptor';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { SharedModule } from '@gtool.shared/shared.module';
import { OrganisationModule } from '@gtool.organisation/organisation.module';
import { RepairOrdersModule } from '@gtool/repair-orders/repair-orders.module';
import { CreditsModule } from './credits/credits.module';
import { MachineModule } from './machine/machine.module';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbDateCustomParserFormatter } from '@gtool.shared/custom-validation/dateformat';
import { GlobalResolver } from '@gtool.resolvers/global.resolver';
import {
    InjectableRxStompConfig,
    RxStompService,
    rxStompServiceFactory,
} from '@stomp/ng2-stompjs';
import { myRxStompConfig } from './my-rx-stomp.config';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ReportsModule } from './reports/reports.module';
import { ErrorInterceptor } from '@gtool.shared/interceptors/error.interceptor';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component'
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { APP_INITIALIZER } from '@angular/core';
import { I18nService } from '@gtool.i18n/i18n.service';
import { RepairPreviewModule } from './repair-preview/repair-preview.module';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';

@NgModule({
    declarations: [AppComponent, LoginComponent, ControlPanelComponent, ConfirmationDialogComponent],
    imports: [
        ApmModule,
        HttpClientModule,
        BrowserModule,
        ReactiveFormsModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            closeButton: true,
            enableHtml: true,
            positionClass: 'toast-bottom-right',
        }),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        AppRoutingModule,
        RepairOrdersModule,
        I18nModule,
        SharedModule,
        OrganisationModule,
        MachineModule,
        CreditsModule,
        ReportsModule,
        RepairPreviewModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
      ApmService,
      I18nService,
      {
        provide: APP_INITIALIZER,
        useFactory: (i18n: I18nService) => () => {return i18n.init()},
        deps: [I18nService],
        multi: true
      },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OAuthRequestInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        {
            provide: InjectableRxStompConfig,
            useValue: myRxStompConfig,
        },
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
            deps: [InjectableRxStompConfig],
        },
        ConfirmationDialogService,
        GlobalResolver,
    ],
    entryComponents: [ ConfirmationDialogComponent ],
    bootstrap: [AppComponent],
    exports: [LoginComponent],
})
export class AppModule {
  constructor(service: ApmService) {
    /*
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: 'gtool-ui',
      serverUrl: 'http://localhost:8200'
    });
    */
  }
}
