import { Injectable } from '@angular/core';
import { RevenueReportRequest, RevenenueReport } from '@gtool.shared/models/models';
import { Observable } from 'rxjs';
import { UtilService } from './util.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CreditSearchRequest } from '@gtool.shared/models/CreditSearchRequest';

@Injectable({
    providedIn: 'root',
})
export class ReportsServiceService {
    static readonly URL_REVENUE_REPORT = '/api/reports/revenue';
    static readonly URL_REVENUE_REPORT_DOWNLOAD =
        '/api/reports/revenue/download';

        static readonly URL_CREDIT_REPORT_DOWNLOAD =
        '/api/reports/credit/download';

    constructor(private http: HttpClient) {}

    public downloadRevenueReport(
        revenueReportRequest: RevenueReportRequest
    ): Observable<HttpResponse<Blob>>  {
        return this.http.post(
            UtilService.getUrl(ReportsServiceService.URL_REVENUE_REPORT_DOWNLOAD),
            revenueReportRequest,
            {observe: 'response', responseType: 'blob'}
        );
    }

  public getRevenueReport(
      revenueReportRequest: RevenueReportRequest
  ): Observable<RevenenueReport>  {
      return this.http.post<RevenenueReport>(
          UtilService.getUrl(ReportsServiceService.URL_REVENUE_REPORT),
          revenueReportRequest
      );
  }

  public downloadCreditReport(
    creditSearchRequest: CreditSearchRequest
): Observable<HttpResponse<Blob>>  {
    return this.http.post(
        UtilService.getUrl(ReportsServiceService.URL_CREDIT_REPORT_DOWNLOAD),
        creditSearchRequest,
        {observe: 'response', responseType: 'blob'}
    );
}
}
