import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { RepairTaskInstance } from '@gtool.shared/models/RepairTaskInstance';
import { RepairService } from '@gtool.shared/services/repair.service';
import { RepairTaskInstanceImage } from '@gtool.shared/models/RepairTaskInstanceImage';
import { UtilService } from '@gtool.shared/services/util.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-repair-task-instance',
    templateUrl: './repair-task-instance.component.html',
    styleUrls: ['./repair-task-instance.component.css'],
})
export class RepairTaskInstanceComponent implements OnInit, OnDestroy {
    @ViewChild('instanceNav') instanceNav;
    @Input() repairTaskInstance: RepairTaskInstance;
    @Input() isCollapsed = false;
    @Input() isToggleAllowed = true;
    public duration: Date = null;

    public imagesBeforeFront: RepairTaskInstanceImage[] = new Array();
    public imagesBeforeBack: RepairTaskInstanceImage[]= new Array();
    public imagesAfterFront: RepairTaskInstanceImage[]= new Array();
    public imagesAfterBack: RepairTaskInstanceImage[]= new Array();

    private subscription: Subscription;

    constructor(private repairService: RepairService, private authService: AuthenticationService) {}

    ngOnInit() {
        this.prepareDuration();
        this.fetchImages();

        // if a repair changes from another component - refetch
        this.subscription = this.repairService.repairTaskChange.subscribe((r) => {
          if( r === "switch-images" ){
            this.instanceNav.select("images");
          }else if( r === "fetch-images" ){
            this.fetchImages();
          }
        });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe();
  }

    private fetchImages() : void {
      this.repairService
      .getRepairTaskIntanceImages(
          this.repairTaskInstance.repairOrderId,
          this.repairTaskInstance.repairId,
          this.repairTaskInstance.repairTaskId,
          this.repairTaskInstance.id
      )
      .subscribe((r) => {
        this. imagesBeforeFront = r.filter( (x) => x.stage == 'BEFORE' && x.side == 'FRONT' );
        this. imagesBeforeBack = r.filter( (x) => x.stage == 'BEFORE' && x.side == 'BACK' );
        this. imagesAfterFront = r.filter( (x) => x.stage == 'AFTER' && x.side == 'FRONT' );
        this. imagesAfterBack = r.filter( (x) => x.stage == 'AFTER' && x.side == 'BACK' );
      });
    }

    private prepareDuration(): void {
        if (this.repairTaskInstance.duration > 0) {
            this.duration = new Date(0, 0, 0);
            this.duration.setMilliseconds(this.repairTaskInstance.duration);
        }
    }

    public getImageUrl( image: RepairTaskInstanceImage ): string {
      return UtilService.getUrl(RepairService.URL_REPAIR_ORDER_REPAIR_TASK_IMAGE, [
        { k: ':id', v: this.repairTaskInstance.repairOrderId },
        { k: ':rid', v: this.repairTaskInstance.repairId },
        { k: ':tid', v: this.repairTaskInstance.repairTaskId },
        { k: ':rtid', v: this.repairTaskInstance.id },
        { k: ':imageId', v: image.id }

      ]) +
          '?access_token=' + this.authService.getToken();
    }
}
