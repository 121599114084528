import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilService } from '@gtool.shared/services/util.service';
import { AutoTranslateRequest, AutoTranslateResponse, Language, LanguageKey, Translation } from '@gtool.i18n/models/models';
import { map, flatMap, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    static readonly URL_GET_LANGUAGES = '/api/i18n/languages';
    static readonly URL_GET_LANGUAGE_KEYS = '/api/i18n/translations/:lang';
    static readonly URL_POST_AUTO_AWS = '/api/i18n/auto/aws';

    private availableLanguages: Language[];
    private currentLanguage: Language;
    private currentTranslations: Map<string, Translation>;

    private _showI18n:boolean = false;
    private _translationPopup = new BehaviorSubject<any>({});

    constructor(private http: HttpClient) {
    }

    async init(): Promise<any> {
        return await this.getLanguagesRemote().pipe(
            map (langs => {
                this.availableLanguages = langs;
                this.currentLanguage = this.availableLanguages.find(lang=> lang.primary);
                return this.getTranslationsRemote(this.currentLanguage.iso).pipe(map(
                    trans => {
                        this.currentTranslations = new Map();
                        trans.forEach(tran => {
                            this.currentTranslations.set(
                                tran.languageKey.key,
                                tran
                            );
                        });
                    }
                )).subscribe();
            }
        )).toPromise().catch(e => { console.error(e) });
    }

    public getShowI18n(): boolean {
      return this._showI18n;
    }
    public setShowI18n(show: boolean): void {
       this._showI18n = show;
    }

    public translationPopup():BehaviorSubject<any>{
      return this._translationPopup;
    }

    public translate(key: string): string {
        if (!this.currentLanguage) {
            return 'error:currentLanguage';
        } else if (!this.currentTranslations) {
            return 'error:currentTranslations';
        } else {
            return this.currentTranslations.has(key)
                ? this.currentTranslations.get(key).value
                : null;
        }
    }

    public getLanguagesRemote(): Observable<Language[]> {
        return this.http.get<Language[]>(
            UtilService.getUrl(I18nService.URL_GET_LANGUAGES),
            {params:  new HttpParams().append('secure' , 'false')}
        );
    }

    public getTranslationsRemote(iso: string): Observable<Translation[]> {
        return this.http.get<Translation[]>(
            UtilService.getUrl(I18nService.URL_GET_LANGUAGE_KEYS, [
                { k: ':lang', v: iso },
            ]), {params:  new HttpParams().append('secure' , 'false')}
        );
    }

    public changeLanguage( iso: string): void {
        this.currentLanguage = this.availableLanguages.find( lang => iso === lang.iso);
        this.getTranslationsRemote(iso).pipe(map(
            trans => {
                this.currentTranslations = new Map();
                trans.forEach(tran => {
                    this.currentTranslations.set(
                        tran.languageKey.key,
                        tran
                    );
                });
            })).subscribe();
    }

    public getCurrentLanguage(): Language {
        return this.currentLanguage;
    }
    public getAvailableLanguages(): Language[] {
        return this.availableLanguages;
    }


    public autoTranslateAws(
      data: AutoTranslateRequest
  ): Observable<AutoTranslateResponse> {
      return this.http.post<AutoTranslateResponse>(
          UtilService.getUrl(
              I18nService.URL_POST_AUTO_AWS,
              [
              ]
          ),
          data
      );
  }
}
