import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { I18nService } from '@gtool.i18n/i18n.service';
import { environment } from '@env/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private i18n: I18nService, private toast: MyToasterService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if (!request.headers.get('S-SILENT') && request.url.startsWith(environment.backend_url)) {
                    var msg: string = 'error.unexpected';

                    if (error.status == 401) {
                        msg = 'error.' + error.error.error;
                    } else if (error.status == 400) {
                        msg = error.error.error_description;
                    } else if (error.error.message) {
                        msg = error.error.message;
                    }

                    msg = this.i18n.translate(msg);
                    if (error.error.args && error.error.args.length > 0) {
                        error.error.args.forEach((v, p) => {
                            msg = msg.replace('{' + p + '}', v);
                        });
                    }

                    console.log(msg);
                    this.toast.error(msg);
                }
                return throwError(msg);
            })
        );
    }
}
