<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8 col-xs-12">
                            <h6>{{ 'msg.add.repair.order' | translate }}</h6>
                        </div>
                        <div class="col-md-4 col-xs-12">
                            <a
                                class="btn btn-primary pull-right"
                                [routerLink]="['customer']"
                            >
                                {{ 'msg.add.customer' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <form [formGroup]="addOrderForm">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label for="model">
                                        {{ 'msg.customer' | translate }}:
                                    </label>
                                    <input
                                        id="customer"
                                        type="text"
                                        class="form-control"
                                        formControlName="customer"
                                        placeholder="{{
                                            'msg.customer' | translate
                                        }} "
                                        [ngbTypeahead]="search"
                                        ng-blur="(checkCustomerMatch())"
                                        (focus)="
                                            focus$.next($event.target.value)
                                        "
                                        (click)="
                                            click$.next($event.target.value)
                                        "
                                        #instance="ngbTypeahead"
                                    />
                                    <div
                                        *ngIf="!checkCustomerMatch()"
                                        class="alert alert-danger"
                                    >
                                        <div>
                                            {{
                                                'msg.customer.error' | translate
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                        <label for="aggrementDate" class="">
                                            {{ 'msg.due.date' | translate }}
                                        </label>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                formControlName="dateDue"
                                                class="form-control"
                                                placeholder="dd/mm/yyyy"
                                                ngbDatepicker
                                                #d="ngbDatepicker"
                                                required
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    class="btn btn-calendar m-0"
                                                    (click)="d.toggle()"
                                                    type="button"
                                                >
                                                    <i
                                                        class="now-ui-icons ui-1_calendar-60"
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                    <label class="" for="warranty">{{
                                        'msg.warranty.applicable' | translate
                                    }}</label>
                                    <input
                                        name="warranty"
                                        class="form-control"
                                        style="width:auto!important"
                                        id="warranty"
                                        type="checkbox"
                                        formControlName="warranty"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <label for="model">
                                        {{ 'msg.refNo' | translate }}:
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="refNo"
                                        placeholder="{{
                                            'msg.refNo' | translate
                                        }}"
                                        name="pin"
                                        class="form-control"
                                        id="refNo"
                                    />
                                </div>
                                <!--
                                <div class="col-md-6 col-xs-12">
                                    <label for="model">
                                        {{ 'msg.imei' | translate }} /
                                        {{ 'msg.serial' | translate }}:
                                    </label>
                                    <input
                                        type="text"
                                        formControlName="imei"
                                        placeholder="{{
                                            'msg.imei' | translate
                                        }}"
                                        name="imei"
                                        class="form-control"
                                        id="imei"
                                    />
                                </div>
                              -->
                            </div>

                            <div class="row ">
                                <div class="col-md-3 col-xs-12">
                                    <label for="phoneType">
                                        {{ 'msg.phone.type' | translate }}:
                                    </label>
                                    <select
                                        formControlName="phoneType"
                                        class="form-control"
                                        id="phoneType"
                                        (change)="getModels()"
                                    >
                                        <option value="" disabled selected>
                                            {{ 'msg.phone.type' | translate }}.
                                        </option>
                                        <option
                                            *ngFor="let phone of phoneTypes"
                                            [value]="phone.id"
                                        >
                                            {{ phone.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                    <label for="phoneModel">
                                        {{ 'msg.model' | translate }}:
                                    </label>
                                    <select
                                        formControlName="phoneModel"
                                        class="form-control"
                                        id="phoneModel"
                                        (change)="getRepairTypes()"
                                    >
                                        <option value="" selected>
                                            {{ 'msg.model' | translate }}.
                                        </option>
                                        <option
                                            *ngFor="let model of phoneModels"
                                            [value]="model.id"
                                        >
                                            {{ model.name }}
                                        </option>
                                    </select>
                                </div>



                                <div class="col-md-3 col-xs-12" *ngIf="selectedPhoneModel?.variances">
                                  <label for="variant">
                                      {{ 'msg.variant' | translate }}:
                                  </label>
                                  <select
                                      formControlName="variant"
                                      class="form-control"
                                      id="variant"
                                      (change)="updateVariant()"
                                  >
                                      <option value="" selected>
                                          {{ 'msg.variant' | translate }}.
                                      </option>
                                      <option
                                          *ngFor="let variant of phoneModelVariants"
                                          [value]="variant"
                                      >
                                        {{variant}}
                                      </option>
                                  </select>
                              </div>



                            </div>
                        </div>
                        <div class="row" *ngIf="phoneRepairTypes && showRepairs">
                            <div class="col-12  mt-3">
                                <h6>{{ 'msg.repairs.list' | translate }}</h6>
                            </div>

                            <div class="col-md-3 col-xs-12" *ngIf="phoneRepairTypes.length<=0">
                              <span class="badge badge-danger">{{ 'msg.no.repairs' | translate }}</span>
                            </div>

                            <div
                                *ngFor="
                                    let phoneRepairType of phoneRepairTypes;
                                    let i = index
                                "
                                class="col-md-3 col-xs-12"
                            >
                                <label
                                  *ngIf="!phoneRepairType.variances || phoneRepairType.variances.includes(selectedVariant)"
                                    class=""
                                    for="repairType_{{ phoneRepairType.id }}"
                                    >
                                    <span [innerHTML]="phoneRepairType | translateProcess: 'name'"></span>
                                    <span *ngIf="phoneRepairType.demo" class="badge badge-warning">demo</span>
                                    <input
                                        name="repairType_{{
                                            phoneRepairType.id
                                        }}"
                                        class=""
                                        style="width:auto!important; float:left; margin-right: 10px!important"
                                        id="repairType_{{ phoneRepairType.id }}"
                                        type="checkbox"
                                        formControlName="repairType_{{
                                            phoneRepairType.id
                                        }}"
                                        (change)="
                                            updateRepairs(phoneRepairType.id)
                                        "
                                    />
                                </label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12  mt-3">
                                <h6>{{ 'msg.check.list' | translate }}</h6>
                            </div>

                            <div
                                *ngFor="let item of checkListItems"
                                class="col-md-3 col-xs-12"
                            >
                                <label class="" for="checkList_{{ item.id }}"
                                    >{{ item.name | translate }}
                                    <input
                                        name="checkList_{{ item.id }}"
                                        class=""
                                        style="width:auto!important; float:left; margin-right: 10px!important"
                                        id="checkList_{{ item.id }}"
                                        type="checkbox"
                                        formControlName="checkList_{{
                                            item.id
                                        }}"
                                        (change)="updateCheckList(item.id)"
                                    />
                                </label>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <h6>{{ 'msg.notes' | translate }}</h6>
                            </div>
                            <div class="col">
                                <label for="diagnosticNotes">{{
                                    'msg.notes.diagnostic' | translate
                                }}</label>
                                <textarea
                                    class="form-control"
                                    formControlName="diagnosticNotes"
                                ></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <label for="internalNotes">{{
                                    'msg.notes.internal' | translate
                                }}</label>
                                <textarea
                                    class="form-control"
                                    formControlName="internalNotes"
                                ></textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <button
                                    [disabled]="!validate() || disableAddBtn"
                                    type="button"
                                    (click)="addOrder()"
                                    class="btn btn-primary float-right ml-1"
                                >
                                    {{ 'msg.add.repair.order' | translate }}
                                </button>
                                <a
                                    [routerLink]="['../']"
                                    class="btn btn-secondary float-right ml-1"
                                >
                                    {{ 'msg.cancel' | translate }}
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
