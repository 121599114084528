import { formatDate } from '@angular/common';
import { Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreditSearchRequest } from '@gtool.shared/models/CreditSearchRequest';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ReportsServiceService } from '@gtool.shared/services/reports-service.service';
import { NgbDateParserFormatter, NgbModal, NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
} from 'rxjs/operators';

@Component({
    selector: 'app-credit-report',
    templateUrl: './credit-report.component.html',
    styleUrls: ['./credit-report.component.css'],
})
export class CreditReportComponent implements OnInit {
    creditReportForm: FormGroup;

    private childrenOrgs: { id: number; name: string }[] = [];
    private organisationDetails: OrganisationDetails;

    /* TYPE AHEAD START */
    search = (text$: Observable<string>) => {
        return merge(
            text$.pipe(debounceTime(200), distinctUntilChanged())
        ).pipe(
            map((term) =>
                (term === ''
                    ? this.childrenOrgs
                    : this.childrenOrgs.filter(
                          (v) =>
                              v.name.toLowerCase().indexOf(term.toLowerCase()) >
                              -1
                      )
                ).slice(0, 10)
            )
        );
    };
    formatter = (x: { name: string }) => x.name;
    /* TYPE AHEAD END */

    constructor(
        private df: NgbDateParserFormatter,
        private authService: AuthenticationService,
        private orgService: OrganisationServiceService,
        private reportService: ReportsServiceService,
        config: NgbTypeaheadConfig
    ) {
        config.placement = 'bottom';
        config.showHint = true;
        config.focusFirst = false;
    }

    ngOnInit() {
        // get my org
        this.organisationDetails = this.authService.getCurrentOrganisation();
        // get children orgs
        this.orgService
            .getOrganisationList(this.organisationDetails.id)
            .subscribe((resp) => {
                // push myself
                this.childrenOrgs.push({
                    id: this.organisationDetails.id,
                    name: this.organisationDetails.name,
                });
                resp.forEach((org) => {
                    this.childrenOrgs.push({ id: org.id, name: org.name });
                });
            });
        // prepare the form
        let date = new Date();
        let firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'dd/MM/yyyy', 'en');
        let lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'dd/MM/yyyy', 'en');

        this.generateDetailsForm(this.organisationDetails, firstDay, lastDay);
        this.notifyHistory(this.organisationDetails, firstDay, lastDay);
        // notify all components that we have a new search request
    }

    /**
     * prepare the form
     */
    private generateDetailsForm(organisation: OrganisationDetails, firstDay: string, lastDay: string): void {
        // initialise
        this.creditReportForm = new FormGroup({
            submit: new FormControl(),
            organisationId: new FormControl(Validators.required),
            dateFrom: new FormControl(Validators.required),
            dateTo: new FormControl(Validators.required),
        });
        // populate
        this.creditReportForm.controls['dateFrom'].setValue(this.df.parse(firstDay));
        this.creditReportForm.controls['dateTo'].setValue(this.df.parse(lastDay));
        this.creditReportForm.controls['organisationId'].setValue(organisation);
    }

    private notifyHistory(organisation: OrganisationDetails, firstDay: string, lastDay: string): CreditSearchRequest {
      let crRequest: CreditSearchRequest = new CreditSearchRequest();
      crRequest.dateFrom = firstDay;
      crRequest.dateTo = lastDay;
      crRequest.organisationId = organisation.id;

      this.orgService.creditSearchChange.next(crRequest);

      return crRequest;
    }

    onSubmit(action: string) {

      let organisation: OrganisationDetails = this.creditReportForm.controls['organisationId'].value;
      let firstDay: string = this.df.format(this.creditReportForm.controls['dateFrom'].value);
      let lastDay: string = this.df.format(this.creditReportForm.controls['dateTo'].value);
      // notify the search to redo the search and notify the history component
      let creditReportRequest: CreditSearchRequest = this.notifyHistory(organisation, firstDay, lastDay);

        // if export button, get the XLS download
        if( action === 'DOWNLOAD' ) {
            this.reportService.downloadCreditReport(creditReportRequest).subscribe( response => {
              window.open( window.URL.createObjectURL( response.body) );
            });
        }
    }
}
