import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@gtool.shared/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SafePipe } from './pipes/safe.pipe';
import { ServiceMonitorComponent } from './service-monitor/service-monitor.component';

@NgModule({
    declarations: [FooterComponent, SidebarComponent, NavbarComponent, ServiceMonitorComponent, SafePipe],
    imports: [NgbModule, RouterModule, CommonModule, I18nModule],
    exports: [NgbModule, FooterComponent, SidebarComponent, NavbarComponent, ServiceMonitorComponent, SafePipe],
})
export class SharedModule {}
