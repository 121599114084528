import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrganisationChildValidator } from '@gtool.shared/custom-validation/form-control-validators';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { ModalDismissReasons, NgbModal, NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-machine-transfer',
    templateUrl: './machine-transfer.component.html',
    styleUrls: ['./machine-transfer.component.css'],
    providers: [NgbTypeaheadConfig],
})
export class MachineTransferComponent implements OnInit {
    public transferMachineForm: FormGroup;
    public childrenOrgs: { id: number; name: string }[] = [];
    public machines: { id: number; name: string }[] = [];
    public organisationDetails: OrganisationDetails;
    private _closeResult: string;

    @ViewChild('#transferTo') ngbTypeAheadOrg: NgbTypeahead;
    orgTypeAheadFocus$ = new Subject<string>();
    orgTypeAheadClick$ = new Subject<string>();

    orgTypeAheadSearch = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.orgTypeAheadClick$.pipe(
            filter(() => !this.ngbTypeAheadOrg)
        );
        const inputFocus$ = this.orgTypeAheadFocus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map((term) =>
                (term === ''
                    ? this.childrenOrgs
                    : this.childrenOrgs.filter(
                          (v) =>
                              v.name.toLowerCase().indexOf(term.toLowerCase()) >
                              -1
                      )
                ).slice(0, 10)
            )
        );
    };
    orgTypeAheadFormatter = (x: { name: string }) => x.name;


    @ViewChild('#machines') ngbTypeAheadMachine: NgbTypeahead;
    machineTypeAheadFocus$ = new Subject<string>();
    machineTypeAheadClick$ = new Subject<string>();
    machineTypeAheadSearch = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(
          debounceTime(200),
          distinctUntilChanged()
      );
      const clicksWithClosedPopup$ = this.machineTypeAheadClick$.pipe(
          filter(() => !this.ngbTypeAheadMachine)
      );
      const inputFocus$ = this.machineTypeAheadFocus$;
      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
          map((term) =>
              (term === ''
                  ? this.machines
                  : this.machines.filter(
                        (v) =>
                            v.name.toLowerCase().indexOf(term.toLowerCase()) >
                            -1
                    )
              ).slice(0, 10)
          )
      );
  };

  machineTypeAheadFormatter = (x: { name: string }) => x.name;

    constructor(
        private toastr: MyToasterService,
        private modalService: NgbModal,
        private router: Router,
        private authService: AuthenticationService,
        private orgService: OrganisationServiceService,
        private machineService: MachineService
    ) {}

    ngOnInit(): void {
        this.organisationDetails = this.authService.getCurrentOrganisation();
        this.orgService
            .getOrganisationList(this.organisationDetails.id)
            .subscribe((resp) => {
                resp.filter( org => org.resourceType === 'resource.type.repair.point' ).forEach((org) => {
                    this.childrenOrgs.push({ id: org.id, name: org.name });
                });
            });

        this.machineService.getMachines().subscribe(resp => {
          resp.filter( m => m.operationalStatus !== 'BUSY' ).forEach(m => { this.machines.push({ id: m.id, name: m.serialNo }); });
        });
    }

    onSubmit() {
        this.machineService
            .transferMachine(this.transferMachineForm.get('machines').value.id, this.transferMachineForm.get('transferTo').value.id)
            .subscribe((result) => {
                this.modalService.dismissAll();
                this.toastr.success('msg.machine.transfered');
            });
    }

    private initTransferMachineForm(): void {
        this.transferMachineForm = new FormGroup({
            machines: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                ])
            ),
            transferTo: new FormControl(
                '',
                Validators.compose([
                    OrganisationChildValidator(this.childrenOrgs),
                    Validators.required,
                ])
            ),
        });
    }
    open(content) {
        this.initTransferMachineForm();
        this.modalService.open(content).result.then(
            (result) => {
                this._closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this._closeResult = `Dismissed ${this.getDismissReason(
                    reason
                )}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
