import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { ReportRepairComponent } from './report-repair/report-repair.component';
import { ReportRevenueComponent } from './report-revenue/report-revenue.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ReportsListComponent,
        canActivate: [AuthGuard],
        data: { title: 'msg.reports' },
    },
    {
      path: 'revenue',
      component: ReportRevenueComponent,
      canActivate: [AuthGuard],
      data: { title: 'msg.revenue.report' },
    },
    {
      path: 'repairs',
      component: ReportRepairComponent,
      canActivate: [AuthGuard],
      data: { title: 'msg.reports.report' },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReportsRoutingModule {}
