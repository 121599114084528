<div class="main-content">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Serial No</th>
                    <th scope="col">Type</th>
                    <th scope="col">Connection Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let machine of machines">
                    <td>
                        <a
                        [routerLink]="[machine.id]">
                        {{machine?.serialNo}}
                    </a>
                    </td>
                    <td>{{machine?.machineType.name}}</td>
                    <td>
                      <span
                          *ngIf="machine?.connected"
                          class="badge badge-success"
                          >Connected</span
                      >
                      <span
                          *ngIf="!machine?.connected"
                          class="badge badge-danger"
                          >Disconnected</span
                      >

                      <ng-container
                          *ngIf="machine?.connected"
                      >
                          &nbsp;
                          <span
                          *ngIf="machine?.snapshot?.status?.operation"
                              class="badge"
                              [ngClass]="{'READY':'badge-success','ERROR':'badge-error','UPGRADING':'badge-warning', 'BOOTING': 'badge-warning', 'NONE': 'badge-normal'} [machine?.snapshot?.status?.operation]"


                              > {{ 'machine.operational.status.' + machine?.snapshot?.status?.operation.toLowerCase() | translate }}</span
                          >

                          &nbsp;
                          <span
                              *ngIf="machine?.snapshot?.status?.lid"
                              class="badge"
                              [ngClass]="{'CLOSED':'badge-success','OPEN':'badge-warning'} [machine?.snapshot?.status?.lid]"


                              > {{ 'machine.lid.status.' + machine?.snapshot?.status?.lid.toLowerCase() | translate }}</span
                          >
                      </ng-container>
                  </td>

                  <td>
                    <a
                        *ngIf=" allowUpgrade && machine?.connected &&
                            ( machine?.operationalStatus ==
                            'READY' || ( machine?.operationalStatus == 'BOOTING' && machine?.machineType?.name == 'FREEZETECH' ))
                            && machine?.needsUpgrade
                        "
                        (click)="upgrade(machine?.id)"
                        class="btn btn-success pull-right"
                        tabindex="1"
                    >
                        <i
                            class="fa fa-fw fa-keyboard"
                        ></i>
                        Upgrade
                    </a>
                </td>

                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
