<button
    type="button"
    class="btn btn-primary pull-right"
    (click)="open(editRevenue)"
>
    {{ 'msg.edit' | translate }}
</button>

<ng-template #editRevenue let-modal>
    <form [formGroup]="revenueForm" *ngIf="showForm">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ 'msg.edit' | translate }} {{ 'msg.rate' | translate }}
            </h5>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-container *ngFor="let productRate of productRates">
            <div class="form-group">
                
                    <div class="row">
                        <div class="col">
                            <label for="productRevenueType_{{ productRate.productType.id }}"
                                >{{ productRate.productType?.name }}:</label
                            >
                            <input
                                type="text"
                                formControlName="productRevenueType_{{
                                  productRate.productType.id
                                }}"
                                placeholder="{{
                                    'msg.enter.number.of.credits' | translate
                                }}"
                                name="productRevenueType_{{ productRate.productType.id }}"
                                class="form-control col-2"
                                id="productRevenueType_{{ productRate.productType.id }}"
                            />
                        </div>
                    </div>
                
            </div>
          </ng-container>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                (click)="modal.close('Close click')"
            >
                {{ 'msg.close' | translate }}
            </button>
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!revenueForm.valid"
                (click)="onSubmit()"
            >
                {{ 'msg.save' | translate }}
            </button>
        </div>
    </form>
</ng-template>
