import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { I18nService } from '@gtool.i18n/i18n.service';
import { RepairTask } from '@gtool.shared/models/RepairTask';
import { RepairTaskStep } from '@gtool.shared/models/RepairTaskStep';
import { RepairType } from '@gtool.shared/models/RepairType';
import { Subscription } from 'rxjs';

@Component({
    selector: 'i18n-popup',
    templateUrl: './translate-process-link.component.html',
    styleUrls: ['./translate-process-link.component.css'],
})
export class TranslateProcessLinkComponent implements OnInit, OnDestroy {

    private subscription: Subscription;

    @Input() step: RepairTaskStep;
    @Input() task: RepairTask;
    @Input() taskId: number;
    @Input() target: string;
    @Input() repairType: RepairType;
    @Input() repairTypeId: number;
    public show = false;

    constructor(private i18nService: I18nService) {}

    ngOnInit(): void {
      this.show = this.i18nService.getShowI18n();
      this.subscription = this.i18nService
          .translationPopup()
          .subscribe((e) => {
              if (e && e.event==='toggle') {
                  this.show = e.show;
              }
          });
  }
  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

    public translateProcess($event) {
      $event.preventDefault();
        this.i18nService
            .translationPopup()
            .next({ 'event': 'popup', 'repairTypeId': this.repairTypeId, 'repairType': this.repairType, 'task': this.task, 'step': this.step, 'target': this.target });
    }
}
