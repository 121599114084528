import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '@gtool/login/login.component';
import { ControlPanelComponent } from '@gtool/control-panel/control-panel.component';
import { OrganisationViewComponent } from '@gtool.organisation/organisation-view/organisation-view.component';
import { OrganisationsResolver } from '@gtool.resolvers/organisations.resolver';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { GlobalResolver } from '@gtool.resolvers/global.resolver';

const routes: Routes = [
    { path: '', redirectTo: 'cp/', pathMatch: 'full'},
    { path: 'login', component: LoginComponent, data: {title: 'msg.sign.in'},
        resolve: { global: GlobalResolver } },
    { path: 'forgot',
        loadChildren: '@gtool/forgot-password/forgot-password.module#ForgotPasswordModule',
        resolve: { global: GlobalResolver}},

    { path: 'cp', component: ControlPanelComponent,
        resolve: { global: GlobalResolver },
        children: [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        { path: 'dashboard', loadChildren: '@gtool/dashboard/dashboard.module#DashboardModule',
          data: {title: 'msg.dashboard'} },
        // trick to distinguish active route from /organisations path
        { path: ':id/details', component: OrganisationViewComponent,
          canActivate: [AuthGuard], resolve: { details: OrganisationsResolver },
          data: {title: 'msg.my.details'}
        },
        { path: 'organisations', loadChildren: '@gtool.organisation/organisation.module#OrganisationModule',
          data: {title: 'msg.sign.in'} },
        { path: 'repairs', loadChildren: '@gtool.repairorders/repair-orders.module#RepairOrdersModule',
          data: {title: 'msg.repairs'} },
        { path: 'config', loadChildren: '@gtool.config/config.module#ConfigModule',
          data: {title: 'msg.repairs'} },
        { path: 'credits', loadChildren: '@gtool/credits/credits.module#CreditsModule',
          data: {title: 'msg.credits'} },
        { path: 'machines', loadChildren: '@gtool/machine/machine.module#MachineModule',
          data: {title: 'msg.machines'} },
        { path: 'products', loadChildren: '@gtool/product/product.module#ProductModule',
          data: {title: 'msg.products'} },
        { path: 'reports', loadChildren: '@gtool/reports/reports.module#ReportsModule',
          data: {title: 'msg.reports'} },
        { path: 'repair-preview', loadChildren: '@gtool/repair-preview/repair-preview.module#RepairPreviewModule',
        data: {title: 'msg.repair.preview'} },
    ]},
    { path: '**', redirectTo: 'cp'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'corrected'})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
