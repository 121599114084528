import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationDetails, RevenueReportRequest, RevenenueReport } from '@gtool.shared/models/models';
import { ReportsServiceService } from '@gtool.shared/services/reports-service.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-revenue',
  templateUrl: './report-revenue.component.html',
  styleUrls: ['./report-revenue.component.css']
})
export class ReportRevenueComponent implements OnInit {

  reportForm: FormGroup;
  organisations: OrganisationDetails[];
  public result: RevenenueReport;

  constructor(
    private df: NgbDateParserFormatter,
    private authService: AuthenticationService,
    private orgService: OrganisationServiceService,
    private reportService: ReportsServiceService
  ) { }

  ngOnInit() {
    this.initForm();
    const myId = this.authService.getCurrentOrganisation().id;
    this.orgService.getOrganisationList( myId, true ).subscribe( r => {
      this.organisations = r;
    });
  }

  onSubmit(type: string) {
    const revenueReportRequest = new RevenueReportRequest();
    revenueReportRequest.action = type;
    revenueReportRequest.organisationId = this.reportForm.controls['organisationId'].value;
    revenueReportRequest.dateFrom = this.df.format(this.reportForm.controls['dateFrom'].value);
    revenueReportRequest.dateTo = this.df.format(this.reportForm.controls['dateTo'].value);

    if ( type === 'DOWNLOAD' ) {
      this.reportService.downloadRevenueReport(revenueReportRequest).subscribe( response => {
        window.open( window.URL.createObjectURL( response.body) );
      });
    } else if ( type === 'SEARCH' ) {
      this.reportService.getRevenueReport(revenueReportRequest).subscribe( r => {
        this.result = r;
      });
    }
  }

  private initForm(): void {
    this.reportForm = new FormGroup({
        organisationId: new FormControl('', Validators.required),
        dateFrom: new FormControl('', Validators.required),
        dateTo: new FormControl('', Validators.required),
    });
  }

}
