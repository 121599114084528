import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@gtool.shared/shared.module';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { CreditsRoutingModule } from './credits-routing.module';

import { CreditsComponent } from './credits.component';
import { CreditHistoryComponent } from './credit-history/credit-history.component';
import { CreditTransferComponent } from './credit-transfer/credit-transfer.component';
import { CreditOrderComponent } from './credit-order/credit-order.component';
import { CreditReportComponent } from './credit-report/credit-report.component'

@NgModule({
  declarations: [CreditsComponent, CreditHistoryComponent, CreditTransferComponent, CreditOrderComponent, CreditReportComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    I18nModule,
    CreditsRoutingModule
  ],
  exports: [CreditsComponent]
})
export class CreditsModule { }
