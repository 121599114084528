import { OrganisationDetails, Group, Customer } from './models';
import { OperatorDetails } from './OperatorDetails';
import { PhoneModel } from './PhoneModel';
import { RepairOrder } from './RepairOrder';
import { PhoneRepairType } from './PhoneRepairType';
import { RepairTaskProgress } from './RepairTaskProgress';
import { StartFinishInfo } from './StartFinishInfo';

export class Repair {
    id?: number;
    updateInfo?: string;
    repairOrder?: RepairOrder;
    repairOrderId?: number;
    phoneRepairType?: PhoneRepairType;
    phoneRepairTypeId?: number;
    repairTasks?: RepairTaskProgress[];
    currentRepairTask?: number;
    hasTasks?: boolean;
    status?: string;
    operatorId: number;
    operator: OperatorDetails;
    notes: string;
    startFinishInfo?: StartFinishInfo;
}
