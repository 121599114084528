import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MachineType } from '@gtool.shared/models/models';
import { MachineFirmware } from '@gtool.shared/models/MachineFirmware';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { combineLatest } from 'rxjs';
import { MachineFirmwareRequest } from '@gtool.shared/models/MachineFirmwareRequest';
import { UtilService } from '@gtool.shared/services/util.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
    selector: 'app-firmware-form',
    templateUrl: './firmware-form.component.html',
    styleUrls: ['./firmware-form.component.css'],
})
export class FirmwareFormComponent implements OnInit {
    public mode: string;
    public machineFirmwareForm: FormGroup;
    public machineTypes: MachineType[];
    private firmwareId: any;
    private firmware: MachineFirmware = new MachineFirmware();
    selectedFiles: Array<FileList> = new Array<FileList>();

    constructor(
        private toastr: MyToasterService,
        private router: Router,
        private route: ActivatedRoute,
        private machineService: MachineService
    ) {}

    ngOnInit() {
        this.mode = 'CREATE';
        this.initializeForm();

        combineLatest(
            this.machineService.getMachineTypes(),
            this.route.paramMap
        ).subscribe(([machineTypes, params]) => {
            this.machineTypes = machineTypes;
            this.firmwareId = params.get('id');
            if (this.firmwareId !== 'new') {
                this.machineService
                    .getMachine(this.firmwareId)
                    .subscribe(firmware => {
                        this.mode = 'MODIFY';
                        this.machineFirmwareForm.patchValue(firmware);
                        this.firmware = firmware;
                    });
            }
        });
    }

    public onSubmit() {
        const data = new FormData();
        data.append('firmware', this.selectedFiles['firmwareFile'].item(0));
        data.append('firmware', this.selectedFiles['lcdFile'].item(0));
        data.append('firmware', this.selectedFiles['infoFile'].item(0));

        const machineFirmwareRequest: MachineFirmwareRequest = new MachineFirmwareRequest();
        machineFirmwareRequest.firmware = new MachineFirmware();
        machineFirmwareRequest.action = this.mode;
        machineFirmwareRequest.firmware.id = this.machineFirmwareForm.controls[
            'id'
        ].value;
        machineFirmwareRequest.firmware.machineTypeId = this.machineFirmwareForm.controls[
            'machineTypeId'
        ].value;
        machineFirmwareRequest.firmware.firmwareVersion = this.machineFirmwareForm.controls[
            'firmwareVersion'
        ].value;
        machineFirmwareRequest.firmware.productVersion = this.machineFirmwareForm.controls[
            'productVersion'
        ].value;

        data.append('payload', JSON.stringify(machineFirmwareRequest));

        this.machineService.createNewMachineFirmware(data).subscribe(result => {
            this.toastr.success('msg.machine.firmware.added');
            this.router.navigate(['../../'], { relativeTo: this.route });
        });
    }

    private initializeForm() {
        this.machineFirmwareForm = new FormGroup({
            id: new FormControl(''),
            machineTypeId: new FormControl('', [Validators.required]),
            firmwareVersion: new FormControl('', [Validators.required, Validators.pattern('[\\d]*')]),
            productVersion: new FormControl('', [Validators.required, Validators.pattern('[\\d]*')]),
        });
    }

    onFileChange(inputName, event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            this.selectedFiles[inputName] = event.target.files;
        }
    }
}
