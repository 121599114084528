<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <!--<a class="navbar-brand" href="#">{{getTitle()}}</a>-->
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">

      <ul class="navbar-nav">


        <li class="nav-item">
          <a class="nav-link" [href]="helpCenterUrl">
            <i class="now-ui-icons  objects_umbrella-13"></i>
            <p>
              <span class="d-block">{{'msg.report.issue' | translate}}</span>
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="eshopUrl">
            <i class="now-ui-icons shopping_cart-simple"></i>
            <p>
              <span class="d-block">{{'msg.products.solutions' | translate}}</span>
            </p>
          </a>
        </li>

        <app-service-monitor [display]="true"></app-service-monitor>

        <li class="nav-item" ngbDropdown>
          <a class="nav-link" id="dropdownBasic2" ngbDropdownToggle>
            <i class="now-ui-icons business_globe"></i>
            <p>
              <span class="d-block">{{currentLanguage?.nativeName}}</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a *ngFor="let lang of availableLanguages" class="dropdown-item" (click)="changeLanguage(lang.iso)">{{lang.nativeName}}</a>

            <i18n-toggle *ngIf="currentOrganisation?.allowI18n"></i18n-toggle>
          </div>
        </li>

        <li class="nav-item" ngbDropdown>
          <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
            <i class="now-ui-icons users_single-02"></i>
            <p>
              <span class="d-block">{{'msg.my.account' | translate}}</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">

            <div class="dropdown-item text-wrap">
              <div class="row">
                <p class="text-primary"> {{ 'msg.name' | translate }}:&nbsp;</p>
                <p class="text-muted">{{ currentOrganisation?.name }}</p>
              </div>
              <div class="row">
                <p class="text-primary">{{ 'msg.gcredit.id' | translate }}:&nbsp;</p>
                <p class="text-muted">{{ currentOrganisation?.gCreditId }}</p>
              </div>
            </div>

            <div class="dropdown-divider"></div>
            <a title="{{ 'msg.logout' | translate }}" class="dropdown-item" (click)="logout()"><i
                class="fas fa-sign-out-alt"></i>&nbsp;
              {{ 'msg.logout' | translate }}
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
