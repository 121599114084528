import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AuthResponse, OrganisationDetails, PasswordRequest } from '@gtool.shared/models/models';
import { HttpParams, HttpClient } from '@angular/common/http';
import { UtilService } from '@gtool.shared/services/util.service';
import { map, flatMap } from 'rxjs/operators';
import { OrganisationServiceService } from './organisation-service.service';
import { Configuration } from '@gtool.config/configuration';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    static readonly URL_GET_TOKEN = '/oauth/token';
    static readonly URL_POST_PASSWD_REQUEST = '/api/password/forgot';
    static readonly URL_GET_PASSWD_RESET = '/api/password/reset?token=:token';
    static readonly URL_POST_PASSWD_RESET = '/api/password/reset';

    private loggedIn: boolean;
    private currentOrganisation: OrganisationDetails;
    private _change = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private organisationService: OrganisationServiceService
    ) {
        this.loggedIn = false;
    }

    async silentLogin(): Promise<boolean> {
        // if there is a token and logged in is not TRUE, => to fetch current account (ignore token renewals)
        if ( !this.isLoggedIn() && this.getToken() !== null ) {
            try {
                const response = await this.organisationService.getCurrentOrganisation().toPromise();
                this.loggedIn = true;
                this.currentOrganisation = response;
                this._change.next(this.loggedIn);
            } catch {
                this.logout();
            }
        }
        return this.loggedIn;
    }

    public updateCurrentOrganisation(): Promise<OrganisationDetails> {
        return this.organisationService
            .getCurrentOrganisation().pipe( map(response => {
                this.currentOrganisation = response;
                return response;
            }))
            .toPromise();
    }

    public isRoot( org: OrganisationDetails): boolean {
        return org.distributorType != null &&
            Configuration.DTYPE_GTOOL === org.distributorType.name;
    }
    public isDistributor( org: OrganisationDetails): boolean {
        return org.distributorType != null &&
            Configuration.DISTRIBUTOR === org.resourceType;
    }

    public isRepairPoint( org: OrganisationDetails): boolean {
      return Configuration.REPAIR_POINT === org.resourceType;
  }

    public isLoggedIn(): boolean {
        return this.loggedIn;
    }

    public authorize(username, password, grant_type): Observable<any> {
        // first clear the sessionStorage
        sessionStorage.clear();
        const params = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('grant_type', grant_type);

        return this.http
            .post<AuthResponse>(
                UtilService.getUrl(AuthenticationService.URL_GET_TOKEN),
                params
            )
            .pipe(
                map(res => {
                    this.saveToken(res);
                    this.loggedIn = true;
                }),
                flatMap(() => {
                    // by using the flatmap we succeed to wait and the 2nd api call
                    // and then return a response to its father
                    return this.organisationService
                        .getCurrentOrganisation()
                        .pipe(
                            map(response => {
                                this.currentOrganisation = response;
                                this._change.next(this.loggedIn);
                            })
                        );
                })
            );
    }

    public getToken(): string {
        return localStorage.getItem('access_token');
    }

    get change(): BehaviorSubject<boolean> {
        return this._change;
    }

    private saveToken(response: AuthResponse): void {
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('refresh_token', response.refresh_token);
    }

    public logout(): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        this.loggedIn = false;
        this.currentOrganisation = null;
        this._change.next(this.loggedIn);
    }

    private gc(): void {
        this.organisationService.getCurrentOrganisation().subscribe();
    }

    public getCurrentOrganisation(): OrganisationDetails {
        return this.currentOrganisation;
    }

    public requestPasswordReset(data: PasswordRequest): Observable<void> {
      return this.http
        .post<void>( UtilService.getUrl(AuthenticationService.URL_POST_PASSWD_REQUEST), data);
    }

    public passwordReset(data: PasswordRequest): Observable<void> {
      return this.http
        .post<void>( UtilService.getUrl(AuthenticationService.URL_POST_PASSWD_RESET), data);
    }

    public validatePasswordReset(token: string): Observable<void> {
      return this.http.get<void>(
          UtilService.getUrl(AuthenticationService.URL_GET_PASSWD_RESET, [{ k: ':token', v: token }])
      );
    }
}
