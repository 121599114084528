import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CartRequest, CartItem } from '@gtool.shared/models/models';
import { ProductService } from '@gtool.shared/services/product.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { environment } from '@env/environment';

@Component({
    selector: 'app-credit-order',
    templateUrl: './credit-order.component.html',
    styleUrls: ['./credit-order.component.css'],
})
export class CreditOrderComponent implements OnInit {
    private _closeResult: string;
    public buyCreditsForm: FormGroup;

    constructor(
      private modalService: NgbModal,
      private productService: ProductService,
      private authenticationService: AuthenticationService) {}

    ngOnInit() {}

    private initBuyCreditsForm(): void {
        this.buyCreditsForm = new FormGroup({
            credits: new FormControl('', [
                Validators.required,
                Validators.min(1),
            ]),
        });
    }

    open(content) {
        this.initBuyCreditsForm();
        this.modalService.open(content).result.then(
            result => {
                this._closeResult = `Closed with: ${result}`;
            },
            reason => {
                this._closeResult = `Dismissed ${this.getDismissReason(
                    reason
                )}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onSubmit() {
      const cItem: CartItem = new CartItem();
      cItem.sku = 'GCREDIT';
      cItem.qty = this.buyCreditsForm.get('credits').value;

      const cartRequest: CartRequest = new CartRequest();
      cartRequest.action = 'ADD';
      cartRequest.cartItems = new Array<CartItem>();
      cartRequest.cartItems.push(cItem);

      this.productService.addToCart(cartRequest).subscribe( result => {
        this.modalService.dismissAll();
        let redirectUrl =  environment.magento_url + '/gtool/account/autoLogin?client_redirect=checkout/cart&client_token=';
        redirectUrl += this.authenticationService.getToken();
        window.open(redirectUrl, '_blank');
      });
    }
}
