import { Component, OnInit } from '@angular/core';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { Router } from '@angular/router';
import { Machine } from '@gtool.shared/models/Machine';
import { environment } from '@env/environment';

@Component({
  selector: 'app-my-machines',
  templateUrl: './my-machines.component.html',
  styleUrls: ['./my-machines.component.css']
})
export class MyMachinesComponent implements OnInit {

  public machines = new Array<Machine>();
  public allowUpgrade:boolean = environment.allow_client_upgrade;

  constructor(
    private router: Router,
    private machineService: MachineService,
  ) { }

  ngOnInit() {
    this.machineService
    .getMyMachines()
    .subscribe(result => {
        this.machines = result;
    });
  }

  upgrade( machineId: number): void {
    this.machineService.upgradeMachine(machineId).toPromise().then( r => {
    // something
    }).finally( () => {
        this.refresh();
    });
  }

  refresh(): void {
    this.machineService
    .getMyMachines()
    .subscribe(result => {
        this.machines = result;
    });
}

}
