import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { OrganisationDetails } from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
    selector: 'app-organisations',
    templateUrl: './organisations.component.html',
    styleUrls: ['./organisations.component.css'],
})
export class OrganisationsComponent implements OnInit {
    public organisationList = new Array<OrganisationDetails>();

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.organisationList = this.route.snapshot.data.list[1];
    }
}
