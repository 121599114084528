import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { MachinesComponent } from '@gtool/machine/machines/machines.component';
import { MyMachinesComponent } from './my-machines/my-machines.component';
import { MyMachineComponent } from './my-machine/my-machine.component';
import { MachineFormComponent } from './machine-form/machine-form.component';
import { FirmwareComponent } from './firmware/firmware.component';
import { FirmwareFormComponent } from './firmware-form/firmware-form.component';
import { MachineComponent } from './machine/machine.component';
import { ScanImageComponent } from './scan-image/scan-image.component';
import { MachinesListResolver } from '@gtool.resolvers/machines-list';

const routes: Routes = [
  {
    path: '',  pathMatch: 'full',
    component: MachinesComponent,
    canActivate: [AuthGuard],
    resolve: { list: MachinesListResolver },
  },
  {
    path: 'all/:id',
    component: MachineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'all/:id/scan-image',
    component: ScanImageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'firmware',
    component: FirmwareComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'firmware/:id/form',
    component: FirmwareFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':id/form',
    component: MachineFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my',
    component: MyMachinesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my/:id',
    component: MyMachineComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MachineRoutingModule { }
