import { OrganisationRequestType } from './OrganisationRequestType';
import { OrganisationDetails } from './OrganisationDetails';
import { QuerySpecification } from './QuerySpecification';

export class OrganisationRequest {
    action?: OrganisationRequestType;
    distributor?: OrganisationDetails;
    repairPoint?: OrganisationDetails;
    q?: QuerySpecification;
}
