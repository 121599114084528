import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhoneTypesListResolver } from '@gtool.resolvers/phoneTypes-list';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { PreviewComponent } from './preview/preview.component';

const routes: Routes = [
  {
      path: '',
      pathMatch: 'full',
      component: PreviewComponent,
      canActivate: [AuthGuard],
      resolve: {
        phoneTypes: PhoneTypesListResolver
      },
      data: { title: 'msg.repair.preview' },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RepairPreviewRoutingModule { }
