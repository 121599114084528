<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <app-machine-transfer></app-machine-transfer>

                            <a
                                [routerLink]="['firmware']"
                                class="btn btn-primary pull-right"
                            >
                                <i class="fa fa-fw fa-keyboard"></i>
                                {{ 'msg.firmware' | translate }}
                            </a>

                            <a
                                [routerLink]="['new', 'form']"
                                class="btn btn-primary pull-right"
                            >
                                <i class="fa fa-fw fa-plus-circle"></i>
                                {{ 'msg.add' | translate }}
                            </a>

                            <a
                                (click)="exportCsv()"
                                tabindex="1"
                                class="btn btn-success pull-right"
                            >
                                <i class="fa fa-fw fa-file-excel"></i>
                                Export CSV
                            </a>

                            <a
                                (click)="refresh()"
                                tabindex="1"
                                class="btn btn-warning pull-right"
                            >
                                <i class="fa fa-sync"></i>
                                Refresh
                            </a>
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="row">
                        <div
                            class="col d-flex justify-content-start pagination-bar"
                        >
                            <span
                                >{{ 'msg.query.results' | translate }}:
                                {{ totalResultsWithSpecs }}</span
                            >
                        </div>

                        <div class="col d-flex justify-content-end">
                            <fieldset class="form-inline">
                                <div class="form-group">
                                    <label
                                        style="margin-right: 0.5rem"
                                        for="page-size"
                                        >{{ 'msg.page.size' | translate }}:
                                    </label>
                                    <div
                                        class="btn-group"
                                        ngbDropdown
                                        role="group"
                                        aria-label="Page size"
                                    >
                                        <button
                                            id="page-size"
                                            class="btn btn-outline-primary"
                                            ngbDropdownToggle
                                        >
                                            {{ currentPageSize }}
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            ngbDropdownMenu
                                        >
                                            <div *ngFor="let ps of pageSizes">
                                                <button
                                                    (click)="changePageSize(ps)"
                                                    ngbDropdownItem
                                                >
                                                    {{ ps }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div
                            class="col-auto d-flex justify-content-end pagination-bar"
                        >
                            <ngb-pagination
                                (pageChange)="pageChanged($event)"
                                [collectionSize]="totalResultsWithSpecs"
                                [pageSize]="currentPageSize"
                                [(page)]="currentPage"
                                [maxSize]="3"
                            >
                            </ngb-pagination>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                        	<!-- SEARCH BY Serial No -->
                                            <div
                                                *ngIf="showSerialNoSearch"
                                                class="input-icons"
                                            >
                                                <i
                                                    (click)="closeSearchInput('serialNo')"
                                                    class="now-ui-icons ui-1_simple-remove icon"
                                                ></i>
                                                <input
                                                    #serialNo
                                                    placeholder="Serial No"
                                                    name="serialNo"
                                                    class="input-field form-control"
                                                    style="width: auto !important;"
                                                    id="serialNo"
                                                    (keyup)="searchBySerialNo(serialNo.value)"
                                                />
                                            </div>
                                            <div *ngIf="!showSerialNoSearch">
                                                <i
                                                    style="cursor: pointer"
                                                    (click)="openSearchInput(0)"
                                                    class="now-ui-icons ui-1_zoom-bold"
                                                ></i>
                                                Serial No
                                            </div>
                                        </th>
                                        <th scope="col">Type
                                            <div
                                                class="btn-group"
                                                ngbDropdown
                                                role="group"
                                                aria-label="Sort by machine type"
                                            >
                                                <button
                                                    class="btn btn-outline-primary"
                                                    ngbDropdownToggle
                                                >
                                                    {{currentMachineTypeName}}
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    ngbDropdownMenu
                                                >
                                                    <div
                                                        *ngFor="let type of machineTypes"
                                                    >
                                                        <button
                                                            (click)="changeMachineType(type)"
                                                            ngbDropdownItem
                                                        >
                                                            {{type.name}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">Prod. Version</th>
                                        <th scope="col">Firm. Version</th>
                                        <th scope="col">Order Status
                                        	<div
                                                class="btn-group"
                                                ngbDropdown
                                                role="group"
                                                aria-label="Sort by order status"
                                            >
                                                <button
                                                    class="btn btn-outline-primary"
                                                    ngbDropdownToggle
                                                >
                                                    {{currentOrderStatus | translate}}
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    ngbDropdownMenu
                                                >
                                                    <div
                                                        *ngFor="let status of orderStatuses"
                                                    >
                                                        <button
                                                            (click)="changeOrderStatus(status)"
                                                            ngbDropdownItem
                                                        >
                                                            {{status | translate}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div></th>
                                        <th scope="col">Repair Point</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let machine of machinesResult">
                                        <td>                        <a
                                          [routerLink]="['all', machine.id]">
                                          {{machine?.serialNo}}
                                      </a></td>
                                        <td>{{ machine?.machineType.name }}</td>
                                        <td>{{ machine?.productVersion }}</td>
                                        <td>{{ machine?.firmwareVersion }}</td>
                                        <td>
                                            {{
                                                machine?.orderStatus | translate
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                machine?.repairPoint?.gCreditId
                                            }}
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="machine?.connected"
                                                class="badge badge-success"
                                                >Connected</span
                                            >
                                            <span
                                                *ngIf="!machine?.connected"
                                                class="badge badge-danger"
                                                >Disconnected</span
                                            >

                                            <ng-container
                                            *ngIf="machine?.connected"
                                        >
                                            &nbsp;
                                            <span
                                            *ngIf="machine?.snapshot?.status?.operation"
                                                class="badge"
                                                [ngClass]="{'READY':'badge-success','ERROR':'badge-error','UPGRADING':'badge-warning', 'BOOTING': 'badge-warning', 'NONE': 'badge-normal'} [machine?.snapshot?.status?.operation]"


                                                > {{ 'machine.operational.status.' + machine?.snapshot?.status?.operation.toLowerCase() | translate }}</span
                                            >

                                            &nbsp;
                                            <span
                                                *ngIf="machine?.snapshot?.status?.lid"
                                                class="badge"
                                                [ngClass]="{'CLOSED':'badge-success','OPEN':'badge-warning'} [machine?.snapshot?.status?.lid]"


                                                > {{ 'machine.lid.status.' + machine?.snapshot?.status?.lid.toLowerCase() | translate }}</span
                                            >
                                        </ng-container>
                                        </td>
                                        <td>
                                            <a
                                                *ngIf="machine?.connected &&
                                                    ( machine?.operationalStatus ==
                                                    'READY' ||
                                                    machine?.operationalStatus == 'BOOTING' ||
                                                    ( machine?.operationalStatus == 'BOOTING' && machine?.machineType?.name == 'FREEZETECH' ))
                                                    && machine?.needsUpgrade
                                                "
                                                (click)="upgrade(machine?.id)"
                                                class="btn btn-success pull-right"
                                                tabindex="1"
                                            >
                                                <i
                                                    class="fa fa-fw fa-keyboard"
                                                ></i>
                                                Upgrade
                                            </a>

                                            <a
                                                *ngIf="machine?.connected &&
                                                    machine?.operationalStatus ==
                                                    'BUSY'
                                                "
                                                (click)="reset(machine?.id)"
                                                class="btn btn-warning pull-right"
                                                tabindex="1"
                                            >
                                                <i
                                                    class="fa fa-fw fa-trash"
                                                ></i>
                                                Reset jobs
                                            </a>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
