/**
 * This decorator will check if the annotated method input is not empty. 
 * In case the input is not empty the method will execute properly.
 * If empty the method will be skipped
 */
export function IsNotEmpty(): MethodDecorator{
    return function(target: Object, key: string | symbol, descriptor: PropertyDescriptor) {
      const func = descriptor.value
      descriptor.value = function() {
        //method's input
        let inputValue:string = arguments[0];
        if(inputValue != null && inputValue != undefined && inputValue.length > 0){
            return func.apply(this, arguments);
        }else{
            return null;
        }
          
      }
    }
  }