import { Component, OnInit } from '@angular/core';
import { OrganisationModule } from '@gtool.organisation/organisation.module';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.css']
})
export class ControlPanelComponent implements OnInit {

  constructor(
    private authService: AuthenticationService
  ) {}


  ngOnInit() {
  }

}
