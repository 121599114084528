import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Operator } from 'rxjs';
import {
    OrganisationDetails,
    CustomerRequest,
    Customer,
    CustomerDetails,
    OrganisationRequest,
    OperatorDetails,
    ProductRate,
    MachineJob,
} from '@gtool.shared/models/models';
import { UtilService } from '@gtool.shared/services/util.service';
import { CreditHistory } from '@gtool.shared/models/CreditHistory';
import { CreditRequest } from '@gtool.shared/models/CreditRequest';
import { map } from 'rxjs/operators';
import { OperatorRequest } from '@gtool.shared/models/OperatorRequest';
import { CreditSearchRequest } from '@gtool.shared/models/CreditSearchRequest';

@Injectable({
    providedIn: 'root',
})
export class OrganisationServiceService {
    static readonly URL_GET_CURRENT = '/api/organisations/current';
    static readonly URL_POST_CREDIT_HISTORY =
        '/api/organisations/:id/credit/history';
    static readonly URL_GET_CREDIT_HISTORY_JOB =
        '/api/organisations/:id/credit/history/:hid/job/:muuid';

    static readonly URL_POST_TRANSFER_CREDIT =
        '/api/organisations/:id/credit/transfer';
    static readonly URL_GET_ORGANISATION = '/api/organisations/:id';
    static readonly URL_GET_LIST = '/api/organisations/:id/children';
    static readonly URL_GET_RATES = '/api/organisations/:id/rates';
    static readonly URL_GET_CUSTOMERS =
        '/api/organisations/:id/customers/query';
    static readonly URL_POST_CUSTOMER = '/api/organisations/:id/customers';
    static readonly URL_POST_ORGANISATION = '/api/organisations';
    static readonly URL_GET_OPERATORS =
        '/api/organisations/:id/operators/query';
    static readonly URL_POST_OPERATOR = '/api/organisations/:id/operators';

    static readonly URL_PUT_STATUS = '/api/organisations/:id/status';

    private _creditChange = new BehaviorSubject<boolean>(false);
    private _creditSearchChange = new BehaviorSubject<CreditSearchRequest>(
        null
    );
    private _rateChange = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient) {}

    get creditChange(): BehaviorSubject<boolean> {
        return this._creditChange;
    }
    get creditSearchChange(): BehaviorSubject<CreditSearchRequest> {
        return this._creditSearchChange;
    }
    get rateChange(): BehaviorSubject<boolean> {
        return this._rateChange;
    }

    getCurrentOrganisation(): Observable<OrganisationDetails> {
        return this.http.get<OrganisationDetails>(
            UtilService.getUrl(OrganisationServiceService.URL_GET_CURRENT)
        );
    }

    public getOrganisationList(
        id: number,
        self: boolean = false
    ): Observable<OrganisationDetails[]> {
        return this.http.get<OrganisationDetails[]>(
            UtilService.getUrl(OrganisationServiceService.URL_GET_LIST, [
                { k: ':id', v: id },
            ]),
            { params: { self: '' + self } }
        );
    }

    public getOrganisationDetails(id: number): Observable<OrganisationDetails> {
        return this.http.get<OrganisationDetails>(
            UtilService.getUrl(
                OrganisationServiceService.URL_GET_ORGANISATION,
                [{ k: ':id', v: id }]
            )
        );
    }

    public getCreditHistory(
        id: number,
        creditRequest: CreditRequest
    ): Observable<CreditHistory> {
        return this.http
            .post<CreditHistory>(
                UtilService.getUrl(
                    OrganisationServiceService.URL_POST_CREDIT_HISTORY,
                    [{ k: ':id', v: id }]
                ),
                creditRequest
            )
            .pipe(
                map((creditHistory) => {
                    return creditHistory;
                })
            );
    }

    public transferCredit(
        id: number,
        creditRequest: CreditRequest
    ): Observable<any> {
        return this.http.post<any>(
            UtilService.getUrl(
                OrganisationServiceService.URL_POST_TRANSFER_CREDIT,
                [{ k: ':id', v: id }]
            ),
            creditRequest
        );
    }

    public getCustomers(
        id: number,
        customerRequest: CustomerRequest
    ): Observable<Customer[]> {
        return this.http
            .post<Customer[]>(
                UtilService.getUrl(
                    OrganisationServiceService.URL_GET_CUSTOMERS,
                    [{ k: ':id', v: id }]
                ),
                customerRequest
            )
            .pipe(
                map((customers) => {
                    return customers;
                })
            );
    }

    public createNewCustomer(
        id: number,
        customerRequest: CustomerRequest
    ): Observable<CustomerDetails> {
        return this.http
            .post<CustomerDetails>(
                UtilService.getUrl(
                    OrganisationServiceService.URL_POST_CUSTOMER,
                    [{ k: ':id', v: id }]
                ),
                customerRequest
            )
            .pipe(
                map((customers) => {
                    return customers;
                })
            );
    }

    public createOrganisation(
        organisationRequest: OrganisationRequest
    ): Observable<OrganisationDetails> {
        return this.http.post<OrganisationDetails>(
            UtilService.getUrl(
                OrganisationServiceService.URL_POST_ORGANISATION
            ),
            organisationRequest
        );
    }

    public updateOrganisation(
        organisationRequest: OrganisationRequest
    ): Observable<OrganisationDetails> {
        return this.http.put<OrganisationDetails>(
            UtilService.getUrl(
                OrganisationServiceService.URL_POST_ORGANISATION
            ),
            organisationRequest
        );
    }

    public getOperators(
        id: number,
        operatorRequest: OperatorRequest
    ): Observable<OperatorDetails[]> {
        return this.http
            .post<OperatorDetails[]>(
                UtilService.getUrl(
                    OrganisationServiceService.URL_GET_OPERATORS,
                    [{ k: ':id', v: id }]
                ),
                operatorRequest
            )
            .pipe(
                map((operators) => {
                    return operators;
                })
            );
    }

    public createNewOperator(
        id: number,
        operatorRequest: OperatorRequest
    ): Observable<OperatorDetails> {
        return this.http
            .post<OperatorDetails>(
                UtilService.getUrl(
                    OrganisationServiceService.URL_POST_OPERATOR,
                    [{ k: ':id', v: id }]
                ),
                operatorRequest
            )
            .pipe(
                map((operator) => {
                    return operator;
                })
            );
    }

    public updateOrganisationRates(
        id: number,
        rates: ProductRate[]
    ): Observable<any> {
        return this.http.put<any>(
            UtilService.getUrl(OrganisationServiceService.URL_GET_RATES, [
                { k: ':id', v: id },
            ]),
            rates
        );
    }

    public getMachineJob(
        id: number,
        hid: number,
        muuid: string
    ): Observable<MachineJob> {
        return this.http.get<MachineJob>(
            UtilService.getUrl(
                OrganisationServiceService.URL_GET_CREDIT_HISTORY_JOB,
                [
                    { k: ':id', v: id },
                    { k: ':hid', v: hid },
                    { k: ':muuid', v: muuid },
                ]
            )
        );
    }

    public changeOrganisationStatus(
        id: number,
        status: string,
        children: boolean
    ): Observable<any> {
        return this.http.put<any>(
            UtilService.getUrl(OrganisationServiceService.URL_PUT_STATUS, [
                { k: ':id', v: id },
            ]),
            { id: id, status: status, children: children }
        );
    }
}
