import { OrganisationDetails, Group, Customer } from './models';
import { OperatorDetails } from './OperatorDetails';
import { PhoneModel } from './PhoneModel';
import { RepairOrder } from './RepairOrder';
import { PhoneRepairType } from './PhoneRepairType';
import { RepairTask } from './RepairTask';

export class RepairTaskProgress {
    repairTask?: RepairTask;
    currentStep?: number;
    status?: string;
    rate?: number;
    comments?: string;
}