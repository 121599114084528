
<button type="button" class="btn btn-primary pull-right" (click)="open(transferMachine)">{{'msg.transfer.machine' | translate}}</button>


<!-- Modal for transfering machines -->
<ng-template #transferMachine let-modal>
  <form [formGroup]="transferMachineForm">
    <div class="modal-header">
      <h5 class="modal-title">{{'msg.transfer.machine' | translate}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
          <div class="row">
              <div class="col">
                  <label for="machines">{{ 'msg.machine' | translate }}:</label>
                  <input
                    id="machines"
                    type="text"
                    class="form-control"
                    formControlName="machines"
                    placeholder="{{ 'msg.select.machine' | translate }} "
                    [ngbTypeahead]="machineTypeAheadSearch"
                    [resultFormatter]="machineTypeAheadFormatter"
                    [inputFormatter]="machineTypeAheadFormatter"
                    (focus)="machineTypeAheadFocus$.next($event.target.value)"
                    (click)="machineTypeAheadClick$.next($event.target.value)"
                  />
              </div>
              <div class="col">
                  <label for="transferTo">{{ 'msg.transfer.to' | translate }}:</label>
                  <input
                    id="transferTo"
                    type="text"
                    class="form-control"
                    formControlName="transferTo"
                    placeholder="{{ 'msg.select.organisation' | translate }} "
                    [ngbTypeahead]="orgTypeAheadSearch"
                    [resultFormatter]="orgTypeAheadFormatter"
                    [inputFormatter]="orgTypeAheadFormatter"
                    (focus)="orgTypeAheadFocus$.next($event.target.value)"
                    (click)="orgTypeAheadClick$.next($event.target.value)"
                  />
              </div>
          </div>
          <div class='row'>
            <div class='col'>
                <span class="error-invalid-input" *ngIf="transferMachineForm.get('machines').hasError('required') && !transferMachineForm.get('machines').pristine">
                  {{ 'error.field.required' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferMachineForm.get('machines').hasError('machine-format')">
                    {{ 'error.machine.format' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferMachineForm.get('machines').hasError('machine-limits')">
                    {{ 'error.machine.limits' | translate }}<br />
                </span>
            </div>
            <div class="col">
                <span class="error-invalid-input" *ngIf="transferMachineForm.get('transferTo').hasError('required') && !transferMachineForm.get('transferTo').pristine">
                  {{ 'error.field.required' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferMachineForm.get('transferTo').hasError('organisation-child')">
                  {{ 'error.organisation.not.valid' | translate }}<br />
                </span>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{ 'msg.close' | translate }}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!transferMachineForm.valid" (click)="onSubmit()">{{ 'msg.transfer' | translate }}</button>
    </div>
  </form>
</ng-template>
