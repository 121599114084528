import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class Configuration {

    public static readonly SHORT_DATE = 'dd/MM/yyyy';
    public static readonly LONG_DATE = 'dd/MM/yyyy hh:mm';
    public static readonly REPAIR_POINT = 'resource.type.repair.point';
    public static readonly DISTRIBUTOR = 'resource.type.distributor';
    public static readonly DTYPE_GTOOL = 'distributor.type.root';
}
