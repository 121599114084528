<div class="main-content">
    <div class="row">
        <div class="col-md-9 order-md-first order-sm-last">
            <div class="row">
                <div class="card">
                    <div class="card-header">
                        <h6 class="title">Credit History</h6>
                    </div>
                    <div class="card-body">
                        <app-credit-history></app-credit-history>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 order-md-last order-sm-first">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="title">Credit</h6>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 m-auto text-center">
                                    <span
                                        class="text-large"
                                        *ngIf="!isRoot"
                                        [ngClass]="
                                            {
                                                false: 'text-success',
                                                true: 'text-danger'
                                            }[organisationDetails?.credit < 0]
                                        "
                                    >
                                        {{ abs(organisationDetails.credit) }}
                                    </span>
                                </div>
                                <div class="col-6">
                                    <app-credit-order
                                        *ngIf="!isRoot"
                                    ></app-credit-order>
                                    <app-credit-transfer
                                        *ngIf="
                                            organisationDetails.resourceType ===
                                            'resource.type.distributor'
                                        "
                                    ></app-credit-transfer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="title">Credit Report</h6>
                        </div>
                        <div class="card-body">
                            <app-credit-report></app-credit-report>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
