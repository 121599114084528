<app-service-monitor></app-service-monitor>
<div class="mx-auto col-sm-12 col-md-4 login bg-light mt-5">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="p-3">
        <h4 class="mb-3 font-weight-normal text-center">
            {{ 'msg.please.sign.in' | translate }}
        </h4>

        <div *ngIf="showError" class="alert alert-danger" role="alert">
            {{ 'error.login' | translate }}
        </div>

        <div class="form-group">
          <label for="inputEmail" class="sr-only">
              {{ 'msg.username' | translate }}
          </label>
          <input
              type="text"
              formControlName="username"
              class="form-control"
              placeholder="{{ 'msg.username' | translate }}"
              required
              autofocus
          />
        </div>
        <div class="form-group">
          <label for="inputPassword" class="sr-only">
              {{ 'msg.password' | translate }}
          </label>
          <input
              type="password"
              formControlName="password"
              class="form-control"
              placeholder="{{ 'msg.password' | translate }}"
              required
          />
        </div>
        <div class="form-group text-center">
          <button
              class="btn btn-md btn-primary"
              type="submit"
              name="login"
              [disabled]="!loginForm.valid"
          >
              {{ 'msg.sign.in' | translate }}
          </button>
        </div>
        <div class="form-group text-center">
            <a
            [routerLink]="['../forgot/request']"
            class="text-warning"
            >
            {{'msg.forgot.password' | translate}}
            </a>
        </div>
    </form>
</div>
