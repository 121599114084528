import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { OrganisationDetails } from '@gtool.shared/models/models';
import { CreditTransfer } from '@gtool.shared/models/CreditTransfer';
import { CreditRequest } from '@gtool.shared/models/CreditRequest';
import { CreditRequestType } from '@gtool.shared/models/CreditRequestType';

import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';

import { CreditValidator, OrganisationChildValidator } from '@gtool.shared/custom-validation/form-control-validators';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';


@Component({
  selector: 'app-credit-transfer',
  templateUrl: './credit-transfer.component.html',
  styleUrls: ['./credit-transfer.component.css']
})
export class CreditTransferComponent implements OnInit {

  public transferCreditsForm: FormGroup;
  private _childrenOrgs: {id: number, name: string}[] = [];
  private _organisationDetails: OrganisationDetails;
  private _closeResult: string;

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.childrenOrgs
        : this.childrenOrgs.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  formatter = (x: { name: string }) => x.name;

  constructor(
    private toastr: MyToasterService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService,
    private orgService: OrganisationServiceService,
  ) { }

  ngOnInit() {
    this.organisationDetails = this.authService.getCurrentOrganisation();
    this.orgService.getOrganisationList(this.organisationDetails.id).subscribe(
      resp => {
        resp.forEach(org => {
          this._childrenOrgs.push({'id':org.id,'name':org.name});
        });
      });
  }

  onSubmit() {
    const creditRequest: CreditRequest = new CreditRequest();
    creditRequest.action = CreditRequestType.TRANSFER;
    creditRequest.ct = new CreditTransfer();
    creditRequest.ct.credit = this.transferCreditsForm.get('credits').value;
    creditRequest.ct.toId = this.transferCreditsForm.get('transferTo').value.id;

    this.orgService.transferCredit(this.organisationDetails.id, creditRequest).subscribe(result => {
      this.modalService.dismissAll();
      this.orgService.creditChange.next(true);
      this.toastr.success('msg.credit.transfered');
    });
  }

  private initTransferCreditsForm(): void {
    this.transferCreditsForm = new FormGroup({
        credits: new FormControl('', Validators.compose([
          CreditValidator(this.authService),
          Validators.required
        ])),
        transferTo: new FormControl('',Validators.compose([
          OrganisationChildValidator(this.childrenOrgs),
          Validators.required
        ]))
    });
  }

  get organisationDetails(): OrganisationDetails {
    return this._organisationDetails;
  }

  set organisationDetails(organisationDetails: OrganisationDetails) {
    this._organisationDetails = organisationDetails;
  }

  get childrenOrgs() {
    return this._childrenOrgs;
  }

  set childrenOrgs(childrenOrgs: {id:number,name:string}[]) {
    this._childrenOrgs = childrenOrgs;
  }

  open(content) {
    this.initTransferCreditsForm();
    this.modalService.open(content).result.then(
      result => {
        this._closeResult = `Closed with: ${result}`;
      },
      reason => {
        this._closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
