import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
    CustomerRequest,
    CustomerRequestType,
    Customer,
    CustomerDetails,
} from '@gtool.shared/models/models';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';

@Component({
    selector: 'app-add-customer',
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.css'],
})
export class AddCustomerComponent implements OnInit {
    constructor(
        private toast: MyToasterService,
        private organisationService: OrganisationServiceService,
        private authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public addCustomerForm: FormGroup;

    ngOnInit() {
        this.initializeCustomersForm();
    }
    private initializeCustomersForm() {
        this.addCustomerForm = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            phoneNumber: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
        });
    }

    public onSubmit() {
        console.log('ON SUBMIT');
        const customerRequest: CustomerRequest = new CustomerRequest();
        customerRequest.action = CustomerRequestType.CREATE;
        customerRequest.dto = new CustomerDetails();
        customerRequest.dto.firstName = this.addCustomerForm.get(
            'firstName'
        ).value;
        customerRequest.dto.lastName = this.addCustomerForm.get(
            'lastName'
        ).value;
        customerRequest.dto.type = this.addCustomerForm.get(
            'type'
        ).value;
        customerRequest.dto.phoneNumber = this.addCustomerForm.get(
            'phoneNumber'
        ).value;

        console.log('ID : ' + this.authService.getCurrentOrganisation().id);
        this.organisationService
            .createNewCustomer(
                this.authService.getCurrentOrganisation().id,
                customerRequest
            )
            .subscribe(result => {
                this.toast.success('msg.add.customer.success');
                this.router.navigate(['../'], {relativeTo: this.route});
            });
    }
}
