import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    constructor() {}

    public static getUrl(url: string, params: any[] = null): string {
        url = environment.backend_url + url;

        if (params != null) {
            for (const param of params) {
                url = url.replace(param.k, param.v);
            }
        }
        return url;
    }
}
