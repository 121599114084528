import { MachineType } from './MachineType';
import { OrganisationDetails } from './OrganisationDetails';
import { MachineStatusSnapshot } from './MachineStatusSnapshot';
import { RepairTaskInstance } from './RepairTaskInstance';

export class Machine {
    id?: number;
    machineType?: MachineType;
    orderStatus?: string;
    operationalStatus?: string;
    connected?: boolean;
    needsUpgrade?: boolean;
    machineTypeId?: number;
    repairPointId?: number;
    repairPoint?: OrganisationDetails;
    uuid?: string;
    serialNo?: string;
    password?: string;
    productVersion?: string;
    firmwareVersion?: string;
    snapshot?: MachineStatusSnapshot;
    reservedBy?: RepairTaskInstance;
}
