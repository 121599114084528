<button
    type="button"
    class="btn btn-primary pull-right"
    (click)="open(selectOperator)"
>
    {{ 'msg.select' | translate }} {{ 'msg.operator' | translate }}
</button>

<!-- Modal for buying credits -->
<ng-template #selectOperator let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{ 'msg.select' | translate }} {{ 'msg.operator' | translate }}
        </h5>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">{{ 'msg.first.name' | translate }}</th>
                        <th scope="col">{{ 'msg.last.name' | translate }}</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let oper of operators">
                        <td>{{ oper?.firstName }}</td>
                        <td>{{ oper?.lastName }}</td>
                        <td>
                            <button class="btn btn-success pull-right"
                              (click)="assignOperator(oper?.id)"
                            >
                                {{ 'msg.select' | translate }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            (click)="modal.close('Close click')"
        >
            {{ 'msg.close' | translate }}
        </button>
    </div>
</ng-template>
