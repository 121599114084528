import { OrganisationDetails, Group, Customer } from './models';
import { OperatorDetails } from './OperatorDetails';
import { MachineStatus } from './MachineStatus';

export class Command {
    cmd?: number;
    errorType?: number;
    ackCmd?: number;
    isAck?: boolean;
    isHeartbeat?: boolean;
    isError?: boolean;
    isComplete?: boolean;
    isPrompt?: boolean;
    isReady?: boolean;
    isStartAsk?: boolean;
    isStartPressed?: boolean;
    isLidOpened?: boolean;
    isLidOpening?: boolean;
    isLidClosing?: boolean;
    isLidClosed?: boolean;
    isPistonDown?: boolean;
    isConfirmPressed?: boolean;
    isConfirmAsk?: boolean;
    isDownload?: boolean;
    downloadStatus: string;
    status?: MachineStatus;
    hex?: string;

}
