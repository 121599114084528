import { MachineType } from './MachineType';
import { RepairTaskStep } from './RepairTaskStep';
import { RepairSpecs } from './RepairSpecs';
import { RepairTool } from './RepairTool';

export class PricingPolicyGroup {
    id?: number;
    name?: string;
}

export class RepairPricing {
    group?: PricingPolicyGroup;
    credit?: number;
}

export class RepairTask {
    order?: number;
    credit?: number;
    pricing?: RepairPricing[];
    steps?: RepairTaskStep[];
    name?: string;
    name_i18n?: Translation[];
    machineType?: MachineType;
    machineVersion?: string;
    specs: RepairSpecs;
    tools?: RepairTool[];
}


export class Translation{
  lang?: string;
  value?: string;
}
