<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h6>{{ 'msg.add.customer' | translate }}</h6>
                </div>
                <div class="card-body">
                    <form [formGroup]="addCustomerForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col">
                                            <label for="firstName">
                                                {{
                                                    'msg.first.name'
                                                        | translate
                                                }}:
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="firstName"
                                                placeholder="{{
                                                    'msg.first.name' | translate
                                                }}"
                                                name="firstName"
                                                class="form-control"
                                                id="firstName"
                                            />
                                        </div>
                                        <div class="col">
                                            <label for="lastName">
                                                {{
                                                    'msg.last.name' | translate
                                                }}:
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="lastName"
                                                placeholder="{{
                                                    'msg.last.name' | translate
                                                }}"
                                                name="lastName"
                                                class="form-control"
                                                id="lastName"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col">
                                            <label for="phoneNumber">
                                                {{
                                                    'msg.phone.number'
                                                        | translate
                                                }}:
                                            </label>
                                            <input
                                                type="text"
                                                formControlName="phoneNumber"
                                                placeholder="{{
                                                    'msg.phone.number'
                                                        | translate
                                                }}"
                                                name="phoneNumber"
                                                class="form-control"
                                                id="phoneNumber"
                                            />
                                        </div>
                                        <div class="col">
                                            <label for="type">
                                                {{
                                                    'msg.customer.type'
                                                        | translate
                                                }}:
                                            </label>
                                            <select
                                                formControlName="type"
                                                class="form-control"
                                                id="type"
                                            >
                                                <option value="" selected>
                                                    {{
                                                        'msg.select' | translate
                                                    }}
                                                    {{
                                                        'msg.customer.type'
                                                            | translate
                                                    }}
                                                </option>
                                                <option
                                                    value="customer.type.walk"
                                                >
                                                    {{
                                                        'customer.type.walk'
                                                            | translate
                                                    }}
                                                </option>
                                                <option
                                                    value="customer.type.b2b"
                                                >
                                                    {{
                                                        'customer.type.b2b'
                                                            | translate
                                                    }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <button
                                    class="btn btn-primary float-right ml-1"
                                    type="submit"
                                    [disabled]="!addCustomerForm.valid"
                                >
                                    {{ 'msg.add.customer' | translate }}
                                </button>
                                <a
                                    [routerLink]="['../']"
                                    class="btn btn-secondary float-right ml-1"
                                >
                                    {{ 'msg.cancel' | translate }}
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
