import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, Subject, combineLatest } from 'rxjs';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { OrganisationDetails, PricingPolicyGroup } from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Country } from '@gtool.shared/models/Country';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';


@Injectable()
export class OrganisationsResolver implements Resolve<any> {
    constructor(
      private orgService: OrganisationServiceService,
      private authService: AuthenticationService,
      private confService: ConfigurationService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<[Country[], OrganisationDetails, any]> {

        const id: any = route.params['id'];
        const orgId = (id !== 'my') ? id : this.authService.getCurrentOrganisation().id;

        return combineLatest(
          this.confService.getCountries(),
          this.orgService.getOrganisationDetails(Number(orgId)),
          new Promise((resolve, reject) => { resolve(id); })
        ).toPromise();
    }
}
