import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { MachineFirmware } from '@gtool.shared/models/MachineFirmware';
import { UtilService } from '@gtool.shared/services/util.service';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { Paging } from '@gtool.shared/models/Paging';
import { Direction } from '@gtool.shared/models/Direction';
import { DirectionType } from '@gtool.shared/models/DirectionType';
import { Filter } from '@gtool.shared/models/Filter';
import { FilterOperatorType } from '@gtool.shared/models/FilterOperatorType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { MachineType } from '@gtool.shared/models/MachineType';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';

@Component({
    selector: 'app-firmware',
    templateUrl: './firmware.component.html',
    styleUrls: ['./firmware.component.css'],
})
export class FirmwareComponent implements OnInit {
  public machineFirmwareResultSet: QueryResultSet<MachineFirmware>;
  public machineFirmwareResult: MachineFirmware[];

  // public machineFirmwares = new Array<MachineFirmware>();
  public machineTypes: MachineType[] = new Array(new MachineType(0, 'All'));


  public currentPage: number = 1;

  //total available pages, based on totalResults and page size
  public totalResultsWithSpecs: number;

  //array to be iterated on the UI
  public pageSizes: Array<number> = [10, 20, 50];
  public currentPageSize: number = 10;

  private currentMachineFirmwareQuery: QuerySpecification = new QuerySpecification();
  public currentMachineTypeName: string = 'All';
  public currentMachineTypeId: number = 0;


    constructor(
        private route: ActivatedRoute,
        private machineService: MachineService,
        private authService: AuthenticationService
    ) {}

    ngOnInit() {
        // this.machineService.getMachineFirmwares().subscribe((result) => {
        //     this.machineFirmwares = result;
        // });

        this.machineService.getMachinesFirmwareWithSpecs().subscribe((result) => {
            this.machineFirmwareResultSet = result;
            this.machineFirmwareResult = result.objectsResult;
            this.totalResultsWithSpecs = result.numberOfObjects;
        });

        this.machineService.getMachineTypes().subscribe(
          machineTypez => machineTypez.forEach(
              machineType =>
              this.machineTypes.push(new MachineType(machineType.id, machineType.name)))
              );

        this.currentMachineFirmwareQuery = this.generateDefaultQuery();
        }

    dowloadUrl(tid: string, fid: string, type: string): string {
        return (
            UtilService.getUrl(MachineService.URL_GET_MACHINE_FIRMWARE_CMD, [
                { k: ':tid', v: tid },
                { k: ':fid', v: fid },
                { k: ':type', v: type },
            ]) +
            '?access_token=' +
            this.authService.getToken()
        );
    }

    /**
     * This method will generate the default Query.
     * PageSize:10,Current Page:1
     */
    private generateDefaultQuery(): QuerySpecification {
      let query: QuerySpecification = new QuerySpecification();

      //Paging
      let p: Paging = new Paging();
      p.page = 1;
      p.limit = 10;
      p.order = "AmachineType.name,DproductVersion,DfirmwareVersion";

      // //Sorting
      // let directions: Array<Direction> = new Array();
      // let direction = new Direction();
      // direction.column = 'id';
      // direction.direction = DirectionType.DESC;
      // directions.push(direction);
      // p.directions = directions;

      //Filtering
      let f: Filter = new Filter();
      f.operator = FilterOperatorType.AND;

      let criterionQuery: CriterionQuery = new CriterionQuery();

      let criteria: Array<CriterionQuery> = [];
      criteria.push(criterionQuery);

      f.criteria = criteria;

      query.p = p;
      query.f = f;

      return query;
  }

  /**
   * Handle change PageSize event
   * @param pageSize
  */
 public changePageSize(pageSize: number): void {
   if (this.currentPageSize !== pageSize) {
     this.currentPageSize = pageSize;
     this.currentMachineFirmwareQuery.p.limit = pageSize;
     this.getFirmwareOrders();
    }
  }

  /**
   * Handle change Page event
   * @param page
  */
 public pageChanged(page: number): void {
   this.currentMachineFirmwareQuery.p.page = page;
   this.getFirmwareOrders();
  }

  /**
   * This method will send the updated QuerySpecification
  */
 private getFirmwareOrders(): void {
   this.machineService
   .getMachinesFirmwareWithSpecs(this.currentMachineFirmwareQuery)
   .subscribe((firmwares) => {
     this.machineFirmwareResultSet = firmwares;
     this.machineFirmwareResult = firmwares.objectsResult;
     this.totalResultsWithSpecs = firmwares.numberOfObjects;
    });
  }

  public changeMachineType(machineType: MachineType): void {
    if (this.currentMachineTypeId !== machineType.id) {
        this.currentMachineTypeId = machineType.id;
        this.currentMachineTypeName = machineType.name;
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentMachineFirmwareQuery.f.criteria) {
            if(criterion.columnName ==='machineType.id'){
                if(machineType.id === 0){
                    index = this.currentMachineFirmwareQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                } else {
                    criterion.value = machineType.id.toString();
                    found = true;
                }
                break;
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'machineType.id';
            criterionQuery.value = machineType.id.toString();
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.EQUAL;
            this.currentMachineFirmwareQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentMachineFirmwareQuery.f.criteria.splice(index,1);
        }
        this.getFirmwareOrders();
    }
  }
}
