<li *ngIf="display" class="nav-item" ngbDropdown>
    <a
        [ngClass]="{ 'text-danger': anyDisconnected() }"
        class="nav-link"
        id="dropdownServiceMonitor"
        ngbDropdownToggle
    >
        <i class="now-ui-icons media-2_sound-wave"></i>
        <p>
            <span class="d-block">{{ 'msg.network.status' | translate }}</span>
        </p>
    </a>

    <div
        class="dropdown-menu dropdown-menu-right"
        ngbDropdownMenu
        aria-labelledby="dropdownServiceMonitor"
    >
        <div class="dropdown-item">
            <div class="row">
              <div class="col">
                <span [ngClass]="{ 'text-danger': !clientOnline }" class=""
                    ><i class="now-ui-icons media-2_sound-wave"></i
                    >{{ 'msg.network.status.client' | translate }}</span
                >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <span [ngClass]="{ 'text-danger': !appServerOnline }" class=""
                    ><i class="now-ui-icons media-2_sound-wave"></i
                    >{{ 'msg.network.status.server' | translate }}</span
                >
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <span
                    [ngClass]="{ 'text-danger': !mqttServerOnline }"
                    class="d-block"
                    ><i class="now-ui-icons media-2_sound-wave"></i
                    >{{ 'msg.network.status.mqtt' | translate }}</span
                >
              </div>
            </div>
        </div>
    </div>
</li>
