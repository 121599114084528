import { Component, OnInit, OnDestroy } from '@angular/core';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Subscription, timer } from 'rxjs';
import { Message } from '@stomp/stompjs';
import { Command } from '@gtool.shared/models/models';
import { Machine } from '@gtool.shared/models/Machine';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.css']
})
export class MachineComponent implements OnInit, OnDestroy {

  private commTimer = timer(1000, 5000); // wait for 1 sec and then loop every 5
  private commTimeSubscription: Subscription;
  private topicSubscription: Subscription;
  public connected: boolean;
  private machineId: number;
  public cmd: Command;
  public lastCheck: Date;
  public lastComm: Date;
  public machine: Machine;
  public isRoot: boolean;

  public allcmds: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rxStompService: RxStompService,
    private machineService: MachineService,
    private authService: AuthenticationService
  ) {
    this.connected = false;
  }

  ngOnInit() {
    this.connected = false;
    this.route.params.subscribe( p => {
      this.machineId  = p['id'];
      this.machineService.getMachine(this.machineId.toString()).subscribe( m => {
        this.machine = m;
        // set as disconnected if last heartbeat was 2 seconds ago
        this.commTimeSubscription = this.commTimer.subscribe(v => {
          this.lastCheck = new Date();
          if (this.lastComm) {
            const timeLimit = new Date().getTime() - ( 2 * 1000 ); // five seconds ago
            // if five seconds ago is greater than the last time we got a heartbeat, mark as offline
            if ( timeLimit > this.lastComm.getTime() ) {
              this.connected = false;
            }
          }
        });

        this.topicSubscription = this.rxStompService.watch('/topic/device.' + m.serialNo).subscribe((message: Message) => {
          this.cmd = JSON.parse(message.body);
          console.log(this.cmd);
          // anything that comes through is an indication this machine is up and running
          this.lastComm = new Date();
          this.connected = true;
        });

        this.isRoot = this.authService.isRoot(this.authService.getCurrentOrganisation());

      });
    });
  }

  ngOnDestroy() {
    this.commTimeSubscription.unsubscribe();
    this.topicSubscription.unsubscribe();
  }

}
