import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, Subject, combineLatest } from 'rxjs';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { OrganisationDetails, PricingPolicyGroup } from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { Country } from '@gtool.shared/models/Country';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';

@Injectable()
export class OrganisationsListResolver implements Resolve<any> {
    constructor(
        private orgService: OrganisationServiceService,
        private authService: AuthenticationService,
        private confService: ConfigurationService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Promise<[Country[], OrganisationDetails[]]> {
        const id = this.authService.getCurrentOrganisation().id;

        return combineLatest(
          this.confService.getCountries(),
          this.orgService.getOrganisationList(id, true),
        ).toPromise();
    }
}
