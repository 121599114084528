import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OperatorDetails, OperatorRequest, OperatorRequestType, Repair, RepairRequest } from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';
import { RepairService } from '@gtool.shared/services/repair.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-select-operator',
    templateUrl: './select-operator.component.html',
    styleUrls: ['./select-operator.component.css'],
})
export class SelectOperatorComponent implements OnInit, OnDestroy {
    private _closeResult: string;
    @Input() repair: Repair;
    public orgId: any;
    public operators: Array<OperatorDetails>;
    private subscription: Subscription;



    constructor(
      private modalService: NgbModal,
      private authService: AuthenticationService,
      private repairService: RepairService,
      private orgService: OrganisationServiceService
    ) {}

    ngOnInit() {
        this.subscription = this.repairService.repairChange.subscribe();
        this.orgId = this.authService.getCurrentOrganisation().id;
        this.fetchOperators();

    }

    ngOnDestroy(){
      this.subscription.unsubscribe();
    }

    private fetchOperators(): void {
      const operatorRequest: OperatorRequest = new OperatorRequest();
      operatorRequest.action = OperatorRequestType.QUERY;
      this.orgService.getOperators(
        this.orgId,
        operatorRequest
      ).subscribe(opers => {
        this.operators = opers;
      });
    }

    private assignOperator( operatorId: number ): void {
      const request: RepairRequest  = new RepairRequest();
      request.action = 'ASSIGN';
      request.dto = new Repair();
      request.dto.operatorId = operatorId;
      this.repairService.updateRepair(this.repair.repairOrderId, this.repair.id, request).subscribe( r => {
        this.repairService.repairChange.next(true);
        this.modalService.dismissAll();
      });
    }

    open(content) {
        this.modalService.open(content, { size: 'lg' }).result.then(
            result => {
                this._closeResult = `Closed with: ${result}`;
            },
            reason => {
                this._closeResult = `Dismissed ${this.getDismissReason(
                    reason
                )}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
