<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">

                          <a
                                [routerLink]="['/', 'cp', 'machines']"
                                class="btn btn-primary"
                            >
                                <i class="now-ui-icons objects_spaceship"></i>
                                {{ 'msg.machines' | translate }}
                            </a>
                            <a
                                [routerLink]="['new', 'form']"
                                class="btn btn-primary pull-right"
                            >
                                <i class="fa fa-fw fa-plus-circle"></i>
                                {{ 'msg.add' | translate }}
                            </a>
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="row">
                        <div
                            class="col d-flex justify-content-start pagination-bar"
                        >
                            <span
                                >{{ 'msg.query.results' | translate }}:
                                {{ totalResultsWithSpecs }}</span
                            >
                        </div>

                        <div class="col d-flex justify-content-end">
                            <fieldset class="form-inline">
                                <div class="form-group">
                                    <label
                                        style="margin-right: 0.5rem"
                                        for="page-size"
                                        >{{ 'msg.page.size' | translate }}:
                                    </label>
                                    <div
                                        class="btn-group"
                                        ngbDropdown
                                        role="group"
                                        aria-label="Page size"
                                    >
                                        <button
                                            id="page-size"
                                            class="btn btn-outline-primary"
                                            ngbDropdownToggle
                                        >
                                            {{ currentPageSize }}
                                        </button>
                                        <div
                                            class="dropdown-menu"
                                            ngbDropdownMenu
                                        >
                                            <div *ngFor="let ps of pageSizes">
                                                <button
                                                    (click)="changePageSize(ps)"
                                                    ngbDropdownItem
                                                >
                                                    {{ ps }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div
                            class="col-auto d-flex justify-content-end pagination-bar"
                        >
                            <ngb-pagination
                                (pageChange)="pageChanged($event)"
                                [collectionSize]="totalResultsWithSpecs"
                                [pageSize]="currentPageSize"
                                [(page)]="currentPage"
                                [maxSize]="3"
                            >
                            </ngb-pagination>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Machine Type
                                          <div
                                                class="btn-group"
                                                ngbDropdown
                                                role="group"
                                                aria-label="Sort by machine type"
                                            >
                                                <button
                                                    class="btn btn-outline-primary"
                                                    ngbDropdownToggle
                                                >
                                                    {{currentMachineTypeName}}
                                                </button>
                                                <div
                                                    class="dropdown-menu"
                                                    ngbDropdownMenu
                                                >
                                                    <div
                                                        *ngFor="let type of machineTypes"
                                                    >
                                                        <button
                                                            (click)="changeMachineType(type)"
                                                            ngbDropdownItem
                                                        >
                                                            {{type.name}}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col">Product Version</th>
                                        <th scope="col">Firmware Version</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let machineFirmware of machineFirmwareResult
                                        "
                                    >
                                        <td>
                                            {{
                                                machineFirmware.machineType.name
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                machineFirmware?.productVersion
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                machineFirmware?.firmwareVersion
                                            }}
                                        </td>
                                        <td>
                                            <a
                                                [href]="
                                                    dowloadUrl(
                                                        machineFirmware?.machineTypeId,
                                                        machineFirmware.id,
                                                        'firmware'
                                                    )
                                                "
                                                class="btn btn-primary"
                                            >
                                                <i
                                                    class="fa fa-fw fa-download"
                                                ></i>
                                                {{
                                                    'msg.firmware.file'
                                                        | translate
                                                }}
                                            </a>
                                            <span>&nbsp;</span>
                                            <a
                                                [href]="
                                                    dowloadUrl(
                                                        machineFirmware?.machineTypeId,
                                                        machineFirmware.id,
                                                        'lcd'
                                                    )
                                                "
                                                class="btn btn-primary"
                                            >
                                                <i
                                                    class="fa fa-fw fa-download"
                                                ></i>
                                                {{ 'msg.lcd.file' | translate }}
                                            </a>
                                            <span>&nbsp;</span>
                                            <a
                                                [href]="
                                                    dowloadUrl(
                                                        machineFirmware?.machineTypeId,
                                                        machineFirmware.id,
                                                        'info'
                                                    )
                                                "
                                                class="btn btn-primary"
                                            >
                                                <i
                                                    class="fa fa-fw fa-download"
                                                ></i>
                                                {{
                                                    'msg.info.file' | translate
                                                }}
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
