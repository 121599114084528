<div class="main-content">
    <div class="row">
        <div class="col-md-12">
        <div class="card">
            <div class="card-body">

                <form [formGroup]="machineForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <label for="model">Number of Machines:</label>
                                <input
                                    type="text"
                                    formControlName="number"
                                    placeholder="Number of Machines"
                                    name="number"
                                    class="form-control"
                                    id="number"
                                />
                            </div>
                            <div class="col">
                                <label for="model">Firmware Version:</label>
                                <input
                                    type="text"
                                    formControlName="firmwareVersion"
                                    placeholder="Firmware Version"
                                    name="firmwareVersion"
                                    class="form-control"
                                    id="firmwareVersion"
                                />
                            </div>
                            <div class="col">
                                <label for="model">Product Version:</label>
                                <input
                                    type="text"
                                    formControlName="productVersion"
                                    placeholder="Product Version"
                                    name="productVersion"
                                    class="form-control"
                                    id="productVersion"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="machinetype">Machine Type:</label>
                            <select
                                formControlName="machineTypeId"
                                class="form-control"
                                id="machineTypeId"
                            >
                                <option value="" selected> Select machine Type. </option>
                                <option
                                    *ngFor="let machineType of machineTypes"
                                    value="{{ machineType.id }}"
                                >
                                    {{ machineType.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col">
                            <label for="machinetype">Select Order Status:</label>
                            <select
                                formControlName="orderStatus"
                                class="form-control"
                                id="orderStatus"
                            >
                                <option value="" selected> Select Order Status. </option>
                                <option value="machine.status.for.sale">{{
                                    'machine.status.for.sale' | translate
                                }}</option>
                                <option value="machine.status.in.production">{{
                                    'machine.status.in.production' | translate
                                }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-12">
                            <button
                                class="btn btn-primary float-right ml-1"
                                type="submit"
                                [disabled]="!machineForm.valid"
                            >
                                Add Machine
                            </button>
                            <a
                                [routerLink]="['../../']"
                                class="btn btn-secondary float-right ml-1"
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</div>