import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { I18nService } from '@gtool.i18n/i18n.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';
import { interval, Subscription } from 'rxjs';
import { CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';

@Component({
    selector: 'app-service-monitor',
    templateUrl: './service-monitor.component.html',
    styleUrls: ['./service-monitor.component.css'],
})
export class ServiceMonitorComponent implements OnInit, OnDestroy {
    @Input() display: boolean = false;

    public clientOnline: boolean = true;
    public mqttServerOnline: boolean = true;
    public appServerOnline: boolean = true;

    private timeLastMessageReceived = Date.now();

    private mSub: Subscription;
    private qSub: Subscription;

    constructor(
        private httpClient: HttpClient,
        private rxStompService: RxStompService,
        private toast: MyToasterService,
        private i18n: I18nService
    ) {}

    ngOnInit(): void {
        this.mSub = this.rxStompService
            .watch('/topic/monitoring')
            .subscribe((message: Message) => {
                var currentStatus = message.body === 'true';
                // this.showToast(currentStatus, this.mqttServerOnline, 'msg.monitoring.mqtt');
                this.mqttServerOnline = currentStatus;
                this.timeLastMessageReceived = Date.now();
            });

        this.monitor(true);
        this.qSub = interval(15_000).subscribe((x) => {
            this.monitor(false);
        });
    }

    ngOnDestroy(): void {
        this.qSub.unsubscribe();
        this.mSub.unsubscribe();
    }

    private monitor(firstCall:boolean): void {
      console.log('Monitoring now: ' +  Date.now() + ' vs last: ' + this.timeLastMessageReceived);
        //check if last websocket message is older than now - 1 minute (or if it is the first call)
        //if it is, check if the application is up
        //if not, check if the client can access f.e wikipedia
        var mqttTimeout = this.timeLastMessageReceived + 30_000 < Date.now();
        var wasDisconnected = !this.appServerOnline || !this.clientOnline || !this.mqttServerOnline
        if( firstCall || wasDisconnected || mqttTimeout ){
          if(!firstCall){
            console.log('MQTT Disconnected AND probably APP disconnected');
            // this.showToast(false, this.mqttServerOnline, 'msg.monitoring.mqtt');
            this.mqttServerOnline = false;
          }
        // check app server
        this.httpClient
            .get(environment.backend_url + '/health',
            { headers: {
              'S-SILENT': 'true' }
            })
            .toPromise()
            .then((r) => {
                this.showToast(true, this.appServerOnline, 'msg.monitoring.server');
                this.appServerOnline = true;
                console.log('APP connected');
            })
            .catch((e) => {
                this.showToast(false, this.appServerOnline, 'msg.monitoring.server');
                this.appServerOnline = false;
                console.log('APP disconnected');
            });

        // check client
        this.httpClient
            .get('https://wikimedia.org/api/rest_v1/feed/availability')
            .toPromise()
            .then((r) => {
                this.showToast(true, this.clientOnline, 'msg.monitoring.client');
                this.clientOnline = true;
                console.log('Client connected');
            })
            .catch((e) => {
                this.showToast(false, this.clientOnline, 'msg.monitoring.client');
                this.clientOnline = false;
                console.log('Client disconnected');
            });
          }
    }


    private showToast( currentStatus:boolean, previousStatus: boolean, messagePfx: string ): void{
        if( currentStatus != previousStatus && currentStatus ){
          //reconnection
          this.toast.success( messagePfx + ".connected" )
        }else if( currentStatus != previousStatus && !currentStatus ){
          // disconnection - errors must be translated programmatically
          // (unless they are handled in the http erro interceptor)
          this.toast.error( this.i18n.translate(messagePfx + ".disconnected") )
        }
    }

    public anyDisconnected(){
      return !this.appServerOnline || !this.mqttServerOnline || !this.clientOnline;
    }
}
