import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18nService } from '@gtool.i18n/i18n.service';

@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslatePipe implements PipeTransform {
    constructor(
        private i18nService: I18nService,
        private sanitizer: DomSanitizer
    ) {}

    transform(value: string, args?: any[], html?: boolean): any {
        if (!value) {
            return '';
        }
        let text: string = this.i18nService.translate(value);
        if (!text) {
            text = '???' + value;
        }
        if (html) {
            return this.sanitizer.bypassSecurityTrustHtml(text);
        } else {
            return text;
        }
    }
}
