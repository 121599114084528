import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { I18nService } from '@gtool.i18n/i18n.service';

@Injectable()
export class GlobalResolver implements Resolve<any> {
    constructor(
        private i18n: I18nService
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        return null;//await this.i18n.init();
    }
}
