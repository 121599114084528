<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="title">
                                {{ 'msg.reports' | translate }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="card-body all-icons">
                    <div class="row">

                        <div
                            *ngIf="isDistributor" 
                            (click)="goTo(['revenue'])"
                            class="font-icon-list hover-hand col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
                        >
                            <div class="font-icon-detail">
                                <i
                                    class="now-ui-icons business_money-coins"
                                ></i>
                                <p>{{'msg.revenue.report' | translate}}</p>
                            </div>
                        </div>
                                          
                        <div (click)="goTo(['repairs'])"
                            class="font-icon-list hover-hand col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-6"
                        >
                            <div class="font-icon-detail">
                                <i
                                    class="now-ui-icons objects_support-17"
                                ></i>
                                <p>{{'msg.repairs.report' | translate}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
