<ng-container *ngIf="hasCredit">
    <span *ngIf="isGtool" class="float-right">
        <button
            type="button"
            class="btn btn-primary btn-sm my-0"
            placement="right"
            [ngbPopover]="popContent"
            popoverTitle="Default: {{ task.credit }}"
            triggers="mouseenter:mouseleave"
        >
            Credits
        </button>
        <ng-template #popContent>
            <ul class="list-group">
                <ng-container *ngFor="let group of task.pricing">
                    <li class="list-group item">
                        {{ group.group.name }}: {{ group.credit }}
                    </li></ng-container
                >
            </ul>
        </ng-template>
    </span>

    <span *ngIf="isRepairPoint" class="badge badge-primary float-right p-1">
        credits: {{ credit }}
    </span>
</ng-container>
