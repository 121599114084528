import { Component, OnInit, QueryList, ViewChildren,  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Debounce } from '@gtool.shared/decorators/debounce';
import { IsNotEmpty } from '@gtool.shared/decorators/not-empty';
import { SortableHeader, SortEvent } from '@gtool.shared/directives/SortableHeader.directive';
import { CriteriaOperatorType } from '@gtool.shared/models/CriteriaOperatorType';
import { CriteriaValueType } from '@gtool.shared/models/CriteriaValueType';
import { CriterionQuery } from '@gtool.shared/models/CriterionQuery';
import { Direction } from '@gtool.shared/models/Direction';
import { DirectionType } from '@gtool.shared/models/DirectionType';
import { Filter } from '@gtool.shared/models/Filter';
import { FilterOperatorType } from '@gtool.shared/models/FilterOperatorType';
import { Paging } from '@gtool.shared/models/Paging';
import { QueryResultSet } from '@gtool.shared/models/QueryResultSet';
import { QuerySpecification } from '@gtool.shared/models/QuerySpecification';
import { RepairOrder } from '@gtool.shared/models/RepairOrder';
import { RepairService } from '@gtool.shared/services/repair.service';



@Component({
    selector: 'app-repair-orders',
    templateUrl: './repair-orders.component.html',
    styleUrls: ['./repair-orders.component.css'],
})

export class RepairOrdersComponent implements OnInit {
    public ordersResultSet: QueryResultSet<RepairOrder>;
    public ordersResult: RepairOrder[];

    public orderStatuses: string[] = new Array();
	public dateFields: string[] = new Array();
    //the default value for sorting is status:New
    public currentStatus: string = 'repair.order.status.new';
	public currentDateField: string = '';
    public currentPage: number = 1;

    //total available pages, based on totalResults and page size
    public totalResultsWithSpecs: number;

    //array to be itterated on the UI
    public pageSizes: Array<number> = [10, 20, 50];
    public currentPageSize: number = 10;

    private currentRepaiOrdersQuery: QuerySpecification = new QuerySpecification();

    public showOrderIdSearch: boolean = false;
    public showRefNoSearch: boolean = false;
    public showCustomerSearch: boolean = false;
    public showStatusButton: boolean = false;


    constructor(
        private route: ActivatedRoute,
        private repairService: RepairService
    ) {}

    ngOnInit() {
        this.ordersResultSet = this.route.snapshot.data.list; // receive the data from the resolver
        this.ordersResult = this.ordersResultSet.objectsResult;
        this.totalResultsWithSpecs = this.ordersResultSet.numberOfObjects;
        this.generateRepairOrderStatuses();
		this.generateDateFields();
        this.currentRepaiOrdersQuery = this.generateDefaultQuery();
    }

    /**
     * This method will generate the default Query.
     * PageSize:10,Current Page:1, Order Status:New, Sorting:Newest first
     */
    private generateDefaultQuery(): QuerySpecification {
        let query: QuerySpecification = new QuerySpecification();

        //Paging
        let p: Paging = new Paging();
        p.page = 1;
        p.limit = 10;

        //Sorting
        let directions: Array<Direction> = new Array();
        let direction = new Direction();
        direction.column = 'updateInfo.dateUpdated';
        direction.direction = DirectionType.DESC;
        directions.push(direction);
        p.directions = directions;

        //Fltering
        let f: Filter = new Filter();
        f.operator = FilterOperatorType.AND;

        let criterionQuery: CriterionQuery = new CriterionQuery();
        criterionQuery.columnName = 'status';
        criterionQuery.value = this.currentStatus;
        criterionQuery.valueType = CriteriaValueType.STRING;
        criterionQuery.operator = CriteriaOperatorType.EQUAL;

        let criteria: Array<CriterionQuery> = [];
        criteria.push(criterionQuery);

        f.criteria = criteria;

        query.p = p;
        query.f = f;

        return query;
    }

    /**
     * Generate the available options of a RepairOrders based on status
     */
    private generateRepairOrderStatuses(): void {
        this.orderStatuses.push('msg.default.select');
        this.orderStatuses.push('repair.order.status.new');
        this.orderStatuses.push('repair.order.status.pending');
        this.orderStatuses.push('repair.order.status.finished');
        this.orderStatuses.push('repair.order.status.error');
        this.orderStatuses.push('repair.order.status.canceled');
    }

	private generateDateFields(): void {
        this.dateFields.push('');
        this.dateFields.push('msg.date.created');
        this.dateFields.push('msg.date.started');
        this.dateFields.push('msg.due.date');
        this.dateFields.push('msg.date.finished');
    }

    /**
     * Handle change Status event
     * @param status
     */
    public changeStatus(status: string): void {
        if (this.currentStatus !== status) {
            this.currentStatus = status;
            let found: boolean= false;
            let index: number = -1;
            for (var criterion of this.currentRepaiOrdersQuery.f.criteria) {
                if(criterion.columnName ==='status'){
                    if(status === 'msg.default.select'){
                        index = this.currentRepaiOrdersQuery.f.criteria.indexOf(criterion,0);
                        found = true;
                    } else {
                        criterion.value = status;
                        found = true;
                    }
                    break;
                }
            }
            if(!found){
                let criterionQuery: CriterionQuery = new CriterionQuery();
                criterionQuery.columnName = 'status';
                criterionQuery.value = status;
                criterionQuery.valueType = CriteriaValueType.STRING;
                criterionQuery.operator = CriteriaOperatorType.EQUAL;
                this.currentRepaiOrdersQuery.f.criteria.push(criterionQuery);
            } else if(index > -1){
                this.currentRepaiOrdersQuery.f.criteria.splice(index,1);
            }
            this.getRepairOrders();
        }
    }

    /**
     * Handle change PageSize event
     * @param pageSize
     */
    public changePageSize(pageSize: number): void {
        if (this.currentPageSize !== pageSize) {
            this.currentPageSize = pageSize;
            this.currentRepaiOrdersQuery.p.limit = pageSize;
            this.getRepairOrders();
        }
    }

    /**
     * Handle change Page event
     * @param page
     */
    public pageChanged(page: number): void {
        this.currentRepaiOrdersQuery.p.page = page;
        this.getRepairOrders();
    }

    /**
     * This method will send the updated QuerySpecification
     */
    private getRepairOrders(): void {
        this.repairService
            .getRepairOrdersWithSpecsAndPagination(this.currentRepaiOrdersQuery)
            .subscribe((orders) => {
                this.ordersResultSet = orders;
                this.ordersResult = orders.objectsResult;
                this.totalResultsWithSpecs = orders.numberOfObjects;
            });
    }
    
    public closeSearchInput(columnName: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentRepaiOrdersQuery.f.criteria) {
            if(criterion.columnName === columnName){
                index = this.currentRepaiOrdersQuery.f.criteria.indexOf(criterion,0);
                found = true;
            }
        }
        if(found && index > -1){
            this.currentRepaiOrdersQuery.f.criteria.splice(index,1);
        }
        this.getRepairOrders();
        switch (columnName){
            case 'id' :
                //search by orderId
                this.showOrderIdSearch = false;
                break;
            case 'customer.lastName':
                // search by customer lastname
                this.showCustomerSearch = false;
                break;
            case 'refNo':
                //search by reference number
                this.showRefNoSearch = false;
                break;
            case 'status':
                //search by reference number
                this.showStatusButton = false;
                break;
        }
    }

    /**
     * This method will show the specified search input, hide the others and disable the status filtering button
     * @param field 0:Order ID | 1: Customer last name | 2: Reference number
     */
    public openSearchInput(field:number):void{
        switch (field){
            case 0 :
                //search by orderId
                this.showOrderIdSearch = true;
                break;
            case 1:
                // search by customer lastname
                this.showCustomerSearch = true;
                break;
            case 2:
                //search by reference number
                this.showRefNoSearch = true;
                break;
            case 3:
                //search by status
                this.showStatusButton = true;
                break;
        }

    }

    /**
     * This method will close all the search inputs
     */
    private closeAllSearchInputs():void{
        this.showOrderIdSearch = false;
        this.showCustomerSearch = false;
        this.showRefNoSearch = false;
        
    }


    @Debounce(500)
    @IsNotEmpty()
    public searchByOrderId(id: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentRepaiOrdersQuery.f.criteria) {
            if(criterion.columnName ==='id'){
                if(id !== ''){
                    criterion.value = id;
                    found = true;
                    break;
                } else {
                    index = this.currentRepaiOrdersQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                }
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'id';
            criterionQuery.value = id;
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.EQUAL;
            this.currentRepaiOrdersQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentRepaiOrdersQuery.f.criteria.splice(index,1);
        }
        this.getRepairOrders();
    }

    @Debounce(500)
    @IsNotEmpty()
    public searchByRefNumber(refNumber: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentRepaiOrdersQuery.f.criteria) {
            if(criterion.columnName ==='refNo'){
                if(refNumber !== ''){
                    criterion.value = refNumber;
                    found = true;
                    break;
                } else {
                    index = this.currentRepaiOrdersQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                }
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'refNo';
            criterionQuery.value = refNumber;
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.LIKE;
            this.currentRepaiOrdersQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentRepaiOrdersQuery.f.criteria.splice(index,1);
        }
        this.getRepairOrders();
    }


    
    @Debounce(500)
    @IsNotEmpty()
    public searchByCustomer(name: string): void {
        let found: boolean= false;
        let index: number = -1;
        for (var criterion of this.currentRepaiOrdersQuery.f.criteria) {
            if(criterion.columnName ==='customer.lastName'){
                if(name !== ''){
                    criterion.value = name;
                    found = true;
                    break;
                } else {
                    index = this.currentRepaiOrdersQuery.f.criteria.indexOf(criterion,0);
                    found = true;
                }
            }
        }
        if(!found){
            let criterionQuery: CriterionQuery = new CriterionQuery();
            criterionQuery.columnName = 'customer.lastName';
            criterionQuery.value = name;
            criterionQuery.valueType = CriteriaValueType.STRING;
            criterionQuery.operator = CriteriaOperatorType.LIKE;
            this.currentRepaiOrdersQuery.f.criteria.push(criterionQuery);
        } else if(index > -1){
            this.currentRepaiOrdersQuery.f.criteria.splice(index,1);
        }
        this.getRepairOrders();
    }
    
    @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

	public onSort({ column, direction }: SortEvent) {

		// resetting other headers
		this.headers.forEach(header => {
			if (header.sortable !== column) {
				header.direction = '';
			}
		});
		//Sorting
        let directions: Array<Direction> = new Array();
        let dir = new Direction();
        if(column === 'date'){
			dir.column = this.getSortColumnByKey(this.currentDateField);
		} else {
        	dir.column = column;
		}
        if(direction === 'asc') {
			dir.direction = DirectionType.ASC;
		} else {
			dir.direction = DirectionType.DESC;
		}
        directions.push(dir);
        this.currentRepaiOrdersQuery.p.directions = directions;
        this.getRepairOrders();
	}
	
	public changeSelectedField(field: string):void{
        this.currentDateField = field;        
    }

    private getSortColumnByKey(key : string):string{
		switch (key){
            case 'msg.date.created':
                return 'createInfo.dateCreated';
            case 'msg.date.started':
                return 'startFinishInfo.dateStarted';
            case 'msg.due.date':
                return 'dateDue';
            case 'msg.date.finished':
                return 'startFinishInfo.dateFinished';
        }
	}
}
