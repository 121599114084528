import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService } from '@gtool.i18n/i18n.service';
import { Language } from '@gtool.i18n/models/Language';
import { AutoTranslateRequest } from '@gtool.i18n/models/Translation';
import { RepairTask, Translation } from '@gtool.shared/models/RepairTask';
import {
    ProcessI18nRequest,
    RepairTaskStep,
} from '@gtool.shared/models/RepairTaskStep';
import { RepairType } from '@gtool.shared/models/RepairType';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-translate-process-modal',
    templateUrl: './translate-process-modal.component.html',
    styleUrls: ['./translate-process-modal.component.css'],
})
export class TranslateProcessModalComponent implements OnInit, OnDestroy {
    @ViewChild('translateProcessModal', { static: false }) private content;
    private subscription: Subscription;

    private i18nForm: FormGroup;
    public languages: Language[];
    public step: RepairTaskStep;
    public task: RepairTask;

    private repairTypeId: number;
    private repairType:RepairType;
    private target: string;

    public isRepairStep : boolean = true;
    private defaultValue: string;

    public allowEditDefault: boolean = false;
    public editDefault: boolean = false;

    constructor(
        private i18nService: I18nService,
        private configService: ConfigurationService,
        private authService: AuthenticationService,
        private modal: NgbModal,
        private toast: MyToasterService
    ) {}

    ngOnInit(): void {
        this.languages = this.i18nService
            .getAvailableLanguages()
            .filter((l) => l.enabled)
            .sort((l) => l.position);

        this.allowEditDefault = this.authService.isRoot(this.authService.getCurrentOrganisation());
        this.i18nForm = new FormGroup({});

        this.i18nForm.addControl(
            'translation_default',
            new FormControl({ value: '', disabled: true })
        );
        this.languages.forEach((l) => {
            this.i18nForm.addControl(
                'translation_' + l.iso,
                new FormControl('')
            );
        });

        this.i18nForm.addControl(
            'applyToAll',
            new FormControl(false)
        );

        this.subscription = this.i18nService
            .translationPopup()
            .subscribe((e) => {
                if (e && e.event === 'popup') {
                    this.translateProcessModal(
                        e.repairTypeId,
                        e.repairType,
                        e.task,
                        e.step,
                        e.target,
                        e.applyToAll
                    );
                }
            });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public translateProcessModal(
        repairTypeId: number,
        repairType: RepairType,
        task: RepairTask,
        step: RepairTaskStep,
        target: string,
        applyToAll: boolean
    ) {
        this.repairType = repairType;
        this.step = step;
        this.task = task;
        this.repairTypeId = repairTypeId;
        this.target = target;
        this.isRepairStep = step? true : false;

        const objName = step ? 'step' : task ? 'task' : 'repairType';
        this.defaultValue = eval('this.' + objName + '.' + target); // ex. this.task.name | this.step.prompt/info

        const i18nValues: Translation[] = eval(
            'this.' + objName + '.' + target + '_i18n'
        );
        this.i18nForm.controls['translation_default'].setValue(this.defaultValue);
        this.languages.forEach((l) => {
            const i18nValue = i18nValues
                ? i18nValues.find((v) => v.lang === l.iso)
                : null;
            const fieldValue = i18nValue
                ? i18nValue.value
                : l.primary
                ? this.defaultValue
                : '';
            this.i18nForm.controls['translation_' + l.iso].setValue(fieldValue);
        });
        this.i18nForm.controls['applyToAll'].setValue(applyToAll);
        this.modal.open(this.content, { size: 'xl' }).result.then(
            (result) => {},
            (reason) => {
              this.editDefault = false;
              this.i18nForm.controls['translation_default'].disable();
            }
        );

    }

    public onSubmit() {
        const request: ProcessI18nRequest = new ProcessI18nRequest();
        request.repairTypeId = this.repairTypeId;
        request.taskId = !this.task ? null : this.task.order;
        request.stepId = !this.step ? null : this.step.order;
        request.target = this.target;
        request.type = this.step ? 'step' : this.task ? 'task' : 'repair';
        request.applyToAll = this.i18nForm.controls['applyToAll'].value;
        request.translations = new Array();
        this.languages.forEach((l) => {
          request.translations.push( { lang: l.iso, value: this.i18nForm.controls['translation_' + l.iso].value } );
        });

        // if enabled default edit, send that as well
        if( this.editDefault ){
          request.translations.push( { lang: 'default', value: this.i18nForm.controls['translation_default'].value } );
        }

        this.configService.translateProcess(request).subscribe(r => {
          this.modal.dismissAll();
          this.toast.success('msg.i18n.process.success');
          this.i18nService
          .translationPopup()
          .next({ 'event': 'refresh'});
        });
    }

    public autoTranslate( $event, lang:string ){
      $event.preventDefault();
      const request: AutoTranslateRequest = new AutoTranslateRequest();
      request.from = 'en';
      request.to = lang;
      request.text = this.i18nForm.controls['translation_default'].value;
      this.i18nService.autoTranslateAws(request).subscribe(r => {
        this.i18nForm.controls['translation_' + lang].setValue(r.translatedText);
      });
    }

    public enableEditDefault($event){
      $event.preventDefault();
      this.editDefault = true;
      this.i18nForm.controls['translation_default'].enable();
    }
}
