import { LEADING_TRIVIA_CHARS } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { I18nService } from '@gtool.i18n/i18n.service';
import { PhoneModel } from '@gtool.shared/models/PhoneModel';
import { PhoneRepairType } from '@gtool.shared/models/PhoneRepairType';
import { PhoneType } from '@gtool.shared/models/PhoneType';
import { RepairTask } from '@gtool.shared/models/RepairTask';
import { RepairTaskStep } from '@gtool.shared/models/RepairTaskStep';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { ConfigurationService } from '@gtool.shared/services/configuration.service';
import { RepairService } from '@gtool.shared/services/repair.service';
import { Subscription } from 'rxjs';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';


@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.css'],
})
export class PreviewComponent implements OnInit {
    public phoneTypes: PhoneType[];
    public phoneModels: PhoneModel[];
    public phoneModelVariants: string[];
    public phoneRepairTypes: PhoneRepairType[];
    public selectedPhoneModel: PhoneModel;
    public selectedVariant: string;
    public showRepairs: boolean;
    public previewForm: FormGroup;
    public repairTypeId: number;
    public showTasks: boolean;
    public phoneRepairType: PhoneRepairType;
    public selectedTask: RepairTask;
    public showingStepId: number = null;
    public currentStep: RepairTaskStep;
    public hasRestart: boolean = false;
    public hasNext: boolean = false;
    public hasPrevious: boolean = false;
    public orgDetails: OrganisationDetails;

    private subscription: Subscription;

    constructor(
      private orgService: OrganisationServiceService,
        private configService: ConfigurationService,
        private repairService: RepairService,
        private i18nService: I18nService,
        private authService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
      this.orgDetails = this.authService.getCurrentOrganisation();
      this.initializeForm();
      this.phoneTypes = this.route.snapshot.data.phoneTypes[0];
      this.subscription = this.i18nService
      .translationPopup()
      .subscribe((e) => {
          if (e && e.event === 'refresh') {
            this.refreshView();
          }
      });
    }

    private initializeForm() {
        this.previewForm = new FormGroup({
            phoneType: new FormControl('', Validators.required),
            phoneModel: new FormControl('', Validators.required),
            repairType: new FormControl('', Validators.required),
            variant: new FormControl(''),
        });
    }

    public getModels() {
        this.clearSelections();
        this.phoneRepairTypes = null;
        this.previewForm.get('phoneModel').setValue('');
        const phoneType = this.previewForm.get('phoneType').value;
        if (phoneType !== '') {
            this.configService.getPhoneModels().subscribe((data) => {
                this.phoneModels = data.filter(
                    (res) => res.phoneTypeId == phoneType
                );
            });
        } else {
            this.phoneModels = null;
            this.selectedPhoneModel = null;
            this.phoneModelVariants = null;
            this.selectedTask = null;
        }
    }
    private clearSelections(): void {
        //clear selected repairs
        this.repairTypeId = null;
        this.previewForm.get('variant').setValue('');
        this.previewForm.get('repairType').setValue('');
        this.showTasks = false;
        this.selectedTask = null;
        this.currentStep = null;
        this.showingStepId = null;
    }

    public getRepairTypes() {
        this.clearSelections();
        const phoneModel = this.previewForm.get('phoneModel').value;
        if (phoneModel !== '') {
            this.selectedPhoneModel = this.phoneModels.find(
                (m) => m.id == phoneModel
            );
            this.configService
                .getPhoneRepairTypes(phoneModel)
                .subscribe((data) => {
                    this.phoneRepairTypes = data;
                    this.phoneModelVariants = this.selectedPhoneModel.variances;
                    this.showRepairs = !this.phoneModelVariants;
                });
        } else {
            this.phoneModelVariants = null;
            this.phoneRepairTypes = null;
            this.showRepairs = false;
            this.showTasks = false;
            this.selectedTask = null;
        }
    }

    public updateRepairType($event, repairTypeId: number) {
      $event.preventDefault();
      this.repairTypeId = repairTypeId;
      this.showTasks = false;
      this.phoneRepairType = null;
      this.selectedTask = null;
      // fetch tasks for the specific repair specs
      this.repairService.getRepairType(this.repairTypeId).subscribe(
        r => {
          this.phoneRepairType = r;
          this.showTasks = true;
        }
      );
    }

    private refreshView(){
      this.configService
      .getPhoneRepairTypes(this.selectedPhoneModel.id)
      .subscribe((data) => {
          this.phoneRepairTypes = data;
          // update tasks list
          if( this.phoneRepairType ){
            this.phoneRepairType = this.phoneRepairTypes.find(p => p.id === this.phoneRepairType.id);
          }
          // update selected task
          if( this.selectedTask ){
            this.selectedTask = this.phoneRepairType.tasks.find( t => t.order === this.selectedTask.order );
          }
          // update current step
          if( this.currentStep ){
            this.currentStep = this.selectedTask.steps.find( s => s.order === this.currentStep.order );
          }
      });
    }

    public updateVariant() {
        this.selectedVariant = this.previewForm.get('variant').value;
        this.showRepairs = true;
        this.showTasks = false;
        this.selectedTask = null;
    }

    public selectTask(ev:Event, order:number): void {
      ev.preventDefault();
      this.selectedTask = this.phoneRepairType.tasks.find(t => t.order === order);
      this.showingStepId = 0;
      this.next();

    }

    public next(): void {
      this.showingStepId++;
      this.currentStep = this.selectedTask.steps.find( t => t.order === this.showingStepId );
      this.previewButtons();
    }

    public previous(): void {
      this.showingStepId--;
      this.currentStep = this.selectedTask.steps.find( t => t.order === this.showingStepId );
      this.previewButtons();
    }

    public restart(): void {
      this.showingStepId = 1;
      this.currentStep = this.selectedTask.steps.find( t => t.order === this.showingStepId );
      this.previewButtons();
    }

    public previewButtons(): void {
      this.hasRestart = this.currentStep != null && this.currentStep.order > 1;
      this.hasPrevious = this.currentStep != null && this.currentStep.order > 1;
      this.hasNext = this.currentStep != null && this.currentStep.order < this.selectedTask.steps.length;
    }


    public getImageUrl(image: string): string {
      if (image.startsWith('http')) { return image; }
      else {
      return (
        environment.backend_url +
        '/api/config/phones/types/' +
        this.selectedPhoneModel.phoneTypeId +
        '/models/' +
        this.selectedPhoneModel.id +
        '/specs/images?img=' +
        image +
        '&access_token=' +
        this.authService.getToken()
        );
      }
    }
}
