import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RepairPreviewRoutingModule } from './repair-preview-routing.module';
import { PreviewComponent } from './preview/preview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { SharedModule } from '@gtool.shared/shared.module';
import { ViewCreditComponent } from './view-credit/view-credit.component';


@NgModule({
  declarations: [
    PreviewComponent,
    ViewCreditComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    I18nModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    RepairPreviewRoutingModule
  ]
})
export class RepairPreviewModule { }
