
<button type="button" class="btn btn-primary btn-block pull-right" (click)="open(transferCredits)">{{'msg.transfer.credit' | translate}}</button>



<!-- Modal for transfering credits -->
<ng-template #transferCredits let-modal>
  <form [formGroup]="transferCreditsForm">
    <div class="modal-header">
      <h5 class="modal-title">{{'msg.transfer.credit' | translate}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
          <div class="row">
              <div class="col">
                  <label for="credits">{{ 'msg.credits' | translate }}:</label>
                  <input
                      type="text"
                      formControlName="credits"
                      placeholder="{{ 'msg.enter.number.of.credits' | translate }}"
                      name="credits"
                      class="form-control"
                      id="credits"
                  />
              </div>
              <div class="col">
                  <label for="transferTo">{{ 'msg.transfer.to' | translate }}:</label>
                  <input
                    id="transferTo"
                    type="text"
                    class="form-control"
                    formControlName="transferTo"
                    placeholder="{{ 'msg.select.organisation' | translate }} "
                    [ngbTypeahead]="search"
                    [resultFormatter]="formatter"
                    [inputFormatter]="formatter"
                    (focus)="focus$.next($event.target.value)"
                    (click)="click$.next($event.target.value)"
                    #instance="ngbTypeahead"
                  />
              </div>
          </div>
          <div class='row'>
            <div class='col'>
                <span class="error-invalid-input" *ngIf="transferCreditsForm.get('credits').hasError('required') && !transferCreditsForm.get('credits').pristine">
                  {{ 'error.field.required' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferCreditsForm.get('credits').hasError('credit-format')">
                    {{ 'error.credit.format' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferCreditsForm.get('credits').hasError('credit-limits')">
                    {{ 'error.credit.limits' | translate }}<br />
                </span>
            </div>
            <div class="col">
                <span class="error-invalid-input" *ngIf="transferCreditsForm.get('transferTo').hasError('required') && !transferCreditsForm.get('transferTo').pristine">
                  {{ 'error.field.required' | translate }}<br />
                </span>
                <span class="error-invalid-input" *ngIf="transferCreditsForm.get('transferTo').hasError('organisation-child')">
                  {{ 'error.organisation.not.valid' | translate }}<br />
                </span>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{ 'msg.close' | translate }}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!transferCreditsForm.valid" (click)="onSubmit()">{{ 'msg.transfer' | translate }}</button>
    </div>
  </form>
</ng-template>
