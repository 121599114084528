  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ 'msg.date' | translate }}</th>
          <th scope="col">{{ 'credit.history.credits' | translate }}</th>
          <th scope="col">{{ 'credit.history.action.type' | translate }}</th>
          <th scope="col">{{ 'credit.history.job.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.order.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.transfer.uuid' | translate }}</th>
          <th scope="col">{{ 'credit.history.organization.from' | translate }}</th>
          <th scope="col">{{ 'credit.history.organization.to' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of creditHistory">
          <td>{{ item?.createInfo.dateCreated }}</td>
          <td>{{ item?.credit }}</td>
          <td>{{ item?.creditActionType | translate }}</td>
          <td>
            <a [routerLink]="" (click)="redirectToMachineJob(item)">{{ item?.machineJobUuid }}</a>
          </td>
          <td>{{ item?.orderUuid }}</td>
          <td>{{ item?.transferUuid }}</td>
          <td>{{ item?.fromOrganisation?.gCreditId }}</td>
          <td>{{ item?.toOrganisation?.gCreditId }}</td>
        </tr>
      </tbody>
    </table>
  </div>
