import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { I18nService } from '@gtool.i18n/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class MyToasterService {

  constructor(
    private i18n: I18nService,
    private toastr: ToastrService
  ) { }

  public info(msg: string): void {
    this.toastr.info( this.i18n.translate(msg), '', {
      toastClass: 'alert alert-info',
    });
  }

  public success(msg: string): void {
    this.toastr.success(this.i18n.translate(msg), '', {
      toastClass: 'alert alert-success',
    });
  }

  public warning(msg: string): void {
    this.toastr.warning(this.i18n.translate(msg), '', {
      toastClass: 'alert alert-warning',
    });
  }

  /**
   * Note this method does not call translate, it is happening inside the error.interceptor
   *
   * @param msg
   */
  public error(msg: string): void {
    this.toastr.error(msg, '', {
      toastClass: 'alert alert-danger',
    });
  }
}
