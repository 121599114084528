import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { ReportRevenueComponent } from './report-revenue/report-revenue.component';
import { ReportRepairComponent } from './report-repair/report-repair.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ReportsListComponent,
        ReportRevenueComponent,
        ReportRepairComponent,
    ],
    imports: [
      NgbModule,
      HttpClientModule,
      CommonModule,
      I18nModule,
      FormsModule,
      ReactiveFormsModule,
      ReportsRoutingModule
  ],
})
export class ReportsModule {}
