import { Directive, Input, Output, EventEmitter } from "@angular/core";

export type SortColumn = keyof string | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': 'asc', '': 'asc' };

export interface SortEvent {
	column: string;
	direction: SortDirection;
}

@Directive({
	selector: 'i[sortable]',
	host: {
		'[class.fa-sort]': 'direction === ""',
		'[class.fa-sort-up]': 'direction === "asc"',
		'[class.fa-sort-down]': 'direction === "desc"',
		'(click)': 'rotate()'
	}
})

export class SortableHeader {

	@Input() sortable: string = '';
	@Input() direction: SortDirection = '';
	@Output() sort = new EventEmitter<SortEvent>();

	rotate() {
		this.direction = rotate[this.direction];
		this.sort.emit({ column: this.sortable, direction: this.direction });
	}
}