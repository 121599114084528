<div class="card" >
    <div class="card-header">
        <div class="row">
          <div class="col-8">
          <h6 class="title">{{ 'msg.operators' | translate }}</h6>
          </div>
          <div class="col-4">
              <button  *ngIf="isRoot" type="button" class="btn btn-primary pull-right" (click)="open(addOperator)">
                  <i
                  class="fa fa-fw fa-plus-circle"
                  ></i>
                  {{ 'msg.add' | translate }}
              </button>
          </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'msg.first.name' | translate }}</th>
                <th scope="col">{{ 'msg.last.name' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let oper of operators">
                <td>{{ oper?.firstName }}</td>
                <td>{{ oper?.lastName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
  </div>
</div>

<ng-template #addOperator let-modal>
        <form [formGroup]="operatorForm" (ngSubmit)="onSubmit()">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'msg.add' | translate }} {{ 'msg.operator' | translate }}</h5>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
          <div class="modal-body">
          <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                    <label for="firstName" class="">
                        {{ 'msg.first.name' | translate }}
                    </label>
                        <input
                            type="text"
                            formControlName="firstName"
                            class="form-control"
                            placeholder="{{ 'msg.first.name' | translate }}"
                            required
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                      <label for="lastName" class="">
                          {{ 'msg.last.name' | translate }}
                      </label>
                          <input
                              type="text"
                              formControlName="lastName"
                              class="form-control"
                              placeholder="{{ 'msg.last.name' | translate }}"
                              required
                          />
                    </div>
                </div>
            </div>
            </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{ 'msg.close' | translate }}</button>
            <button
                class="btn btn-primary pull-right"
                type="submit"
                [disabled]="!operatorForm.valid"
            >
                {{ 'msg.save' | translate }}
            </button>
          </div>
        </form>
</ng-template>
