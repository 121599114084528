import { Component, OnInit } from '@angular/core';
import { RepairService } from '@gtool.shared/services/repair.service';
import { ActivatedRoute } from '@angular/router';
import { RepairOrder, MachineJob, Repair, RepairEndType } from '@gtool.shared/models/models';
import { combineLatest } from 'rxjs';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RepairOrderEndRequest } from '@gtool.shared/models/RepairOrderEndRequest';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent implements OnInit {

  public cancelForm: FormGroup;
  public repairOrder: RepairOrder;
  public repairs: Repair[];
  public repairOrderId;
  public repairEndTypes: RepairEndType[];

  constructor(
    private modal: NgbModal,
    private toast: MyToasterService,
    private route: ActivatedRoute,
    private repairService: RepairService
  ) { }

  ngOnInit() {
    this.route.params.subscribe( p => {
      this.repairOrderId = p['id'];
      this.fetchRemoteInfo();
    });
  }

  public async fetchRemoteInfo() {
    combineLatest(
      this.repairService.getRepairOrder(this.repairOrderId),
      this.repairService.getRepairsForOrder(this.repairOrderId)
    ).subscribe(([repairOrder, repairs]) => {
      this.repairOrder = repairOrder;
      this.repairs = repairs;
    });
  }

  public finishRepairOrder( id: number ): void {
    let data: RepairOrderEndRequest = new RepairOrderEndRequest();
    data.id = id;
    data.action='FINISH';
    this.repairService.endRepairOrder(id, data).subscribe( r => {
      this.fetchRemoteInfo();
      this.toast.success('msg.repair.order.finished');
    });
  }

  public cancelRepairOrder( id: number ): void {
    let data: RepairOrderEndRequest = new RepairOrderEndRequest();
    data.id = id;
    data.action='CANCEL';
    data.repairEndType = this.cancelForm.controls['cancelationType'].value;
    data.comments = this.cancelForm.controls['comments'].value;
    this.repairService.endRepairOrder(id, data).subscribe( r => {
      this.modal.dismissAll();
      this.fetchRemoteInfo();
      this.toast.success('msg.repair.order.cancelled');
    });
  }


  open( content ) {
    this.initForm();
    this.repairService.getRepairEndTypes().subscribe( r => {

      this.repairEndTypes = r.filter( t => t.status === 'repair.order.status.canceled' );

      this.modal.open(content, { size: 'lg' }).result.then(
        result => {},
        reason => {}
      );
    });

  }

  private initForm(): void {
    this.cancelForm = new FormGroup({
        cancelationType: new FormControl('', Validators.compose([Validators.required])),
        comments: new FormControl('', [])
    });
  }

}
