import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MachineService } from '@gtool.shared/services/machine-service.service';
import { MachineType } from '@gtool.shared/models/models';
import { MachineRequest } from '@gtool.shared/models/MachineRequest';
import { Router, ActivatedRoute } from '@angular/router';
import { Machine } from '@gtool.shared/models/Machine';
import { combineLatest } from 'rxjs';
import { MyToasterService } from '@gtool.shared/services/my-toaster.service';

@Component({
  selector: 'app-machine-form',
  templateUrl: './machine-form.component.html',
  styleUrls: ['./machine-form.component.css']
})
export class MachineFormComponent implements OnInit {

  public mode: string;
  public machineForm: FormGroup;
  public machineTypes: MachineType[];
  private machineId: any;
  private machine: Machine = new Machine();

  constructor(
    private toastr: MyToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private machineService: MachineService
  ) { }

  ngOnInit() {
    this.mode = 'CREATE';
    this.initializeForm();

    combineLatest(
      this.machineService.getMachineTypes(),
      this.route.paramMap
    ).subscribe(([machineTypes, params]) => {
      this.machineTypes = machineTypes;
      this.machineId = params.get('id');
      if ( this.machineId !== 'new') {
        this.machineService.getMachine(this.machineId)
          .subscribe(machine => {
            this.mode = 'MODIFY';
            this.machineForm.patchValue(machine);
            this.machine = machine;
        });
      }
    });
  }

  public onSubmit() {
    const machineRequest: MachineRequest = new MachineRequest();
    machineRequest.machine = new Machine();
    machineRequest.action = this.mode;
    machineRequest.machine.id = this.machineForm.controls['id'].value;
    machineRequest.number = this.machineForm.controls['number'].value;
    machineRequest.machine.machineTypeId = this.machineForm.controls['machineTypeId'].value;
    machineRequest.machine.orderStatus = this.machineForm.controls['orderStatus'].value;
    machineRequest.machine.firmwareVersion = this.machineForm.controls['firmwareVersion'].value;
    machineRequest.machine.productVersion = this.machineForm.controls['productVersion'].value;
    this.machineService.createNewMachine(machineRequest).subscribe(result => {
      this.toastr.success('msg.machine.added');
      this.router.navigate(['../../'], {relativeTo: this.route});
    });
  }

  private initializeForm() {
    this.machineForm = new FormGroup({
        id: new FormControl(''),
        number: new FormControl('', [Validators.required, Validators.min(1), Validators.max(1000)]),
        machineTypeId: new FormControl('', Validators.required),
        orderStatus: new FormControl('', Validators.required),
        firmwareVersion: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        productVersion: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")])
    });
  }

}
