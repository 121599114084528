import { ProductRate } from './ProductRate';
import { DistributorType } from './DistributorType';
import { PricingPolicyGroup } from './RepairTask';

export class OrganisationDetails {
    id?: number;
    ownerId?: number;
    parentId?: number;
    credit?: number;
    name?: string;
    postCode?: string;
    city?: string;
    countryId?: number;
    resourceType?: string;
    gCreditId?: string;
    path?: string;
    productRates?: ProductRate[];
    children?: OrganisationDetails[];
    distributorType?: DistributorType;
    distributorTypeId?: number;
    parent?: OrganisationDetails;
    regNumber?: string;
    address?: string;
    vatTax?: string;
    commPersonFirstName?: string;
    commPersonLastName?: string;
    techPersonFirstName?: string;
    techPersonLastName?: string;
    telephone?: string;
    email?: string;
    bank?: string;
    bankAccNumber?: string;
    bankSwift?: string;
    bankBranch?: string;
    aggrementDate?: string;
    overdraftAllow?: boolean;
    overdraftAmount?: number;
    pricingPolicyGroupId?: number;
    pricingPolicyGroup?: PricingPolicyGroup;
    status?: string;

    allowDemo?: boolean
    allowI18n?: boolean
}
