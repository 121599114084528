<div class="card" *ngIf="currentRepairTaskInstance">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <div class="card-title">
                    <h6>
                        {{'msg.current.repair.task' | translate}}
                    </h6>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row mt-1">
            <div class="col-12">
                <app-repair-task-instance
                    [repairTaskInstance]="currentRepairTaskInstance"
                    [isCollapsed]="true"
                    [isToggleAllowed]="false"
                ></app-repair-task-instance>
            </div>
        </div>
    </div>
</div>

<div class="card" *ngIf="repairTaskInstances?.length > 0">
    <div class="card-header">
        <div class="row">
            <div class="col-12">
                <div class="card-title">
                    <h6>
                      {{'msg.history.repair.task' | translate}}
                    </h6>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="row mt-1" *ngFor="let repairTaskInstance of repairTaskInstances">
            <div class="col-12">
            <app-repair-task-instance
                [repairTaskInstance]="repairTaskInstance"
            ></app-repair-task-instance>
            </div>
        </div>
    </div>
</div>
