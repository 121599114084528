import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RepairTool } from '@gtool.shared/models/RepairTool';
import { I18nService } from './i18n.service';

@Pipe({
    name: 'translateProcess',
    pure: false,
})
export class TranslateProcessPipe implements PipeTransform {
    constructor(
        private i18nService: I18nService,
        private sanitizer: DomSanitizer
    ) {}

    transform(value: any, ...args: any[]): any {
        let result = this.i18n(value, args[0]);
        if( args.length > 1 ){
          result = this.replaceShortcode(result, 'rtl', args[1]);
          result = this.replaceShortcode(result, 'gls', args[1], args[2]);
        }
        return result;
    }

    private i18n(obj: any, target: string): any {
        // no object no fun
        if (!obj) {
            return '';
        }
        // evaluate list contents via refrection
        var i18n_list = eval('obj.' + target + '_i18n');
        // no list no fun
        if (i18n_list) {
            var i18n = i18n_list.filter(
                (i) => i.lang === this.i18nService.getCurrentLanguage().iso && i.value && i.value.trim() !== ''
            )[0];
            // no current language in list no fun
            if (i18n) {
                return i18n.value;
            }
        }
        // if no success in i18n list, just return the target (name, info, prompt etc)
        var evalResult = eval('obj.' + target);
        return evalResult
            ? evalResult
            : '';
    }

    private replaceShortcode(value: string, shortcode: string, tools: RepairTool[], variance: string = null): string {
      let start = value.indexOf('#' + shortcode + '[');
      if( start >= 0 ){
        let end = value.indexOf("]");
        let code = value.substring(start + shortcode.length+2, end);
        let tool: RepairTool = null;
        if(  shortcode == 'rtl' && tools ){
          tool = tools.find( t => t.sku === code);
        }else if(shortcode == 'gls' && tools){
          tool = tools.find( t => t.scanPart && t.variance && t.scanPart === code && t.variance === variance);
        }
        if(!tool) {
          tool = new RepairTool();
          tool.sku = code;
          tool.name = 'N/A'
        }
        // find tool name
        let result = value.substring(0, start);
        result += '<strong>' +  tool.name + ' (' + tool.sku + ')</strong>';
        result += value.substring(end+1, value.length);
        //scan for more until the end
        return this.replaceShortcode(result, shortcode, tools);
      }else{
        return value;
      }
    }


}
