import { debounce } from 'lodash';

/**
 * This decorator is created to debounce the annotated function for the given milliseconds
 * @param ms 
 */
export function Debounce(ms): MethodDecorator{
    return function(target: any, key: any, descriptor: any) {
      const oldFunc = descriptor.value
      const newFunc = debounce(oldFunc, ms)
      descriptor.value = function() {
          return newFunc.apply(this, arguments)
      }
    }
  }