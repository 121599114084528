import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RepairOrdersComponent } from '@gtool.repairorders/repair-orders.component';
import { AuthGuard } from '@gtool.shared/guards/auth.guard';
import { RepairOrdersListResolver } from '@gtool.resolvers/repairorders-list';
import { PhoneTypesListResolver } from '@gtool.resolvers/phoneTypes-list';
import { CustomersListResolver } from '@gtool.resolvers/customers-list';
import { AddOrderComponent } from './add-order/add-order.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ViewRepairComponent } from './view-repair/view-repair.component';
import { ViewTaskComponent } from './view-task/view-task.component';

const routes: Routes = [
    {
        path: '', pathMatch: 'full',
        component: RepairOrdersComponent,
        canActivate: [AuthGuard],
        resolve: { list: RepairOrdersListResolver },
    },
    {
      path: ':id/details',
      component: ViewOrderComponent,
      canActivate: [AuthGuard],
    },
    {
        path: ':id/repair/:rid',
        component: ViewRepairComponent,
        canActivate: [AuthGuard],
    },
    {
        path: ':id/repair/:rid/task/:tid',
        component: ViewTaskComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'add',
        component: AddOrderComponent,
        canActivate: [AuthGuard],
        resolve: {
            phoneTypes: PhoneTypesListResolver,
            customers: CustomersListResolver,
        },
    },
    {
        path: 'add/customer',
        component: AddCustomerComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RepairOrdersRouting {}
