import { Language, LanguageKey } from '@gtool.i18n/models/models';

export interface Translation {
    language: Language;
    languageKey: LanguageKey;
    value: any;
}

export class AutoTranslateRequest {
  from?: string;
  to?: string;
  text?: string;
}

export class AutoTranslateResponse {
  from?: string;
  to?: string;
  originalText?: string;
  translatedText?: string;
}
