import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable } from 'rxjs';
import {
    Customer,
    CustomerRequest,
    CustomerRequestType,
} from '@gtool.shared/models/models';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { OrganisationServiceService } from '@gtool.shared/services/organisation-service.service';

@Injectable()
export class CustomersListResolver implements Resolve<any> {
    constructor(
        private organisationService: OrganisationServiceService,
        private authService: AuthenticationService
    ) {}

    resolve(): Observable<Customer[]> {
        let customerRequest: CustomerRequest = new CustomerRequest();
        customerRequest.action = CustomerRequestType.QUERY;
        let currentOrgId: number = this.authService.getCurrentOrganisation().id;
        return this.organisationService.getCustomers(
            currentOrgId,
            customerRequest
        );
    }
}
