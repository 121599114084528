import { RevenenueReportLineGroup } from './RevenenueReportLineGroup';

export class RevenenueReportLine {
    name: string;
    creditId: string;
    distributorType: string;
    parentCreditId: string;
    total = 0.0;
    productGroups: RevenenueReportLineGroup[];
}
