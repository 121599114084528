<div class="card-body">
    <form [formGroup]="creditReportForm">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="dateFrom" class="">
                        {{ 'msg.date' | translate }}&nbsp;{{
                            'msg.from' | translate
                        }}
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            formControlName="dateFrom"
                            class="form-control"
                            placeholder="dd/mm/yyyy"
                            ngbDatepicker
                            #dateFrom="ngbDatepicker"
                            required
                        />
                        <div class="input-group-append">
                            <button
                                class="btn btn-calendar m-0"
                                (click)="dateFrom.toggle()"
                                type="button"
                            >
                                <i class="now-ui-icons ui-1_calendar-60"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="dateTo" class="">
                        {{ 'msg.date' | translate }}&nbsp;{{
                            'msg.to' | translate
                        }}
                    </label>
                    <div class="input-group">
                        <input
                            type="text"
                            formControlName="dateTo"
                            class="form-control"
                            placeholder="dd/mm/yyyy"
                            ngbDatepicker
                            #dateTo="ngbDatepicker"
                            required
                        />
                        <div class="input-group-append">
                            <button
                                class="btn btn-calendar m-0"
                                (click)="dateTo.toggle()"
                                type="button"
                            >
                                <i class="now-ui-icons ui-1_calendar-60"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <label for="organisationId"
                    >{{ 'msg.organisation' | translate }}:</label
                >
                <input
                    id="organisationId"
                    type="text"
                    class="form-control"
                    formControlName="organisationId"
                    placeholder="{{ 'msg.select.organisation' | translate }} "
                    [ngbTypeahead]="search"
                    [resultFormatter]="formatter"
                    [inputFormatter]="formatter"
                    [editable]='false'
                    #instance="ngbTypeahead"
                />
            </div>

            <div class="col-sm-12 mt-auto">
                <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    (click)="onSubmit('DOWNLOAD')"
                    [disabled]="!creditReportForm.valid"
                >
                    {{ 'msg.download' | translate }}
                </button>
                <button
                    class="btn btn-primary pull-right"
                    type="submit"
                    (click)="onSubmit('SEARCH')"
                    [disabled]="!creditReportForm.valid"
                >
                    {{ 'msg.search' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
