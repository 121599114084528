import { CriteriaOperatorType } from './CriteriaOperatorType';
import { CriteriaValueType } from './CriteriaValueType';

export class CriterionQuery {
    columnName?: string;
    value?: string;
    operator?: CriteriaOperatorType;
    valueType?: CriteriaValueType;
    valueFormat?: string;
}
