import { OrganisationDetails, Group, Customer } from './models';
import { OperatorDetails } from './OperatorDetails';
import { UpdateInfo } from './UpdateInfo';
import { Machine } from './Machine';
import { RepairOrder } from './RepairOrder';

export class MachineJob {
    id?: number;
    updateInfo?: UpdateInfo;
    machine?: Machine;
    machineId?: number;
    repairOrder?: RepairOrder;
    repairOrderId?: number;
    repairId?: number;
    repairTaskId?: number;
    operator?: OperatorDetails;
    operatorId?: number;
    status?: string;
    duration?: number;
    completion?: number;
    errorCode?: string;
    errorComment?: string;
    imei?: string;
    uuid?: string;
    // repairSpecification?: RepairSpec;
    // repairSpecificationId?: number;
}
