import { Component, Input, OnInit } from '@angular/core';
import { OrganisationDetails } from '@gtool.shared/models/OrganisationDetails';
import { RepairPricing, RepairTask } from '@gtool.shared/models/RepairTask';
import { AuthenticationService } from '@gtool.shared/services/authentication.service';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-view-credit',
    templateUrl: './view-credit.component.html',
    styleUrls: ['./view-credit.component.css'],
})
export class ViewCreditComponent implements OnInit {
    @Input() orgDetails: OrganisationDetails;
    @Input() task: RepairTask;

    public isGtool: boolean = false;
    public isRepairPoint: boolean = true;
    public hasCredit: boolean = false;
    public hasPricingPolicy: boolean = false;
    public credit: number = 0;
    public orgName: string;
    public orgPricingPolicy: string;

    constructor(private authService: AuthenticationService) {}

    ngOnInit(): void {
      // authService.isRepairPoint created
        this.isGtool = this.authService.isRoot(this.orgDetails);
        this.isRepairPoint = this.authService.isRepairPoint(this.orgDetails);
        this.hasPricingPolicy = (this.orgDetails.pricingPolicyGroupId > 0)? true : false;
        this.checkCredit();
    }

    public checkCredit() {
        if (this.task.credit > 0) {
            this.hasCredit = true;
            this.calcCredit();
        } else {
            this.hasCredit = false;
        }
    }

    public calcCredit() {
      if (this.hasPricingPolicy) {
        for (var repairPricing of this.task.pricing) {
            if (
                repairPricing.group.name ==
                this.orgDetails.pricingPolicyGroup.name
            ) {
                this.credit = repairPricing.credit;
            }
        }
      } else {
        this.credit = this.task.credit;
      }
    }
}
