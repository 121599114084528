import { RepairType } from './RepairType';
import { PhoneModel } from './PhoneModel';
import { RepairTool } from './RepairTool';
import { RepairTask, Translation } from './RepairTask';

export class PhoneRepairType {
    id?: number;
    repairType?: RepairType;
    repairTypeId?: number;
    phoneModel?: PhoneModel;
    phoneModelId?: number;
    tools?: RepairTool[];
    tasks?: RepairTask[];
    forceOrder?: boolean;
    variances?: string[];
    demo?: boolean;
    name?: string;
    name_i18n?: Translation[];
}
