import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MachineRoutingModule } from './machine-routing.module';
import { MachinesComponent } from './machines/machines.component';
import { I18nModule } from '@gtool.i18n/i18n.module';
import { MyMachinesComponent } from './my-machines/my-machines.component';
import { MyMachineComponent } from './my-machine/my-machine.component';
import { MachineFormComponent } from './machine-form/machine-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FirmwareComponent } from './firmware/firmware.component';
import { MachineTypesComponent } from './machine-types/machine-types.component';
import { FirmwareFormComponent } from './firmware-form/firmware-form.component';
import { MachineComponent } from './machine/machine.component';
import { ScanImageComponent } from './scan-image/scan-image.component';
import { MachineTransferComponent } from './machine-transfer/machine-transfer.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { MachineMonitorComponent } from './machine-monitor/machine-monitor.component';
import { MachinesListResolver } from '@gtool.resolvers/machines-list';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [MachinesComponent, MyMachinesComponent, MyMachineComponent, MachineFormComponent, FirmwareComponent, MachineTypesComponent, FirmwareFormComponent, MachineComponent, ScanImageComponent, MachineTransferComponent, MachineMonitorComponent],
  imports: [
    ReactiveFormsModule,
    NgbModule,
    NgbNavModule,
    CommonModule,
    I18nModule,
    MachineRoutingModule,
    NgbTypeaheadModule
  ],
  providers: [
    MachinesListResolver
  ],
  exports: [MachinesComponent, MachineMonitorComponent],
})
export class MachineModule { }
