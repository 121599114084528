import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OAuthRequestInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // if request is oauth token related, add NG-app client credentials
        if (request.url.indexOf('/oauth/token') > 0) {
            const myBtoa: string = btoa('gtool_client:gtool_pass');
            request = request.clone({
                setHeaders: {
                    Authorization: `Basic ${myBtoa}`,
                },
            });
          }
        // }else if(request.url.indexOf('/jolokia/') > 0){
        //   const myBtoa: string = btoa('admin:admin123');
        //   request = request.clone({
        //       setHeaders: {
        //           Authorization: `Basic ${myBtoa}`,
        //       },
        //   });
        // }
        return next.handle(request);
    }
}
